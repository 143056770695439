@font-face {
    font-family: "keenicons-duotone";
    src: url("assets/fonts/keenicons/keenicons-duotone.eot?eut7fk");
    src: url("assets/fonts/keenicons/keenicons-duotone.eot?eut7fk#iefix") format("embedded-opentype"), url("assets/fonts/keenicons/keenicons-duotone.ttf?eut7fk") format("truetype"), url("assets/fonts/keenicons/keenicons-duotone.woff?eut7fk") format("woff"), url("assets/fonts/keenicons/keenicons-duotone.svg?eut7fk#keenicons-duotone") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  .ler-duotone {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "keenicons-duotone" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-flex;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ler-abstract-1 .path1:before {
    content: "\e900";
    opacity: 0.3;
  }
  
  .ler-abstract-1 .path2:before {
    content: "\e901";
    margin-left: -1em;
  }
  
  .ler-abstract-2 .path1:before {
    content: "\e902";
    opacity: 0.3;
  }
  
  .ler-abstract-2 .path2:before {
    content: "\e903";
    margin-left: -1em;
  }
  
  .ler-abstract-3 .path1:before {
    content: "\e904";
    opacity: 0.3;
  }
  
  .ler-abstract-3 .path2:before {
    content: "\e905";
    margin-left: -1em;
  }
  
  .ler-abstract-4 .path1:before {
    content: "\e906";
    opacity: 0.3;
  }
  
  .ler-abstract-4 .path2:before {
    content: "\e907";
    margin-left: -1em;
  }
  
  .ler-abstract-5 .path1:before {
    content: "\e908";
    opacity: 0.3;
  }
  
  .ler-abstract-5 .path2:before {
    content: "\e909";
    margin-left: -1em;
  }
  
  .ler-abstract-6:before {
    content: "\e90a";
  }
  
  .ler-abstract-7 .path1:before {
    content: "\e90b";
    opacity: 0.3;
  }
  
  .ler-abstract-7 .path2:before {
    content: "\e90c";
    margin-left: -1em;
  }
  
  .ler-abstract-8 .path1:before {
    content: "\e90d";
    opacity: 0.3;
  }
  
  .ler-abstract-8 .path2:before {
    content: "\e90e";
    margin-left: -1em;
  }
  
  .ler-abstract-9 .path1:before {
    content: "\e90f";
    opacity: 0.3;
  }
  
  .ler-abstract-9 .path2:before {
    content: "\e910";
    margin-left: -1em;
  }
  
  .ler-abstract-10 .path1:before {
    content: "\e911";
    opacity: 0.3;
  }
  
  .ler-abstract-10 .path2:before {
    content: "\e912";
    margin-left: -1em;
  }
  
  .ler-abstract-11 .path1:before {
    content: "\e913";
    opacity: 0.3;
  }
  
  .ler-abstract-11 .path2:before {
    content: "\e914";
    margin-left: -1em;
  }
  
  .ler-abstract-12 .path1:before {
    content: "\e915";
    opacity: 0.3;
  }
  
  .ler-abstract-12 .path2:before {
    content: "\e916";
    margin-left: -1em;
  }
  
  .ler-abstract-13 .path1:before {
    content: "\e917";
    opacity: 0.3;
  }
  
  .ler-abstract-13 .path2:before {
    content: "\e918";
    margin-left: -1em;
  }
  
  .ler-abstract-14 .path1:before {
    content: "\e919";
  }
  
  .ler-abstract-14 .path2:before {
    content: "\e91a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-15 .path1:before {
    content: "\e91b";
    opacity: 0.3;
  }
  
  .ler-abstract-15 .path2:before {
    content: "\e91c";
    margin-left: -1em;
  }
  
  .ler-abstract-16 .path1:before {
    content: "\e91d";
    opacity: 0.3;
  }
  
  .ler-abstract-16 .path2:before {
    content: "\e91e";
    margin-left: -1em;
  }
  
  .ler-abstract-17 .path1:before {
    content: "\e91f";
  }
  
  .ler-abstract-17 .path2:before {
    content: "\e920";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-18 .path1:before {
    content: "\e921";
  }
  
  .ler-abstract-18 .path2:before {
    content: "\e922";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-19 .path1:before {
    content: "\e923";
  }
  
  .ler-abstract-19 .path2:before {
    content: "\e924";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-20 .path1:before {
    content: "\e925";
  }
  
  .ler-abstract-20 .path2:before {
    content: "\e926";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-21 .path1:before {
    content: "\e927";
    opacity: 0.3;
  }
  
  .ler-abstract-21 .path2:before {
    content: "\e928";
    margin-left: -1em;
  }
  
  .ler-abstract-22 .path1:before {
    content: "\e929";
    opacity: 0.3;
  }
  
  .ler-abstract-22 .path2:before {
    content: "\e92a";
    margin-left: -1em;
  }
  
  .ler-abstract-23 .path1:before {
    content: "\e92b";
    opacity: 0.3;
  }
  
  .ler-abstract-23 .path2:before {
    content: "\e92c";
    margin-left: -1em;
  }
  
  .ler-abstract-24 .path1:before {
    content: "\e92d";
  }
  
  .ler-abstract-24 .path2:before {
    content: "\e92e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-25 .path1:before {
    content: "\e92f";
    opacity: 0.3;
  }
  
  .ler-abstract-25 .path2:before {
    content: "\e930";
    margin-left: -1em;
  }
  
  .ler-abstract-26 .path1:before {
    content: "\e931";
    opacity: 0.3;
  }
  
  .ler-abstract-26 .path2:before {
    content: "\e932";
    margin-left: -1em;
  }
  
  .ler-abstract-27 .path1:before {
    content: "\e933";
  }
  
  .ler-abstract-27 .path2:before {
    content: "\e934";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-28 .path1:before {
    content: "\e935";
  }
  
  .ler-abstract-28 .path2:before {
    content: "\e936";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-29 .path1:before {
    content: "\e937";
  }
  
  .ler-abstract-29 .path2:before {
    content: "\e938";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-30 .path1:before {
    content: "\e939";
  }
  
  .ler-abstract-30 .path2:before {
    content: "\e93a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-31 .path1:before {
    content: "\e93b";
  }
  
  .ler-abstract-31 .path2:before {
    content: "\e93c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-32 .path1:before {
    content: "\e93d";
  }
  
  .ler-abstract-32 .path2:before {
    content: "\e93e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-33 .path1:before {
    content: "\e93f";
    opacity: 0.3;
  }
  
  .ler-abstract-33 .path2:before {
    content: "\e940";
    margin-left: -1em;
  }
  
  .ler-abstract-34 .path1:before {
    content: "\e941";
    opacity: 0.3;
  }
  
  .ler-abstract-34 .path2:before {
    content: "\e942";
    margin-left: -1em;
  }
  
  .ler-abstract-35 .path1:before {
    content: "\e943";
  }
  
  .ler-abstract-35 .path2:before {
    content: "\e944";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-36 .path1:before {
    content: "\e945";
  }
  
  .ler-abstract-36 .path2:before {
    content: "\e946";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-37 .path1:before {
    content: "\e947";
    opacity: 0.3;
  }
  
  .ler-abstract-37 .path2:before {
    content: "\e948";
    margin-left: -1em;
  }
  
  .ler-abstract-38 .path1:before {
    content: "\e949";
  }
  
  .ler-abstract-38 .path2:before {
    content: "\e94a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-39 .path1:before {
    content: "\e94b";
    opacity: 0.3;
  }
  
  .ler-abstract-39 .path2:before {
    content: "\e94c";
    margin-left: -1em;
  }
  
  .ler-abstract-40 .path1:before {
    content: "\e94d";
  }
  
  .ler-abstract-40 .path2:before {
    content: "\e94e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-41 .path1:before {
    content: "\e94f";
  }
  
  .ler-abstract-41 .path2:before {
    content: "\e950";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-42 .path1:before {
    content: "\e951";
    opacity: 0.3;
  }
  
  .ler-abstract-42 .path2:before {
    content: "\e952";
    margin-left: -1em;
  }
  
  .ler-abstract-43 .path1:before {
    content: "\e953";
    opacity: 0.3;
  }
  
  .ler-abstract-43 .path2:before {
    content: "\e954";
    margin-left: -1em;
  }
  
  .ler-abstract-44 .path1:before {
    content: "\e955";
  }
  
  .ler-abstract-44 .path2:before {
    content: "\e956";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-45 .path1:before {
    content: "\e957";
  }
  
  .ler-abstract-45 .path2:before {
    content: "\e958";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-46 .path1:before {
    content: "\e959";
    opacity: 0.3;
  }
  
  .ler-abstract-46 .path2:before {
    content: "\e95a";
    margin-left: -1em;
  }
  
  .ler-abstract-47 .path1:before {
    content: "\e95b";
    opacity: 0.3;
  }
  
  .ler-abstract-47 .path2:before {
    content: "\e95c";
    margin-left: -1em;
  }
  
  .ler-abstract-48 .path1:before {
    content: "\e95d";
    opacity: 0.3;
  }
  
  .ler-abstract-48 .path2:before {
    content: "\e95e";
    margin-left: -1em;
  }
  
  .ler-abstract-48 .path3:before {
    content: "\e95f";
    margin-left: -1em;
  }
  
  .ler-abstract-49 .path1:before {
    content: "\e960";
    opacity: 0.3;
  }
  
  .ler-abstract-49 .path2:before {
    content: "\e961";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-abstract-49 .path3:before {
    content: "\e962";
    margin-left: -1em;
  }
  
  .ler-abstract .path1:before {
    content: "\e963";
    opacity: 0.3;
  }
  
  .ler-abstract .path2:before {
    content: "\e964";
    margin-left: -1em;
  }
  
  .ler-add-files .path1:before {
    content: "\e965";
  }
  
  .ler-add-files .path2:before {
    content: "\e966";
    margin-left: -1em;
  }
  
  .ler-add-files .path3:before {
    content: "\e967";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-add-folder .path1:before {
    content: "\e968";
    opacity: 0.3;
  }
  
  .ler-add-folder .path2:before {
    content: "\e969";
    margin-left: -1em;
  }
  
  .ler-add-item .path1:before {
    content: "\e96a";
    opacity: 0.3;
  }
  
  .ler-add-item .path2:before {
    content: "\e96b";
    margin-left: -1em;
  }
  
  .ler-add-item .path3:before {
    content: "\e96c";
    margin-left: -1em;
  }
  
  .ler-add-notepad .path1:before {
    content: "\e96d";
    opacity: 0.3;
  }
  
  .ler-add-notepad .path2:before {
    content: "\e96e";
    margin-left: -1em;
  }
  
  .ler-add-notepad .path3:before {
    content: "\e96f";
    margin-left: -1em;
  }
  
  .ler-add-notepad .path4:before {
    content: "\e970";
    margin-left: -1em;
  }
  
  .ler-address-book .path1:before {
    content: "\e971";
  }
  
  .ler-address-book .path2:before {
    content: "\e972";
    margin-left: -1em;
  }
  
  .ler-address-book .path3:before {
    content: "\e973";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-airplane-square .path1:before {
    content: "\e974";
    opacity: 0.3;
  }
  
  .ler-airplane-square .path2:before {
    content: "\e975";
    margin-left: -1em;
  }
  
  .ler-airplane .path1:before {
    content: "\e976";
    opacity: 0.3;
  }
  
  .ler-airplane .path2:before {
    content: "\e977";
    margin-left: -1em;
  }
  
  .ler-airpod .path1:before {
    content: "\e978";
    opacity: 0.3;
  }
  
  .ler-airpod .path2:before {
    content: "\e979";
    margin-left: -1em;
  }
  
  .ler-airpod .path3:before {
    content: "\e97a";
    margin-left: -1em;
  }
  
  .ler-android .path1:before {
    content: "\e97b";
    opacity: 0.3;
  }
  
  .ler-android .path2:before {
    content: "\e97c";
    margin-left: -1em;
  }
  
  .ler-android .path3:before {
    content: "\e97d";
    margin-left: -1em;
  }
  
  .ler-android .path4:before {
    content: "\e97e";
    margin-left: -1em;
  }
  
  .ler-android .path5:before {
    content: "\e97f";
    margin-left: -1em;
  }
  
  .ler-android .path6:before {
    content: "\e980";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-angular .path1:before {
    content: "\e981";
    opacity: 0.3;
  }
  
  .ler-angular .path2:before {
    content: "\e982";
    margin-left: -1em;
  }
  
  .ler-angular .path3:before {
    content: "\e983";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-apple .path1:before {
    content: "\e984";
  }
  
  .ler-apple .path2:before {
    content: "\e985";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-archive-tick .path1:before {
    content: "\e986";
    opacity: 0.3;
  }
  
  .ler-archive-tick .path2:before {
    content: "\e987";
    margin-left: -1em;
  }
  
  .ler-archive .path1:before {
    content: "\e988";
    opacity: 0.3;
  }
  
  .ler-archive .path2:before {
    content: "\e989";
    margin-left: -1em;
  }
  
  .ler-archive .path3:before {
    content: "\e98a";
    margin-left: -1em;
  }
  
  .ler-arrow-circle-left .path1:before {
    content: "\e98b";
    opacity: 0.3;
  }
  
  .ler-arrow-circle-left .path2:before {
    content: "\e98c";
    margin-left: -1em;
  }
  
  .ler-arrow-circle-right .path1:before {
    content: "\e98d";
    opacity: 0.3;
  }
  
  .ler-arrow-circle-right .path2:before {
    content: "\e98e";
    margin-left: -1em;
  }
  
  .ler-arrow-diagonal .path1:before {
    content: "\e98f";
    opacity: 0.3;
  }
  
  .ler-arrow-diagonal .path2:before {
    content: "\e990";
    margin-left: -1em;
  }
  
  .ler-arrow-diagonal .path3:before {
    content: "\e991";
    margin-left: -1em;
  }
  
  .ler-arrow-down-left .path1:before {
    content: "\e992";
  }
  
  .ler-arrow-down-left .path2:before {
    content: "\e993";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-down-refraction .path1:before {
    content: "\e994";
    opacity: 0.3;
  }
  
  .ler-arrow-down-refraction .path2:before {
    content: "\e995";
    margin-left: -1em;
  }
  
  .ler-arrow-down-right .path1:before {
    content: "\e996";
  }
  
  .ler-arrow-down-right .path2:before {
    content: "\e997";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-down .path1:before {
    content: "\e998";
  }
  
  .ler-arrow-down .path2:before {
    content: "\e999";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-left .path1:before {
    content: "\e99a";
  }
  
  .ler-arrow-left .path2:before {
    content: "\e99b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-mix .path1:before {
    content: "\e99c";
  }
  
  .ler-arrow-mix .path2:before {
    content: "\e99d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-right-left .path1:before {
    content: "\e99e";
    opacity: 0.3;
  }
  
  .ler-arrow-right-left .path2:before {
    content: "\e99f";
    margin-left: -1em;
  }
  
  .ler-arrow-right .path1:before {
    content: "\e9a0";
  }
  
  .ler-arrow-right .path2:before {
    content: "\e9a1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-two-diagonals .path1:before {
    content: "\e9a2";
    opacity: 0.3;
  }
  
  .ler-arrow-two-diagonals .path2:before {
    content: "\e9a3";
    margin-left: -1em;
  }
  
  .ler-arrow-two-diagonals .path3:before {
    content: "\e9a4";
    margin-left: -1em;
  }
  
  .ler-arrow-two-diagonals .path4:before {
    content: "\e9a5";
    margin-left: -1em;
  }
  
  .ler-arrow-two-diagonals .path5:before {
    content: "\e9a6";
    margin-left: -1em;
  }
  
  .ler-arrow-up-down .path1:before {
    content: "\e9a7";
    opacity: 0.3;
  }
  
  .ler-arrow-up-down .path2:before {
    content: "\e9a8";
    margin-left: -1em;
  }
  
  .ler-arrow-up-left .path1:before {
    content: "\e9a9";
  }
  
  .ler-arrow-up-left .path2:before {
    content: "\e9aa";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-up-refraction .path1:before {
    content: "\e9ab";
    opacity: 0.3;
  }
  
  .ler-arrow-up-refraction .path2:before {
    content: "\e9ac";
    margin-left: -1em;
  }
  
  .ler-arrow-up-right .path1:before {
    content: "\e9ad";
  }
  
  .ler-arrow-up-right .path2:before {
    content: "\e9ae";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-up .path1:before {
    content: "\e9af";
  }
  
  .ler-arrow-up .path2:before {
    content: "\e9b0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrow-zigzag .path1:before {
    content: "\e9b1";
  }
  
  .ler-arrow-zigzag .path2:before {
    content: "\e9b2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-arrows-circle .path1:before {
    content: "\e9b3";
    opacity: 0.3;
  }
  
  .ler-arrows-circle .path2:before {
    content: "\e9b4";
    margin-left: -1em;
  }
  
  .ler-arrows-loop .path1:before {
    content: "\e9b5";
    opacity: 0.3;
  }
  
  .ler-arrows-loop .path2:before {
    content: "\e9b6";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path1:before {
    content: "\e9b7";
    opacity: 0.3;
  }
  
  .ler-artificial-intelligence .path2:before {
    content: "\e9b8";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path3:before {
    content: "\e9b9";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path4:before {
    content: "\e9ba";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path5:before {
    content: "\e9bb";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path6:before {
    content: "\e9bc";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path7:before {
    content: "\e9bd";
    margin-left: -1em;
  }
  
  .ler-artificial-intelligence .path8:before {
    content: "\e9be";
    margin-left: -1em;
  }
  
  .ler-auto-brightness .path1:before {
    content: "\e9bf";
    opacity: 0.3;
  }
  
  .ler-auto-brightness .path2:before {
    content: "\e9c0";
    margin-left: -1em;
  }
  
  .ler-auto-brightness .path3:before {
    content: "\e9c1";
    margin-left: -1em;
  }
  
  .ler-avalanche .path1:before {
    content: "\e9c2";
    opacity: 0.3;
  }
  
  .ler-avalanche .path2:before {
    content: "\e9c3";
    margin-left: -1em;
  }
  
  .ler-award .path1:before {
    content: "\e9c4";
    opacity: 0.3;
  }
  
  .ler-award .path2:before {
    content: "\e9c5";
    margin-left: -1em;
  }
  
  .ler-award .path3:before {
    content: "\e9c6";
    margin-left: -1em;
  }
  
  .ler-badge .path1:before {
    content: "\e9c7";
    opacity: 0.3;
  }
  
  .ler-badge .path2:before {
    content: "\e9c8";
    margin-left: -1em;
  }
  
  .ler-badge .path3:before {
    content: "\e9c9";
    margin-left: -1em;
  }
  
  .ler-badge .path4:before {
    content: "\e9ca";
    margin-left: -1em;
  }
  
  .ler-badge .path5:before {
    content: "\e9cb";
    margin-left: -1em;
  }
  
  .ler-bandage .path1:before {
    content: "\e9cc";
    opacity: 0.3;
  }
  
  .ler-bandage .path2:before {
    content: "\e9cd";
    margin-left: -1em;
  }
  
  .ler-bank .path1:before {
    content: "\e9ce";
    opacity: 0.3;
  }
  
  .ler-bank .path2:before {
    content: "\e9cf";
    margin-left: -1em;
  }
  
  .ler-barcode .path1:before {
    content: "\e9d0";
  }
  
  .ler-barcode .path2:before {
    content: "\e9d1";
    margin-left: -1em;
  }
  
  .ler-barcode .path3:before {
    content: "\e9d2";
    margin-left: -1em;
  }
  
  .ler-barcode .path4:before {
    content: "\e9d3";
    margin-left: -1em;
  }
  
  .ler-barcode .path5:before {
    content: "\e9d4";
    margin-left: -1em;
  }
  
  .ler-barcode .path6:before {
    content: "\e9d5";
    margin-left: -1em;
  }
  
  .ler-barcode .path7:before {
    content: "\e9d6";
    margin-left: -1em;
  }
  
  .ler-barcode .path8:before {
    content: "\e9d7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-basket-ok .path1:before {
    content: "\e9d8";
    opacity: 0.3;
  }
  
  .ler-basket-ok .path2:before {
    content: "\e9d9";
    margin-left: -1em;
  }
  
  .ler-basket-ok .path3:before {
    content: "\e9da";
    margin-left: -1em;
  }
  
  .ler-basket-ok .path4:before {
    content: "\e9db";
    margin-left: -1em;
  }
  
  .ler-basket .path1:before {
    content: "\e9dc";
    opacity: 0.3;
  }
  
  .ler-basket .path2:before {
    content: "\e9dd";
    margin-left: -1em;
  }
  
  .ler-basket .path3:before {
    content: "\e9de";
    margin-left: -1em;
  }
  
  .ler-basket .path4:before {
    content: "\e9df";
    margin-left: -1em;
  }
  
  .ler-behance .path1:before {
    content: "\e9e0";
  }
  
  .ler-behance .path2:before {
    content: "\e9e1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-bill .path1:before {
    content: "\e9e2";
    opacity: 0.3;
  }
  
  .ler-bill .path2:before {
    content: "\e9e3";
    margin-left: -1em;
  }
  
  .ler-bill .path3:before {
    content: "\e9e4";
    margin-left: -1em;
  }
  
  .ler-bill .path4:before {
    content: "\e9e5";
    margin-left: -1em;
  }
  
  .ler-bill .path5:before {
    content: "\e9e6";
    margin-left: -1em;
  }
  
  .ler-bill .path6:before {
    content: "\e9e7";
    margin-left: -1em;
  }
  
  .ler-binance-usd .path1:before {
    content: "\e9e8";
  }
  
  .ler-binance-usd .path2:before {
    content: "\e9e9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-binance-usd .path3:before {
    content: "\e9ea";
    margin-left: -1em;
  }
  
  .ler-binance-usd .path4:before {
    content: "\e9eb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-binance .path1:before {
    content: "\e9ec";
    opacity: 0.3;
  }
  
  .ler-binance .path2:before {
    content: "\e9ed";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-binance .path3:before {
    content: "\e9ee";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-binance .path4:before {
    content: "\e9ef";
    margin-left: -1em;
  }
  
  .ler-binance .path5:before {
    content: "\e9f0";
    margin-left: -1em;
  }
  
  .ler-bitcoin .path1:before {
    content: "\e9f1";
    opacity: 0.3;
  }
  
  .ler-bitcoin .path2:before {
    content: "\e9f2";
    margin-left: -1em;
  }
  
  .ler-black-down:before {
    content: "\e9f3";
  }
  
  .ler-black-left-line .path1:before {
    content: "\e9f4";
  }
  
  .ler-black-left-line .path2:before {
    content: "\e9f5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-black-left:before {
    content: "\e9f6";
  }
  
  .ler-black-right-line .path1:before {
    content: "\e9f7";
  }
  
  .ler-black-right-line .path2:before {
    content: "\e9f8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-black-right:before {
    content: "\e9f9";
  }
  
  .ler-black-up:before {
    content: "\e9fa";
  }
  
  .ler-bluetooth .path1:before {
    content: "\e9fb";
  }
  
  .ler-bluetooth .path2:before {
    content: "\e9fc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-book-open .path1:before {
    content: "\e9fd";
    opacity: 0.3;
  }
  
  .ler-book-open .path2:before {
    content: "\e9fe";
    margin-left: -1em;
  }
  
  .ler-book-open .path3:before {
    content: "\e9ff";
    margin-left: -1em;
  }
  
  .ler-book-open .path4:before {
    content: "\ea00";
    margin-left: -1em;
  }
  
  .ler-book-square .path1:before {
    content: "\ea01";
    opacity: 0.3;
  }
  
  .ler-book-square .path2:before {
    content: "\ea02";
    margin-left: -1em;
  }
  
  .ler-book-square .path3:before {
    content: "\ea03";
    margin-left: -1em;
  }
  
  .ler-book .path1:before {
    content: "\ea04";
    opacity: 0.3;
  }
  
  .ler-book .path2:before {
    content: "\ea05";
    margin-left: -1em;
  }
  
  .ler-book .path3:before {
    content: "\ea06";
    margin-left: -1em;
  }
  
  .ler-book .path4:before {
    content: "\ea07";
    margin-left: -1em;
  }
  
  .ler-bookmark-2 .path1:before {
    content: "\ea08";
    opacity: 0.3;
  }
  
  .ler-bookmark-2 .path2:before {
    content: "\ea09";
    margin-left: -1em;
  }
  
  .ler-bookmark .path1:before {
    content: "\ea0a";
    opacity: 0.3;
  }
  
  .ler-bookmark .path2:before {
    content: "\ea0b";
    margin-left: -1em;
  }
  
  .ler-bootstrap .path1:before {
    content: "\ea0c";
    opacity: 0.3;
  }
  
  .ler-bootstrap .path2:before {
    content: "\ea0d";
    margin-left: -1em;
  }
  
  .ler-bootstrap .path3:before {
    content: "\ea0e";
    margin-left: -1em;
  }
  
  .ler-briefcase .path1:before {
    content: "\ea0f";
    opacity: 0.3;
  }
  
  .ler-briefcase .path2:before {
    content: "\ea10";
    margin-left: -1em;
  }
  
  .ler-brifecase-cros .path1:before {
    content: "\ea11";
    opacity: 0.3;
  }
  
  .ler-brifecase-cros .path2:before {
    content: "\ea12";
    margin-left: -1em;
  }
  
  .ler-brifecase-cros .path3:before {
    content: "\ea13";
    margin-left: -1em;
  }
  
  .ler-brifecase-tick .path1:before {
    content: "\ea14";
    opacity: 0.3;
  }
  
  .ler-brifecase-tick .path2:before {
    content: "\ea15";
    margin-left: -1em;
  }
  
  .ler-brifecase-tick .path3:before {
    content: "\ea16";
    margin-left: -1em;
  }
  
  .ler-brifecase-timer .path1:before {
    content: "\ea17";
    opacity: 0.3;
  }
  
  .ler-brifecase-timer .path2:before {
    content: "\ea18";
    margin-left: -1em;
  }
  
  .ler-brifecase-timer .path3:before {
    content: "\ea19";
    margin-left: -1em;
  }
  
  .ler-brush .path1:before {
    content: "\ea1a";
    opacity: 0.3;
  }
  
  .ler-brush .path2:before {
    content: "\ea1b";
    margin-left: -1em;
  }
  
  .ler-bucket-square .path1:before {
    content: "\ea1c";
    opacity: 0.3;
  }
  
  .ler-bucket-square .path2:before {
    content: "\ea1d";
    margin-left: -1em;
  }
  
  .ler-bucket-square .path3:before {
    content: "\ea1e";
    margin-left: -1em;
  }
  
  .ler-bucket .path1:before {
    content: "\ea1f";
    opacity: 0.3;
  }
  
  .ler-bucket .path2:before {
    content: "\ea20";
    margin-left: -1em;
  }
  
  .ler-bucket .path3:before {
    content: "\ea21";
    margin-left: -1em;
  }
  
  .ler-bucket .path4:before {
    content: "\ea22";
    margin-left: -1em;
  }
  
  .ler-burger-menu-1 .path1:before {
    content: "\ea23";
    opacity: 0.3;
  }
  
  .ler-burger-menu-1 .path2:before {
    content: "\ea24";
    margin-left: -1em;
  }
  
  .ler-burger-menu-1 .path3:before {
    content: "\ea25";
    margin-left: -1em;
  }
  
  .ler-burger-menu-1 .path4:before {
    content: "\ea26";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path1:before {
    content: "\ea27";
    opacity: 0.3;
  }
  
  .ler-burger-menu-2 .path2:before {
    content: "\ea28";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path3:before {
    content: "\ea29";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path4:before {
    content: "\ea2a";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path5:before {
    content: "\ea2b";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path6:before {
    content: "\ea2c";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path7:before {
    content: "\ea2d";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path8:before {
    content: "\ea2e";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path9:before {
    content: "\ea2f";
    margin-left: -1em;
  }
  
  .ler-burger-menu-2 .path10:before {
    content: "\ea30";
    margin-left: -1em;
  }
  
  .ler-burger-menu-3 .path1:before {
    content: "\ea31";
    opacity: 0.3;
  }
  
  .ler-burger-menu-3 .path2:before {
    content: "\ea32";
    margin-left: -1em;
  }
  
  .ler-burger-menu-3 .path3:before {
    content: "\ea33";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-burger-menu-3 .path4:before {
    content: "\ea34";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-burger-menu-3 .path5:before {
    content: "\ea35";
    margin-left: -1em;
  }
  
  .ler-burger-menu-3 .path6:before {
    content: "\ea36";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-burger-menu-3 .path7:before {
    content: "\ea37";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-burger-menu-3 .path8:before {
    content: "\ea38";
    margin-left: -1em;
  }
  
  .ler-burger-menu-3 .path9:before {
    content: "\ea39";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-burger-menu-4:before {
    content: "\ea3a";
  }
  
  .ler-burger-menu-5:before {
    content: "\ea3b";
  }
  
  .ler-burger-menu-6:before {
    content: "\ea3c";
  }
  
  .ler-burger-menu .path1:before {
    content: "\ea3d";
    opacity: 0.3;
  }
  
  .ler-burger-menu .path2:before {
    content: "\ea3e";
    margin-left: -1em;
  }
  
  .ler-burger-menu .path3:before {
    content: "\ea3f";
    margin-left: -1em;
  }
  
  .ler-burger-menu .path4:before {
    content: "\ea40";
    margin-left: -1em;
  }
  
  .ler-bus .path1:before {
    content: "\ea41";
    opacity: 0.3;
  }
  
  .ler-bus .path2:before {
    content: "\ea42";
    margin-left: -1em;
  }
  
  .ler-bus .path3:before {
    content: "\ea43";
    margin-left: -1em;
  }
  
  .ler-bus .path4:before {
    content: "\ea44";
    margin-left: -1em;
  }
  
  .ler-bus .path5:before {
    content: "\ea45";
    margin-left: -1em;
  }
  
  .ler-calculator .path1:before {
    content: "\ea46";
    opacity: 0.3;
  }
  
  .ler-calculator .path2:before {
    content: "\ea47";
    margin-left: -1em;
  }
  
  .ler-calculator .path3:before {
    content: "\ea48";
    margin-left: -1em;
  }
  
  .ler-calculator .path4:before {
    content: "\ea49";
    margin-left: -1em;
  }
  
  .ler-calculator .path5:before {
    content: "\ea4a";
    margin-left: -1em;
  }
  
  .ler-calculator .path6:before {
    content: "\ea4b";
    margin-left: -1em;
  }
  
  .ler-calendar-2 .path1:before {
    content: "\ea4c";
    opacity: 0.3;
  }
  
  .ler-calendar-2 .path2:before {
    content: "\ea4d";
    margin-left: -1em;
  }
  
  .ler-calendar-2 .path3:before {
    content: "\ea4e";
    margin-left: -1em;
  }
  
  .ler-calendar-2 .path4:before {
    content: "\ea4f";
    margin-left: -1em;
  }
  
  .ler-calendar-2 .path5:before {
    content: "\ea50";
    margin-left: -1em;
  }
  
  .ler-calendar-8 .path1:before {
    content: "\ea51";
    opacity: 0.3;
  }
  
  .ler-calendar-8 .path2:before {
    content: "\ea52";
    margin-left: -1em;
  }
  
  .ler-calendar-8 .path3:before {
    content: "\ea53";
    margin-left: -1em;
  }
  
  .ler-calendar-8 .path4:before {
    content: "\ea54";
    margin-left: -1em;
  }
  
  .ler-calendar-8 .path5:before {
    content: "\ea55";
    margin-left: -1em;
  }
  
  .ler-calendar-8 .path6:before {
    content: "\ea56";
    margin-left: -1em;
  }
  
  .ler-calendar-add .path1:before {
    content: "\ea57";
    opacity: 0.3;
  }
  
  .ler-calendar-add .path2:before {
    content: "\ea58";
    margin-left: -1em;
  }
  
  .ler-calendar-add .path3:before {
    content: "\ea59";
    margin-left: -1em;
  }
  
  .ler-calendar-add .path4:before {
    content: "\ea5a";
    margin-left: -1em;
  }
  
  .ler-calendar-add .path5:before {
    content: "\ea5b";
    margin-left: -1em;
  }
  
  .ler-calendar-add .path6:before {
    content: "\ea5c";
    margin-left: -1em;
  }
  
  .ler-calendar-edit .path1:before {
    content: "\ea5d";
    opacity: 0.3;
  }
  
  .ler-calendar-edit .path2:before {
    content: "\ea5e";
    margin-left: -1em;
  }
  
  .ler-calendar-edit .path3:before {
    content: "\ea5f";
    margin-left: -1em;
  }
  
  .ler-calendar-remove .path1:before {
    content: "\ea60";
    opacity: 0.3;
  }
  
  .ler-calendar-remove .path2:before {
    content: "\ea61";
    margin-left: -1em;
  }
  
  .ler-calendar-remove .path3:before {
    content: "\ea62";
    margin-left: -1em;
  }
  
  .ler-calendar-remove .path4:before {
    content: "\ea63";
    margin-left: -1em;
  }
  
  .ler-calendar-remove .path5:before {
    content: "\ea64";
    margin-left: -1em;
  }
  
  .ler-calendar-remove .path6:before {
    content: "\ea65";
    margin-left: -1em;
  }
  
  .ler-calendar-search .path1:before {
    content: "\ea66";
    opacity: 0.3;
  }
  
  .ler-calendar-search .path2:before {
    content: "\ea67";
    margin-left: -1em;
  }
  
  .ler-calendar-search .path3:before {
    content: "\ea68";
    margin-left: -1em;
  }
  
  .ler-calendar-search .path4:before {
    content: "\ea69";
    margin-left: -1em;
  }
  
  .ler-calendar-tick .path1:before {
    content: "\ea6a";
    opacity: 0.3;
  }
  
  .ler-calendar-tick .path2:before {
    content: "\ea6b";
    margin-left: -1em;
  }
  
  .ler-calendar-tick .path3:before {
    content: "\ea6c";
    margin-left: -1em;
  }
  
  .ler-calendar-tick .path4:before {
    content: "\ea6d";
    margin-left: -1em;
  }
  
  .ler-calendar-tick .path5:before {
    content: "\ea6e";
    margin-left: -1em;
  }
  
  .ler-calendar-tick .path6:before {
    content: "\ea6f";
    margin-left: -1em;
  }
  
  .ler-calendar .path1:before {
    content: "\ea70";
    opacity: 0.3;
  }
  
  .ler-calendar .path2:before {
    content: "\ea71";
    margin-left: -1em;
  }
  
  .ler-call .path1:before {
    content: "\ea72";
    opacity: 0.3;
  }
  
  .ler-call .path2:before {
    content: "\ea73";
    margin-left: -1em;
  }
  
  .ler-call .path3:before {
    content: "\ea74";
    margin-left: -1em;
  }
  
  .ler-call .path4:before {
    content: "\ea75";
    margin-left: -1em;
  }
  
  .ler-call .path5:before {
    content: "\ea76";
    margin-left: -1em;
  }
  
  .ler-call .path6:before {
    content: "\ea77";
    margin-left: -1em;
  }
  
  .ler-call .path7:before {
    content: "\ea78";
    margin-left: -1em;
  }
  
  .ler-call .path8:before {
    content: "\ea79";
    margin-left: -1em;
  }
  
  .ler-capsule .path1:before {
    content: "\ea7a";
    opacity: 0.3;
  }
  
  .ler-capsule .path2:before {
    content: "\ea7b";
    margin-left: -1em;
  }
  
  .ler-car-2 .path1:before {
    content: "\ea7c";
  }
  
  .ler-car-2 .path2:before {
    content: "\ea7d";
    margin-left: -1em;
  }
  
  .ler-car-2 .path3:before {
    content: "\ea7e";
    margin-left: -1em;
  }
  
  .ler-car-2 .path4:before {
    content: "\ea7f";
    margin-left: -1em;
  }
  
  .ler-car-2 .path5:before {
    content: "\ea80";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-car-2 .path6:before {
    content: "\ea81";
    margin-left: -1em;
  }
  
  .ler-car-3 .path1:before {
    content: "\ea82";
  }
  
  .ler-car-3 .path2:before {
    content: "\ea83";
    margin-left: -1em;
  }
  
  .ler-car-3 .path3:before {
    content: "\ea84";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-car .path1:before {
    content: "\ea85";
    opacity: 0.3;
  }
  
  .ler-car .path2:before {
    content: "\ea86";
    margin-left: -1em;
  }
  
  .ler-car .path3:before {
    content: "\ea87";
    margin-left: -1em;
  }
  
  .ler-car .path4:before {
    content: "\ea88";
    margin-left: -1em;
  }
  
  .ler-car .path5:before {
    content: "\ea89";
    margin-left: -1em;
  }
  
  .ler-category .path1:before {
    content: "\ea8a";
  }
  
  .ler-category .path2:before {
    content: "\ea8b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-category .path3:before {
    content: "\ea8c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-category .path4:before {
    content: "\ea8d";
    margin-left: -1em;
  }
  
  .ler-cd .path1:before {
    content: "\ea8e";
  }
  
  .ler-cd .path2:before {
    content: "\ea8f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-celsius .path1:before {
    content: "\ea90";
  }
  
  .ler-celsius .path2:before {
    content: "\ea91";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-line-down-2 .path1:before {
    content: "\ea92";
    opacity: 0.3;
  }
  
  .ler-chart-line-down-2 .path2:before {
    content: "\ea93";
    margin-left: -1em;
  }
  
  .ler-chart-line-down-2 .path3:before {
    content: "\ea94";
    margin-left: -1em;
  }
  
  .ler-chart-line-down .path1:before {
    content: "\ea95";
    opacity: 0.3;
  }
  
  .ler-chart-line-down .path2:before {
    content: "\ea96";
    margin-left: -1em;
  }
  
  .ler-chart-line-star .path1:before {
    content: "\ea97";
  }
  
  .ler-chart-line-star .path2:before {
    content: "\ea98";
    margin-left: -1em;
  }
  
  .ler-chart-line-star .path3:before {
    content: "\ea99";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-line-up-2 .path1:before {
    content: "\ea9a";
  }
  
  .ler-chart-line-up-2 .path2:before {
    content: "\ea9b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-line-up .path1:before {
    content: "\ea9c";
  }
  
  .ler-chart-line-up .path2:before {
    content: "\ea9d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-line .path1:before {
    content: "\ea9e";
    opacity: 0.3;
  }
  
  .ler-chart-line .path2:before {
    content: "\ea9f";
    margin-left: -1em;
  }
  
  .ler-chart-pie-3 .path1:before {
    content: "\eaa0";
  }
  
  .ler-chart-pie-3 .path2:before {
    content: "\eaa1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-pie-3 .path3:before {
    content: "\eaa2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-pie-4 .path1:before {
    content: "\eaa3";
  }
  
  .ler-chart-pie-4 .path2:before {
    content: "\eaa4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-pie-4 .path3:before {
    content: "\eaa5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-pie-simple .path1:before {
    content: "\eaa6";
  }
  
  .ler-chart-pie-simple .path2:before {
    content: "\eaa7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-pie-too .path1:before {
    content: "\eaa8";
    opacity: 0.3;
  }
  
  .ler-chart-pie-too .path2:before {
    content: "\eaa9";
    margin-left: -1em;
  }
  
  .ler-chart-simple-2 .path1:before {
    content: "\eaaa";
    opacity: 0.3;
  }
  
  .ler-chart-simple-2 .path2:before {
    content: "\eaab";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-simple-2 .path3:before {
    content: "\eaac";
    margin-left: -1em;
  }
  
  .ler-chart-simple-2 .path4:before {
    content: "\eaad";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-simple-3 .path1:before {
    content: "\eaae";
    opacity: 0.3;
  }
  
  .ler-chart-simple-3 .path2:before {
    content: "\eaaf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-simple-3 .path3:before {
    content: "\eab0";
    margin-left: -1em;
  }
  
  .ler-chart-simple-3 .path4:before {
    content: "\eab1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-simple .path1:before {
    content: "\eab2";
    opacity: 0.3;
  }
  
  .ler-chart-simple .path2:before {
    content: "\eab3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart-simple .path3:before {
    content: "\eab4";
    margin-left: -1em;
  }
  
  .ler-chart-simple .path4:before {
    content: "\eab5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-chart .path1:before {
    content: "\eab6";
  }
  
  .ler-chart .path2:before {
    content: "\eab7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-check-circle .path1:before {
    content: "\eab8";
    opacity: 0.3;
  }
  
  .ler-check-circle .path2:before {
    content: "\eab9";
    margin-left: -1em;
  }
  
  .ler-check-square .path1:before {
    content: "\eaba";
    opacity: 0.3;
  }
  
  .ler-check-square .path2:before {
    content: "\eabb";
    margin-left: -1em;
  }
  
  .ler-check:before {
    content: "\eabc";
  }
  
  .ler-cheque .path1:before {
    content: "\eabd";
  }
  
  .ler-cheque .path2:before {
    content: "\eabe";
    margin-left: -1em;
  }
  
  .ler-cheque .path3:before {
    content: "\eabf";
    margin-left: -1em;
  }
  
  .ler-cheque .path4:before {
    content: "\eac0";
    margin-left: -1em;
  }
  
  .ler-cheque .path5:before {
    content: "\eac1";
    margin-left: -1em;
  }
  
  .ler-cheque .path6:before {
    content: "\eac2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-cheque .path7:before {
    content: "\eac3";
    margin-left: -1em;
  }
  
  .ler-chrome .path1:before {
    content: "\eac4";
    opacity: 0.3;
  }
  
  .ler-chrome .path2:before {
    content: "\eac5";
    margin-left: -1em;
  }
  
  .ler-classmates .path1:before {
    content: "\eac6";
  }
  
  .ler-classmates .path2:before {
    content: "\eac7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-click .path1:before {
    content: "\eac8";
  }
  
  .ler-click .path2:before {
    content: "\eac9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-click .path3:before {
    content: "\eaca";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-click .path4:before {
    content: "\eacb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-click .path5:before {
    content: "\eacc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-clipboard .path1:before {
    content: "\eacd";
    opacity: 0.3;
  }
  
  .ler-clipboard .path2:before {
    content: "\eace";
    margin-left: -1em;
  }
  
  .ler-clipboard .path3:before {
    content: "\eacf";
    margin-left: -1em;
  }
  
  .ler-cloud-add .path1:before {
    content: "\ead0";
  }
  
  .ler-cloud-add .path2:before {
    content: "\ead1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-cloud-change .path1:before {
    content: "\ead2";
  }
  
  .ler-cloud-change .path2:before {
    content: "\ead3";
    margin-left: -1em;
  }
  
  .ler-cloud-change .path3:before {
    content: "\ead4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-cloud-download .path1:before {
    content: "\ead5";
    opacity: 0.3;
  }
  
  .ler-cloud-download .path2:before {
    content: "\ead6";
    margin-left: -1em;
  }
  
  .ler-cloud:before {
    content: "\ead7";
  }
  
  .ler-code .path1:before {
    content: "\ead8";
    opacity: 0.3;
  }
  
  .ler-code .path2:before {
    content: "\ead9";
    margin-left: -1em;
  }
  
  .ler-code .path3:before {
    content: "\eada";
    margin-left: -1em;
  }
  
  .ler-code .path4:before {
    content: "\eadb";
    margin-left: -1em;
  }
  
  .ler-coffee .path1:before {
    content: "\eadc";
    opacity: 0.3;
  }
  
  .ler-coffee .path2:before {
    content: "\eadd";
    margin-left: -1em;
  }
  
  .ler-coffee .path3:before {
    content: "\eade";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-coffee .path4:before {
    content: "\eadf";
    margin-left: -1em;
  }
  
  .ler-coffee .path5:before {
    content: "\eae0";
    margin-left: -1em;
  }
  
  .ler-coffee .path6:before {
    content: "\eae1";
    margin-left: -1em;
  }
  
  .ler-color-swatch .path1:before {
    content: "\eae2";
    opacity: 0.3;
  }
  
  .ler-color-swatch .path2:before {
    content: "\eae3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path3:before {
    content: "\eae4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path4:before {
    content: "\eae5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path5:before {
    content: "\eae6";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path6:before {
    content: "\eae7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path7:before {
    content: "\eae8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path8:before {
    content: "\eae9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path9:before {
    content: "\eaea";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path10:before {
    content: "\eaeb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path11:before {
    content: "\eaec";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path12:before {
    content: "\eaed";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path13:before {
    content: "\eaee";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path14:before {
    content: "\eaef";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path15:before {
    content: "\eaf0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path16:before {
    content: "\eaf1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path17:before {
    content: "\eaf2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path18:before {
    content: "\eaf3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path19:before {
    content: "\eaf4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path20:before {
    content: "\eaf5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-color-swatch .path21:before {
    content: "\eaf6";
    margin-left: -1em;
  }
  
  .ler-colors-square .path1:before {
    content: "\eaf7";
    opacity: 0.3;
  }
  
  .ler-colors-square .path2:before {
    content: "\eaf8";
    margin-left: -1em;
  }
  
  .ler-colors-square .path3:before {
    content: "\eaf9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-colors-square .path4:before {
    content: "\eafa";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-compass .path1:before {
    content: "\eafb";
  }
  
  .ler-compass .path2:before {
    content: "\eafc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-copy-success .path1:before {
    content: "\eafd";
  }
  
  .ler-copy-success .path2:before {
    content: "\eafe";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-copy:before {
    content: "\eaff";
  }
  
  .ler-courier-express .path1:before {
    content: "\eb00";
  }
  
  .ler-courier-express .path2:before {
    content: "\eb01";
    margin-left: -1em;
  }
  
  .ler-courier-express .path3:before {
    content: "\eb02";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-courier-express .path4:before {
    content: "\eb03";
    margin-left: -1em;
  }
  
  .ler-courier-express .path5:before {
    content: "\eb04";
    margin-left: -1em;
  }
  
  .ler-courier-express .path6:before {
    content: "\eb05";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-courier-express .path7:before {
    content: "\eb06";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-courier .path1:before {
    content: "\eb07";
  }
  
  .ler-courier .path2:before {
    content: "\eb08";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-courier .path3:before {
    content: "\eb09";
    margin-left: -1em;
  }
  
  .ler-credit-cart .path1:before {
    content: "\eb0a";
    opacity: 0.3;
  }
  
  .ler-credit-cart .path2:before {
    content: "\eb0b";
    margin-left: -1em;
  }
  
  .ler-cross-circle .path1:before {
    content: "\eb0c";
    opacity: 0.3;
  }
  
  .ler-cross-circle .path2:before {
    content: "\eb0d";
    margin-left: -1em;
  }
  
  .ler-cross-square .path1:before {
    content: "\eb0e";
    opacity: 0.3;
  }
  
  .ler-cross-square .path2:before {
    content: "\eb0f";
    margin-left: -1em;
  }
  
  .ler-cross .path1:before {
    content: "\eb10";
  }
  
  .ler-cross .path2:before {
    content: "\eb11";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-crown-2 .path1:before {
    content: "\eb12";
    opacity: 0.3;
  }
  
  .ler-crown-2 .path2:before {
    content: "\eb13";
    margin-left: -1em;
  }
  
  .ler-crown-2 .path3:before {
    content: "\eb14";
    margin-left: -1em;
  }
  
  .ler-crown .path1:before {
    content: "\eb15";
    opacity: 0.3;
  }
  
  .ler-crown .path2:before {
    content: "\eb16";
    margin-left: -1em;
  }
  
  .ler-css .path1:before {
    content: "\eb17";
    opacity: 0.3;
  }
  
  .ler-css .path2:before {
    content: "\eb18";
    margin-left: -1em;
  }
  
  .ler-cube-2 .path1:before {
    content: "\eb19";
    opacity: 0.3;
  }
  
  .ler-cube-2 .path2:before {
    content: "\eb1a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-cube-2 .path3:before {
    content: "\eb1b";
    margin-left: -1em;
  }
  
  .ler-cube-3 .path1:before {
    content: "\eb1c";
    opacity: 0.3;
  }
  
  .ler-cube-3 .path2:before {
    content: "\eb1d";
    margin-left: -1em;
  }
  
  .ler-cup .path1:before {
    content: "\eb1e";
    opacity: 0.3;
  }
  
  .ler-cup .path2:before {
    content: "\eb1f";
    margin-left: -1em;
  }
  
  .ler-dash .path1:before {
    content: "\eb20";
    opacity: 0.3;
  }
  
  .ler-dash .path2:before {
    content: "\eb21";
    margin-left: -1em;
  }
  
  .ler-data .path1:before {
    content: "\eb22";
  }
  
  .ler-data .path2:before {
    content: "\eb23";
    margin-left: -1em;
  }
  
  .ler-data .path3:before {
    content: "\eb24";
    margin-left: -1em;
  }
  
  .ler-data .path4:before {
    content: "\eb25";
    margin-left: -1em;
  }
  
  .ler-data .path5:before {
    content: "\eb26";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delete-files .path1:before {
    content: "\eb27";
  }
  
  .ler-delete-files .path2:before {
    content: "\eb28";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delete-folder .path1:before {
    content: "\eb29";
    opacity: 0.3;
  }
  
  .ler-delete-folder .path2:before {
    content: "\eb2a";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path1:before {
    content: "\eb2b";
    opacity: 0.3;
  }
  
  .ler-delivery-2 .path2:before {
    content: "\eb2c";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path3:before {
    content: "\eb2d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery-2 .path4:before {
    content: "\eb2e";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path5:before {
    content: "\eb2f";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path6:before {
    content: "\eb30";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path7:before {
    content: "\eb31";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery-2 .path8:before {
    content: "\eb32";
    margin-left: -1em;
  }
  
  .ler-delivery-2 .path9:before {
    content: "\eb33";
    margin-left: -1em;
  }
  
  .ler-delivery-3 .path1:before {
    content: "\eb34";
    opacity: 0.3;
  }
  
  .ler-delivery-3 .path2:before {
    content: "\eb35";
    margin-left: -1em;
  }
  
  .ler-delivery-3 .path3:before {
    content: "\eb36";
    margin-left: -1em;
  }
  
  .ler-delivery-24 .path1:before {
    content: "\eb37";
  }
  
  .ler-delivery-24 .path2:before {
    content: "\eb38";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery-24 .path3:before {
    content: "\eb39";
    margin-left: -1em;
  }
  
  .ler-delivery-24 .path4:before {
    content: "\eb3a";
    margin-left: -1em;
  }
  
  .ler-delivery-door .path1:before {
    content: "\eb3b";
    opacity: 0.3;
  }
  
  .ler-delivery-door .path2:before {
    content: "\eb3c";
    margin-left: -1em;
  }
  
  .ler-delivery-door .path3:before {
    content: "\eb3d";
    margin-left: -1em;
  }
  
  .ler-delivery-door .path4:before {
    content: "\eb3e";
    margin-left: -1em;
  }
  
  .ler-delivery-geolocation .path1:before {
    content: "\eb3f";
  }
  
  .ler-delivery-geolocation .path2:before {
    content: "\eb40";
    margin-left: -1em;
  }
  
  .ler-delivery-geolocation .path3:before {
    content: "\eb41";
    margin-left: -1em;
  }
  
  .ler-delivery-geolocation .path4:before {
    content: "\eb42";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery-geolocation .path5:before {
    content: "\eb43";
    margin-left: -1em;
  }
  
  .ler-delivery-time .path1:before {
    content: "\eb44";
  }
  
  .ler-delivery-time .path2:before {
    content: "\eb45";
    margin-left: -1em;
  }
  
  .ler-delivery-time .path3:before {
    content: "\eb46";
    margin-left: -1em;
  }
  
  .ler-delivery-time .path4:before {
    content: "\eb47";
    margin-left: -1em;
  }
  
  .ler-delivery-time .path5:before {
    content: "\eb48";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery .path1:before {
    content: "\eb49";
  }
  
  .ler-delivery .path2:before {
    content: "\eb4a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-delivery .path3:before {
    content: "\eb4b";
    margin-left: -1em;
  }
  
  .ler-delivery .path4:before {
    content: "\eb4c";
    margin-left: -1em;
  }
  
  .ler-delivery .path5:before {
    content: "\eb4d";
    margin-left: -1em;
  }
  
  .ler-design-2 .path1:before {
    content: "\eb4e";
    opacity: 0.3;
  }
  
  .ler-design-2 .path2:before {
    content: "\eb4f";
    margin-left: -1em;
  }
  
  .ler-design-frame .path1:before {
    content: "\eb50";
    opacity: 0.3;
  }
  
  .ler-design-frame .path2:before {
    content: "\eb51";
    margin-left: -1em;
  }
  
  .ler-design-mask .path1:before {
    content: "\eb52";
    opacity: 0.3;
  }
  
  .ler-design-mask .path2:before {
    content: "\eb53";
    margin-left: -1em;
  }
  
  .ler-design .path1:before {
    content: "\eb54";
    opacity: 0.3;
  }
  
  .ler-design .path2:before {
    content: "\eb55";
    margin-left: -1em;
  }
  
  .ler-devices-2 .path1:before {
    content: "\eb56";
    opacity: 0.3;
  }
  
  .ler-devices-2 .path2:before {
    content: "\eb57";
    margin-left: -1em;
  }
  
  .ler-devices-2 .path3:before {
    content: "\eb58";
    margin-left: -1em;
  }
  
  .ler-devices .path1:before {
    content: "\eb59";
    opacity: 0.3;
  }
  
  .ler-devices .path2:before {
    content: "\eb5a";
    margin-left: -1em;
  }
  
  .ler-devices .path3:before {
    content: "\eb5b";
    margin-left: -1em;
  }
  
  .ler-devices .path4:before {
    content: "\eb5c";
    margin-left: -1em;
  }
  
  .ler-devices .path5:before {
    content: "\eb5d";
    margin-left: -1em;
  }
  
  .ler-diamonds .path1:before {
    content: "\eb5e";
  }
  
  .ler-diamonds .path2:before {
    content: "\eb5f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-directbox-default .path1:before {
    content: "\eb60";
  }
  
  .ler-directbox-default .path2:before {
    content: "\eb61";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-directbox-default .path3:before {
    content: "\eb62";
    margin-left: -1em;
  }
  
  .ler-directbox-default .path4:before {
    content: "\eb63";
    margin-left: -1em;
  }
  
  .ler-disconnect .path1:before {
    content: "\eb64";
  }
  
  .ler-disconnect .path2:before {
    content: "\eb65";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-disconnect .path3:before {
    content: "\eb66";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-disconnect .path4:before {
    content: "\eb67";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-disconnect .path5:before {
    content: "\eb68";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-discount .path1:before {
    content: "\eb69";
    opacity: 0.3;
  }
  
  .ler-discount .path2:before {
    content: "\eb6a";
    margin-left: -1em;
  }
  
  .ler-disk .path1:before {
    content: "\eb6b";
    opacity: 0.3;
  }
  
  .ler-disk .path2:before {
    content: "\eb6c";
    margin-left: -1em;
  }
  
  .ler-dislike .path1:before {
    content: "\eb6d";
  }
  
  .ler-dislike .path2:before {
    content: "\eb6e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dj:before {
    content: "\eb6f";
  }
  
  .ler-document .path1:before {
    content: "\eb70";
    opacity: 0.3;
  }
  
  .ler-document .path2:before {
    content: "\eb71";
    margin-left: -1em;
  }
  
  .ler-dollar .path1:before {
    content: "\eb72";
    opacity: 0.3;
  }
  
  .ler-dollar .path2:before {
    content: "\eb73";
    margin-left: -1em;
  }
  
  .ler-dollar .path3:before {
    content: "\eb74";
    margin-left: -1em;
  }
  
  .ler-dots-circle-vertical .path1:before {
    content: "\eb75";
    opacity: 0.3;
  }
  
  .ler-dots-circle-vertical .path2:before {
    content: "\eb76";
    margin-left: -1em;
  }
  
  .ler-dots-circle-vertical .path3:before {
    content: "\eb77";
    margin-left: -1em;
  }
  
  .ler-dots-circle-vertical .path4:before {
    content: "\eb78";
    margin-left: -1em;
  }
  
  .ler-dots-circle .path1:before {
    content: "\eb79";
    opacity: 0.3;
  }
  
  .ler-dots-circle .path2:before {
    content: "\eb7a";
    margin-left: -1em;
  }
  
  .ler-dots-circle .path3:before {
    content: "\eb7b";
    margin-left: -1em;
  }
  
  .ler-dots-circle .path4:before {
    content: "\eb7c";
    margin-left: -1em;
  }
  
  .ler-dots-horizontal .path1:before {
    content: "\eb7d";
  }
  
  .ler-dots-horizontal .path2:before {
    content: "\eb7e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dots-horizontal .path3:before {
    content: "\eb7f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dots-square-vertical .path1:before {
    content: "\eb80";
    opacity: 0.3;
  }
  
  .ler-dots-square-vertical .path2:before {
    content: "\eb81";
    margin-left: -1em;
  }
  
  .ler-dots-square-vertical .path3:before {
    content: "\eb82";
    margin-left: -1em;
  }
  
  .ler-dots-square-vertical .path4:before {
    content: "\eb83";
    margin-left: -1em;
  }
  
  .ler-dots-square .path1:before {
    content: "\eb84";
    opacity: 0.3;
  }
  
  .ler-dots-square .path2:before {
    content: "\eb85";
    margin-left: -1em;
  }
  
  .ler-dots-square .path3:before {
    content: "\eb86";
    margin-left: -1em;
  }
  
  .ler-dots-square .path4:before {
    content: "\eb87";
    margin-left: -1em;
  }
  
  .ler-dots-vertical .path1:before {
    content: "\eb88";
  }
  
  .ler-dots-vertical .path2:before {
    content: "\eb89";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dots-vertical .path3:before {
    content: "\eb8a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-check-circle .path1:before {
    content: "\eb8b";
    opacity: 0.3;
  }
  
  .ler-double-check-circle .path2:before {
    content: "\eb8c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-check-circle .path3:before {
    content: "\eb8d";
    margin-left: -1em;
  }
  
  .ler-double-check .path1:before {
    content: "\eb8e";
  }
  
  .ler-double-check .path2:before {
    content: "\eb8f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-down .path1:before {
    content: "\eb90";
  }
  
  .ler-double-down .path2:before {
    content: "\eb91";
    margin-left: -1em;
  }
  
  .ler-double-down .path3:before {
    content: "\eb92";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-left-arrow .path1:before {
    content: "\eb93";
    opacity: 0.3;
  }
  
  .ler-double-left-arrow .path2:before {
    content: "\eb94";
    margin-left: -1em;
  }
  
  .ler-double-left .path1:before {
    content: "\eb95";
  }
  
  .ler-double-left .path2:before {
    content: "\eb96";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-right-arrow .path1:before {
    content: "\eb97";
    opacity: 0.3;
  }
  
  .ler-double-right-arrow .path2:before {
    content: "\eb98";
    margin-left: -1em;
  }
  
  .ler-double-right .path1:before {
    content: "\eb99";
  }
  
  .ler-double-right .path2:before {
    content: "\eb9a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-double-up .path1:before {
    content: "\eb9b";
  }
  
  .ler-double-up .path2:before {
    content: "\eb9c";
    margin-left: -1em;
  }
  
  .ler-double-up .path3:before {
    content: "\eb9d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-down-square .path1:before {
    content: "\eb9e";
    opacity: 0.3;
  }
  
  .ler-down-square .path2:before {
    content: "\eb9f";
    margin-left: -1em;
  }
  
  .ler-down:before {
    content: "\eba0";
  }
  
  .ler-dribbble .path1:before {
    content: "\eba1";
  }
  
  .ler-dribbble .path2:before {
    content: "\eba2";
    margin-left: -1em;
  }
  
  .ler-dribbble .path3:before {
    content: "\eba3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dribbble .path4:before {
    content: "\eba4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dribbble .path5:before {
    content: "\eba5";
    margin-left: -1em;
  }
  
  .ler-dribbble .path6:before {
    content: "\eba6";
    margin-left: -1em;
  }
  
  .ler-drop .path1:before {
    content: "\eba7";
  }
  
  .ler-drop .path2:before {
    content: "\eba8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-dropbox .path1:before {
    content: "\eba9";
    opacity: 0.4;
  }
  
  .ler-dropbox .path2:before {
    content: "\ebaa";
    margin-left: -1em;
    opacity: 0.4;
  }
  
  .ler-dropbox .path3:before {
    content: "\ebab";
    margin-left: -1em;
    opacity: 0.4;
  }
  
  .ler-dropbox .path4:before {
    content: "\ebac";
    margin-left: -1em;
    opacity: 0.4;
  }
  
  .ler-dropbox .path5:before {
    content: "\ebad";
    margin-left: -1em;
  }
  
  .ler-educare .path1:before {
    content: "\ebae";
    opacity: 0.3;
  }
  
  .ler-educare .path2:before {
    content: "\ebaf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-educare .path3:before {
    content: "\ebb0";
    margin-left: -1em;
  }
  
  .ler-educare .path4:before {
    content: "\ebb1";
    margin-left: -1em;
  }
  
  .ler-electricity .path1:before {
    content: "\ebb2";
  }
  
  .ler-electricity .path2:before {
    content: "\ebb3";
    margin-left: -1em;
  }
  
  .ler-electricity .path3:before {
    content: "\ebb4";
    margin-left: -1em;
  }
  
  .ler-electricity .path4:before {
    content: "\ebb5";
    margin-left: -1em;
  }
  
  .ler-electricity .path5:before {
    content: "\ebb6";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-electricity .path6:before {
    content: "\ebb7";
    margin-left: -1em;
  }
  
  .ler-electricity .path7:before {
    content: "\ebb8";
    margin-left: -1em;
  }
  
  .ler-electricity .path8:before {
    content: "\ebb9";
    margin-left: -1em;
  }
  
  .ler-electricity .path9:before {
    content: "\ebba";
    margin-left: -1em;
  }
  
  .ler-electricity .path10:before {
    content: "\ebbb";
    margin-left: -1em;
  }
  
  .ler-electronic-clock .path1:before {
    content: "\ebbc";
    opacity: 0.3;
  }
  
  .ler-electronic-clock .path2:before {
    content: "\ebbd";
    margin-left: -1em;
  }
  
  .ler-electronic-clock .path3:before {
    content: "\ebbe";
    margin-left: -1em;
  }
  
  .ler-electronic-clock .path4:before {
    content: "\ebbf";
    margin-left: -1em;
  }
  
  .ler-element-1 .path1:before {
    content: "\ebc0";
  }
  
  .ler-element-1 .path2:before {
    content: "\ebc1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-1 .path3:before {
    content: "\ebc2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-1 .path4:before {
    content: "\ebc3";
    margin-left: -1em;
  }
  
  .ler-element-2 .path1:before {
    content: "\ebc4";
  }
  
  .ler-element-2 .path2:before {
    content: "\ebc5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-3 .path1:before {
    content: "\ebc6";
    opacity: 0.3;
  }
  
  .ler-element-3 .path2:before {
    content: "\ebc7";
    margin-left: -1em;
  }
  
  .ler-element-4 .path1:before {
    content: "\ebc8";
  }
  
  .ler-element-4 .path2:before {
    content: "\ebc9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-5 .path1:before {
    content: "\ebca";
  }
  
  .ler-element-5 .path2:before {
    content: "\ebcb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-6 .path1:before {
    content: "\ebcc";
    opacity: 0.3;
  }
  
  .ler-element-6 .path2:before {
    content: "\ebcd";
    margin-left: -1em;
  }
  
  .ler-element-7 .path1:before {
    content: "\ebce";
  }
  
  .ler-element-7 .path2:before {
    content: "\ebcf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-8 .path1:before {
    content: "\ebd0";
    opacity: 0.3;
  }
  
  .ler-element-8 .path2:before {
    content: "\ebd1";
    margin-left: -1em;
  }
  
  .ler-element-9 .path1:before {
    content: "\ebd2";
    opacity: 0.3;
  }
  
  .ler-element-9 .path2:before {
    content: "\ebd3";
    margin-left: -1em;
  }
  
  .ler-element-10 .path1:before {
    content: "\ebd4";
  }
  
  .ler-element-10 .path2:before {
    content: "\ebd5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-10 .path3:before {
    content: "\ebd6";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-11 .path1:before {
    content: "\ebd7";
  }
  
  .ler-element-11 .path2:before {
    content: "\ebd8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-11 .path3:before {
    content: "\ebd9";
    margin-left: -1em;
  }
  
  .ler-element-11 .path4:before {
    content: "\ebda";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-12 .path1:before {
    content: "\ebdb";
    opacity: 0.3;
  }
  
  .ler-element-12 .path2:before {
    content: "\ebdc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-12 .path3:before {
    content: "\ebdd";
    margin-left: -1em;
  }
  
  .ler-element-equal .path1:before {
    content: "\ebde";
  }
  
  .ler-element-equal .path2:before {
    content: "\ebdf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-equal .path3:before {
    content: "\ebe0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-equal .path4:before {
    content: "\ebe1";
    margin-left: -1em;
  }
  
  .ler-element-equal .path5:before {
    content: "\ebe2";
    margin-left: -1em;
  }
  
  .ler-element-plus .path1:before {
    content: "\ebe3";
  }
  
  .ler-element-plus .path2:before {
    content: "\ebe4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-plus .path3:before {
    content: "\ebe5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-element-plus .path4:before {
    content: "\ebe6";
    margin-left: -1em;
  }
  
  .ler-element-plus .path5:before {
    content: "\ebe7";
    margin-left: -1em;
  }
  
  .ler-emoji-happy .path1:before {
    content: "\ebe8";
    opacity: 0.3;
  }
  
  .ler-emoji-happy .path2:before {
    content: "\ebe9";
    margin-left: -1em;
  }
  
  .ler-emoji-happy .path3:before {
    content: "\ebea";
    margin-left: -1em;
  }
  
  .ler-emoji-happy .path4:before {
    content: "\ebeb";
    margin-left: -1em;
  }
  
  .ler-enjin-coin .path1:before {
    content: "\ebec";
  }
  
  .ler-enjin-coin .path2:before {
    content: "\ebed";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-entrance-left .path1:before {
    content: "\ebee";
  }
  
  .ler-entrance-left .path2:before {
    content: "\ebef";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-entrance-right .path1:before {
    content: "\ebf0";
    opacity: 0.3;
  }
  
  .ler-entrance-right .path2:before {
    content: "\ebf1";
    margin-left: -1em;
  }
  
  .ler-eraser .path1:before {
    content: "\ebf2";
    opacity: 0.3;
  }
  
  .ler-eraser .path2:before {
    content: "\ebf3";
    margin-left: -1em;
  }
  
  .ler-eraser .path3:before {
    content: "\ebf4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-euro .path1:before {
    content: "\ebf5";
    opacity: 0.3;
  }
  
  .ler-euro .path2:before {
    content: "\ebf6";
    margin-left: -1em;
  }
  
  .ler-euro .path3:before {
    content: "\ebf7";
    margin-left: -1em;
  }
  
  .ler-exit-down .path1:before {
    content: "\ebf8";
    opacity: 0.3;
  }
  
  .ler-exit-down .path2:before {
    content: "\ebf9";
    margin-left: -1em;
  }
  
  .ler-exit-left .path1:before {
    content: "\ebfa";
    opacity: 0.3;
  }
  
  .ler-exit-left .path2:before {
    content: "\ebfb";
    margin-left: -1em;
  }
  
  .ler-exit-right-corner .path1:before {
    content: "\ebfc";
    opacity: 0.3;
  }
  
  .ler-exit-right-corner .path2:before {
    content: "\ebfd";
    margin-left: -1em;
  }
  
  .ler-exit-right .path1:before {
    content: "\ebfe";
    opacity: 0.3;
  }
  
  .ler-exit-right .path2:before {
    content: "\ebff";
    margin-left: -1em;
  }
  
  .ler-exit-up .path1:before {
    content: "\ec00";
    opacity: 0.3;
  }
  
  .ler-exit-up .path2:before {
    content: "\ec01";
    margin-left: -1em;
  }
  
  .ler-external-drive .path1:before {
    content: "\ec02";
  }
  
  .ler-external-drive .path2:before {
    content: "\ec03";
    margin-left: -1em;
  }
  
  .ler-external-drive .path3:before {
    content: "\ec04";
    margin-left: -1em;
  }
  
  .ler-external-drive .path4:before {
    content: "\ec05";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-external-drive .path5:before {
    content: "\ec06";
    margin-left: -1em;
  }
  
  .ler-eye-slash .path1:before {
    content: "\ec07";
  }
  
  .ler-eye-slash .path2:before {
    content: "\ec08";
    margin-left: -1em;
  }
  
  .ler-eye-slash .path3:before {
    content: "\ec09";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-eye-slash .path4:before {
    content: "\ec0a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-eye .path1:before {
    content: "\ec0b";
  }
  
  .ler-eye .path2:before {
    content: "\ec0c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-eye .path3:before {
    content: "\ec0d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-facebook .path1:before {
    content: "\ec0e";
    opacity: 0.3;
  }
  
  .ler-facebook .path2:before {
    content: "\ec0f";
    margin-left: -1em;
  }
  
  .ler-faceid .path1:before {
    content: "\ec10";
    opacity: 0.3;
  }
  
  .ler-faceid .path2:before {
    content: "\ec11";
    margin-left: -1em;
  }
  
  .ler-faceid .path3:before {
    content: "\ec12";
    margin-left: -1em;
  }
  
  .ler-faceid .path4:before {
    content: "\ec13";
    margin-left: -1em;
  }
  
  .ler-faceid .path5:before {
    content: "\ec14";
    margin-left: -1em;
  }
  
  .ler-faceid .path6:before {
    content: "\ec15";
    margin-left: -1em;
  }
  
  .ler-fasten .path1:before {
    content: "\ec16";
    opacity: 0.3;
  }
  
  .ler-fasten .path2:before {
    content: "\ec17";
    margin-left: -1em;
  }
  
  .ler-fat-rows .path1:before {
    content: "\ec18";
  }
  
  .ler-fat-rows .path2:before {
    content: "\ec19";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-feather .path1:before {
    content: "\ec1a";
    opacity: 0.3;
  }
  
  .ler-feather .path2:before {
    content: "\ec1b";
    margin-left: -1em;
  }
  
  .ler-figma .path1:before {
    content: "\ec1c";
    opacity: 0.4;
  }
  
  .ler-figma .path2:before {
    content: "\ec1d";
    margin-left: -1em;
    opacity: 0.4;
  }
  
  .ler-figma .path3:before {
    content: "\ec1e";
    margin-left: -1em;
  }
  
  .ler-figma .path4:before {
    content: "\ec1f";
    margin-left: -1em;
  }
  
  .ler-figma .path5:before {
    content: "\ec20";
    margin-left: -1em;
  }
  
  .ler-file-added .path1:before {
    content: "\ec21";
  }
  
  .ler-file-added .path2:before {
    content: "\ec22";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-file-deleted .path1:before {
    content: "\ec23";
  }
  
  .ler-file-deleted .path2:before {
    content: "\ec24";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-file-down .path1:before {
    content: "\ec25";
    opacity: 0.3;
  }
  
  .ler-file-down .path2:before {
    content: "\ec26";
    margin-left: -1em;
  }
  
  .ler-file-left .path1:before {
    content: "\ec27";
    opacity: 0.3;
  }
  
  .ler-file-left .path2:before {
    content: "\ec28";
    margin-left: -1em;
  }
  
  .ler-file-right .path1:before {
    content: "\ec29";
    opacity: 0.3;
  }
  
  .ler-file-right .path2:before {
    content: "\ec2a";
    margin-left: -1em;
  }
  
  .ler-file-sheet .path1:before {
    content: "\ec2b";
  }
  
  .ler-file-sheet .path2:before {
    content: "\ec2c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-file-up .path1:before {
    content: "\ec2d";
    opacity: 0.3;
  }
  
  .ler-file-up .path2:before {
    content: "\ec2e";
    margin-left: -1em;
  }
  
  .ler-file .path1:before {
    content: "\ec2f";
  }
  
  .ler-file .path2:before {
    content: "\ec30";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-files-tablet .path1:before {
    content: "\ec31";
  }
  
  .ler-files-tablet .path2:before {
    content: "\ec32";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-filter-edit .path1:before {
    content: "\ec33";
    opacity: 0.3;
  }
  
  .ler-filter-edit .path2:before {
    content: "\ec34";
    margin-left: -1em;
  }
  
  .ler-filter-search .path1:before {
    content: "\ec35";
    opacity: 0.3;
  }
  
  .ler-filter-search .path2:before {
    content: "\ec36";
    margin-left: -1em;
  }
  
  .ler-filter-search .path3:before {
    content: "\ec37";
    margin-left: -1em;
  }
  
  .ler-filter-square .path1:before {
    content: "\ec38";
  }
  
  .ler-filter-square .path2:before {
    content: "\ec39";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-filter-tablet .path1:before {
    content: "\ec3a";
    opacity: 0.3;
  }
  
  .ler-filter-tablet .path2:before {
    content: "\ec3b";
    margin-left: -1em;
  }
  
  .ler-filter-tick .path1:before {
    content: "\ec3c";
    opacity: 0.3;
  }
  
  .ler-filter-tick .path2:before {
    content: "\ec3d";
    margin-left: -1em;
  }
  
  .ler-filter .path1:before {
    content: "\ec3e";
  }
  
  .ler-filter .path2:before {
    content: "\ec3f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-finance-calculator .path1:before {
    content: "\ec40";
    opacity: 0.3;
  }
  
  .ler-finance-calculator .path2:before {
    content: "\ec41";
    margin-left: -1em;
  }
  
  .ler-finance-calculator .path3:before {
    content: "\ec42";
    margin-left: -1em;
  }
  
  .ler-finance-calculator .path4:before {
    content: "\ec43";
    margin-left: -1em;
  }
  
  .ler-finance-calculator .path5:before {
    content: "\ec44";
    margin-left: -1em;
  }
  
  .ler-finance-calculator .path6:before {
    content: "\ec45";
    margin-left: -1em;
  }
  
  .ler-finance-calculator .path7:before {
    content: "\ec46";
    margin-left: -1em;
  }
  
  .ler-financial-schedule .path1:before {
    content: "\ec47";
    opacity: 0.3;
  }
  
  .ler-financial-schedule .path2:before {
    content: "\ec48";
    margin-left: -1em;
  }
  
  .ler-financial-schedule .path3:before {
    content: "\ec49";
    margin-left: -1em;
  }
  
  .ler-financial-schedule .path4:before {
    content: "\ec4a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-fingerprint-scanning .path1:before {
    content: "\ec4b";
  }
  
  .ler-fingerprint-scanning .path2:before {
    content: "\ec4c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-fingerprint-scanning .path3:before {
    content: "\ec4d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-fingerprint-scanning .path4:before {
    content: "\ec4e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-fingerprint-scanning .path5:before {
    content: "\ec4f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-flag .path1:before {
    content: "\ec50";
    opacity: 0.3;
  }
  
  .ler-flag .path2:before {
    content: "\ec51";
    margin-left: -1em;
  }
  
  .ler-flash-circle .path1:before {
    content: "\ec52";
    opacity: 0.3;
  }
  
  .ler-flash-circle .path2:before {
    content: "\ec53";
    margin-left: -1em;
  }
  
  .ler-flask .path1:before {
    content: "\ec54";
    opacity: 0.3;
  }
  
  .ler-flask .path2:before {
    content: "\ec55";
    margin-left: -1em;
  }
  
  .ler-focus .path1:before {
    content: "\ec56";
    opacity: 0.3;
  }
  
  .ler-focus .path2:before {
    content: "\ec57";
    margin-left: -1em;
  }
  
  .ler-folder-added .path1:before {
    content: "\ec58";
    opacity: 0.3;
  }
  
  .ler-folder-added .path2:before {
    content: "\ec59";
    margin-left: -1em;
  }
  
  .ler-folder-down .path1:before {
    content: "\ec5a";
    opacity: 0.3;
  }
  
  .ler-folder-down .path2:before {
    content: "\ec5b";
    margin-left: -1em;
  }
  
  .ler-folder-up .path1:before {
    content: "\ec5c";
    opacity: 0.3;
  }
  
  .ler-folder-up .path2:before {
    content: "\ec5d";
    margin-left: -1em;
  }
  
  .ler-folder .path1:before {
    content: "\ec5e";
    opacity: 0.3;
  }
  
  .ler-folder .path2:before {
    content: "\ec5f";
    margin-left: -1em;
  }
  
  .ler-frame .path1:before {
    content: "\ec60";
  }
  
  .ler-frame .path2:before {
    content: "\ec61";
    margin-left: -1em;
  }
  
  .ler-frame .path3:before {
    content: "\ec62";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-frame .path4:before {
    content: "\ec63";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-gear .path1:before {
    content: "\ec64";
    opacity: 0.3;
  }
  
  .ler-gear .path2:before {
    content: "\ec65";
    margin-left: -1em;
  }
  
  .ler-general-mouse .path1:before {
    content: "\ec66";
    opacity: 0.3;
  }
  
  .ler-general-mouse .path2:before {
    content: "\ec67";
    margin-left: -1em;
  }
  
  .ler-geolocation-home .path1:before {
    content: "\ec68";
    opacity: 0.3;
  }
  
  .ler-geolocation-home .path2:before {
    content: "\ec69";
    margin-left: -1em;
  }
  
  .ler-geolocation .path1:before {
    content: "\ec6a";
    opacity: 0.3;
  }
  
  .ler-geolocation .path2:before {
    content: "\ec6b";
    margin-left: -1em;
  }
  
  .ler-ghost .path1:before {
    content: "\ec6c";
  }
  
  .ler-ghost .path2:before {
    content: "\ec6d";
    margin-left: -1em;
  }
  
  .ler-ghost .path3:before {
    content: "\ec6e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-gift .path1:before {
    content: "\ec6f";
    opacity: 0.3;
  }
  
  .ler-gift .path2:before {
    content: "\ec70";
    margin-left: -1em;
  }
  
  .ler-gift .path3:before {
    content: "\ec71";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-gift .path4:before {
    content: "\ec72";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-github .path1:before {
    content: "\ec73";
    opacity: 0.3;
  }
  
  .ler-github .path2:before {
    content: "\ec74";
    margin-left: -1em;
  }
  
  .ler-glass .path1:before {
    content: "\ec75";
  }
  
  .ler-glass .path2:before {
    content: "\ec76";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-glass .path3:before {
    content: "\ec77";
    margin-left: -1em;
  }
  
  .ler-google-play .path1:before {
    content: "\ec78";
    opacity: 0.3;
  }
  
  .ler-google-play .path2:before {
    content: "\ec79";
    margin-left: -1em;
  }
  
  .ler-google .path1:before {
    content: "\ec7a";
    opacity: 0.3;
  }
  
  .ler-google .path2:before {
    content: "\ec7b";
    margin-left: -1em;
  }
  
  .ler-graph-2 .path1:before {
    content: "\ec7c";
    opacity: 0.3;
  }
  
  .ler-graph-2 .path2:before {
    content: "\ec7d";
    margin-left: -1em;
  }
  
  .ler-graph-2 .path3:before {
    content: "\ec7e";
    margin-left: -1em;
  }
  
  .ler-graph-3 .path1:before {
    content: "\ec7f";
    opacity: 0.3;
  }
  
  .ler-graph-3 .path2:before {
    content: "\ec80";
    margin-left: -1em;
  }
  
  .ler-graph-4 .path1:before {
    content: "\ec81";
  }
  
  .ler-graph-4 .path2:before {
    content: "\ec82";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-graph-up .path1:before {
    content: "\ec83";
    opacity: 0.3;
  }
  
  .ler-graph-up .path2:before {
    content: "\ec84";
    margin-left: -1em;
  }
  
  .ler-graph-up .path3:before {
    content: "\ec85";
    margin-left: -1em;
  }
  
  .ler-graph-up .path4:before {
    content: "\ec86";
    margin-left: -1em;
  }
  
  .ler-graph-up .path5:before {
    content: "\ec87";
    margin-left: -1em;
  }
  
  .ler-graph-up .path6:before {
    content: "\ec88";
    margin-left: -1em;
  }
  
  .ler-graph .path1:before {
    content: "\ec89";
    opacity: 0.3;
  }
  
  .ler-graph .path2:before {
    content: "\ec8a";
    margin-left: -1em;
  }
  
  .ler-graph .path3:before {
    content: "\ec8b";
    margin-left: -1em;
  }
  
  .ler-graph .path4:before {
    content: "\ec8c";
    margin-left: -1em;
  }
  
  .ler-grid-2 .path1:before {
    content: "\ec8d";
    opacity: 0.3;
  }
  
  .ler-grid-2 .path2:before {
    content: "\ec8e";
    margin-left: -1em;
  }
  
  .ler-grid-frame .path1:before {
    content: "\ec8f";
  }
  
  .ler-grid-frame .path2:before {
    content: "\ec90";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-grid-frame .path3:before {
    content: "\ec91";
    margin-left: -1em;
  }
  
  .ler-grid .path1:before {
    content: "\ec92";
    opacity: 0.3;
  }
  
  .ler-grid .path2:before {
    content: "\ec93";
    margin-left: -1em;
  }
  
  .ler-handcart:before {
    content: "\ec94";
  }
  
  .ler-happy-emoji .path1:before {
    content: "\ec95";
    opacity: 0.3;
  }
  
  .ler-happy-emoji .path2:before {
    content: "\ec96";
    margin-left: -1em;
  }
  
  .ler-heart-circle .path1:before {
    content: "\ec97";
    opacity: 0.3;
  }
  
  .ler-heart-circle .path2:before {
    content: "\ec98";
    margin-left: -1em;
  }
  
  .ler-heart .path1:before {
    content: "\ec99";
    opacity: 0.3;
  }
  
  .ler-heart .path2:before {
    content: "\ec9a";
    margin-left: -1em;
  }
  
  .ler-home-1 .path1:before {
    content: "\ec9b";
    opacity: 0.3;
  }
  
  .ler-home-1 .path2:before {
    content: "\ec9c";
    margin-left: -1em;
  }
  
  .ler-home-2 .path1:before {
    content: "\ec9d";
    opacity: 0.3;
  }
  
  .ler-home-2 .path2:before {
    content: "\ec9e";
    margin-left: -1em;
  }
  
  .ler-home-3 .path1:before {
    content: "\ec9f";
    opacity: 0.3;
  }
  
  .ler-home-3 .path2:before {
    content: "\eca0";
    margin-left: -1em;
  }
  
  .ler-home:before {
    content: "\eca1";
  }
  
  .ler-html .path1:before {
    content: "\eca2";
    opacity: 0.3;
  }
  
  .ler-html .path2:before {
    content: "\eca3";
    margin-left: -1em;
  }
  
  .ler-icon .path1:before {
    content: "\eca4";
    opacity: 0.3;
  }
  
  .ler-icon .path2:before {
    content: "\eca5";
    margin-left: -1em;
  }
  
  .ler-icon .path3:before {
    content: "\eca6";
    margin-left: -1em;
  }
  
  .ler-illustrator .path1:before {
    content: "\eca7";
    opacity: 0.3;
  }
  
  .ler-illustrator .path2:before {
    content: "\eca8";
    margin-left: -1em;
  }
  
  .ler-illustrator .path3:before {
    content: "\eca9";
    margin-left: -1em;
  }
  
  .ler-illustrator .path4:before {
    content: "\ecaa";
    margin-left: -1em;
  }
  
  .ler-information-2 .path1:before {
    content: "\ecab";
    opacity: 0.3;
  }
  
  .ler-information-2 .path2:before {
    content: "\ecac";
    margin-left: -1em;
  }
  
  .ler-information-2 .path3:before {
    content: "\ecad";
    margin-left: -1em;
  }
  
  .ler-information-3 .path1:before {
    content: "\ecae";
    opacity: 0.3;
  }
  
  .ler-information-3 .path2:before {
    content: "\ecaf";
    margin-left: -1em;
  }
  
  .ler-information-3 .path3:before {
    content: "\ecb0";
    margin-left: -1em;
  }
  
  .ler-information-4 .path1:before {
    content: "\ecb1";
    opacity: 0.3;
  }
  
  .ler-information-4 .path2:before {
    content: "\ecb2";
    margin-left: -1em;
  }
  
  .ler-information-4 .path3:before {
    content: "\ecb3";
    margin-left: -1em;
  }
  
  .ler-information-5 .path1:before {
    content: "\ecb4";
    opacity: 0.3;
  }
  
  .ler-information-5 .path2:before {
    content: "\ecb5";
    margin-left: -1em;
  }
  
  .ler-information-5 .path3:before {
    content: "\ecb6";
    margin-left: -1em;
  }
  
  .ler-information .path1:before {
    content: "\ecb7";
    opacity: 0.3;
  }
  
  .ler-information .path2:before {
    content: "\ecb8";
    margin-left: -1em;
  }
  
  .ler-information .path3:before {
    content: "\ecb9";
    margin-left: -1em;
  }
  
  .ler-instagram .path1:before {
    content: "\ecba";
    opacity: 0.3;
  }
  
  .ler-instagram .path2:before {
    content: "\ecbb";
    margin-left: -1em;
  }
  
  .ler-joystick .path1:before {
    content: "\ecbc";
  }
  
  .ler-joystick .path2:before {
    content: "\ecbd";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-joystick .path3:before {
    content: "\ecbe";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-joystick .path4:before {
    content: "\ecbf";
    margin-left: -1em;
  }
  
  .ler-js-2 .path1:before {
    content: "\ecc0";
  }
  
  .ler-js-2 .path2:before {
    content: "\ecc1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-js .path1:before {
    content: "\ecc2";
  }
  
  .ler-js .path2:before {
    content: "\ecc3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-kanban .path1:before {
    content: "\ecc4";
  }
  
  .ler-kanban .path2:before {
    content: "\ecc5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-key-square .path1:before {
    content: "\ecc6";
    opacity: 0.3;
  }
  
  .ler-key-square .path2:before {
    content: "\ecc7";
    margin-left: -1em;
  }
  
  .ler-key .path1:before {
    content: "\ecc8";
    opacity: 0.3;
  }
  
  .ler-key .path2:before {
    content: "\ecc9";
    margin-left: -1em;
  }
  
  .ler-keyboard .path1:before {
    content: "\ecca";
  }
  
  .ler-keyboard .path2:before {
    content: "\eccb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-laptop .path1:before {
    content: "\eccc";
    opacity: 0.3;
  }
  
  .ler-laptop .path2:before {
    content: "\eccd";
    margin-left: -1em;
  }
  
  .ler-laravel .path1:before {
    content: "\ecce";
    opacity: 0.3;
  }
  
  .ler-laravel .path2:before {
    content: "\eccf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-laravel .path3:before {
    content: "\ecd0";
    margin-left: -1em;
  }
  
  .ler-laravel .path4:before {
    content: "\ecd1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-laravel .path5:before {
    content: "\ecd2";
    margin-left: -1em;
  }
  
  .ler-laravel .path6:before {
    content: "\ecd3";
    margin-left: -1em;
  }
  
  .ler-laravel .path7:before {
    content: "\ecd4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-left-square .path1:before {
    content: "\ecd5";
    opacity: 0.3;
  }
  
  .ler-left-square .path2:before {
    content: "\ecd6";
    margin-left: -1em;
  }
  
  .ler-left:before {
    content: "\ecd7";
  }
  
  .ler-like-2 .path1:before {
    content: "\ecd8";
    opacity: 0.3;
  }
  
  .ler-like-2 .path2:before {
    content: "\ecd9";
    margin-left: -1em;
  }
  
  .ler-like-folder .path1:before {
    content: "\ecda";
    opacity: 0.3;
  }
  
  .ler-like-folder .path2:before {
    content: "\ecdb";
    margin-left: -1em;
  }
  
  .ler-like-shapes .path1:before {
    content: "\ecdc";
    opacity: 0.3;
  }
  
  .ler-like-shapes .path2:before {
    content: "\ecdd";
    margin-left: -1em;
  }
  
  .ler-like-tag .path1:before {
    content: "\ecde";
    opacity: 0.3;
  }
  
  .ler-like-tag .path2:before {
    content: "\ecdf";
    margin-left: -1em;
  }
  
  .ler-like .path1:before {
    content: "\ece0";
  }
  
  .ler-like .path2:before {
    content: "\ece1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-loading .path1:before {
    content: "\ece2";
  }
  
  .ler-loading .path2:before {
    content: "\ece3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-lock-2 .path1:before {
    content: "\ece4";
  }
  
  .ler-lock-2 .path2:before {
    content: "\ece5";
    margin-left: -1em;
  }
  
  .ler-lock-2 .path3:before {
    content: "\ece6";
    margin-left: -1em;
  }
  
  .ler-lock-2 .path4:before {
    content: "\ece7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-lock-2 .path5:before {
    content: "\ece8";
    margin-left: -1em;
  }
  
  .ler-lock-3 .path1:before {
    content: "\ece9";
    opacity: 0.3;
  }
  
  .ler-lock-3 .path2:before {
    content: "\ecea";
    margin-left: -1em;
  }
  
  .ler-lock-3 .path3:before {
    content: "\eceb";
    margin-left: -1em;
  }
  
  .ler-lock .path1:before {
    content: "\ecec";
    opacity: 0.3;
  }
  
  .ler-lock .path2:before {
    content: "\eced";
    margin-left: -1em;
  }
  
  .ler-lock .path3:before {
    content: "\ecee";
    margin-left: -1em;
  }
  
  .ler-logistic .path1:before {
    content: "\ecef";
    opacity: 0.3;
  }
  
  .ler-logistic .path2:before {
    content: "\ecf0";
    margin-left: -1em;
  }
  
  .ler-logistic .path3:before {
    content: "\ecf1";
    margin-left: -1em;
  }
  
  .ler-logistic .path4:before {
    content: "\ecf2";
    margin-left: -1em;
  }
  
  .ler-logistic .path5:before {
    content: "\ecf3";
    margin-left: -1em;
  }
  
  .ler-logistic .path6:before {
    content: "\ecf4";
    margin-left: -1em;
  }
  
  .ler-logistic .path7:before {
    content: "\ecf5";
    margin-left: -1em;
  }
  
  .ler-lots-shopping .path1:before {
    content: "\ecf6";
    opacity: 0.3;
  }
  
  .ler-lots-shopping .path2:before {
    content: "\ecf7";
    margin-left: -1em;
  }
  
  .ler-lots-shopping .path3:before {
    content: "\ecf8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-lots-shopping .path4:before {
    content: "\ecf9";
    margin-left: -1em;
  }
  
  .ler-lots-shopping .path5:before {
    content: "\ecfa";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-lots-shopping .path6:before {
    content: "\ecfb";
    margin-left: -1em;
  }
  
  .ler-lots-shopping .path7:before {
    content: "\ecfc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-lots-shopping .path8:before {
    content: "\ecfd";
    margin-left: -1em;
  }
  
  .ler-lovely .path1:before {
    content: "\ecfe";
    opacity: 0.3;
  }
  
  .ler-lovely .path2:before {
    content: "\ecff";
    margin-left: -1em;
  }
  
  .ler-lts .path1:before {
    content: "\ed00";
  }
  
  .ler-lts .path2:before {
    content: "\ed01";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-magnifier .path1:before {
    content: "\ed02";
    opacity: 0.3;
  }
  
  .ler-magnifier .path2:before {
    content: "\ed03";
    margin-left: -1em;
  }
  
  .ler-map .path1:before {
    content: "\ed04";
    opacity: 0.3;
  }
  
  .ler-map .path2:before {
    content: "\ed05";
    margin-left: -1em;
  }
  
  .ler-map .path3:before {
    content: "\ed06";
    margin-left: -1em;
  }
  
  .ler-mask .path1:before {
    content: "\ed07";
    opacity: 0.3;
  }
  
  .ler-mask .path2:before {
    content: "\ed08";
    margin-left: -1em;
  }
  
  .ler-mask .path3:before {
    content: "\ed09";
    margin-left: -1em;
  }
  
  .ler-maximize .path1:before {
    content: "\ed0a";
    opacity: 0.3;
  }
  
  .ler-maximize .path2:before {
    content: "\ed0b";
    margin-left: -1em;
  }
  
  .ler-maximize .path3:before {
    content: "\ed0c";
    margin-left: -1em;
  }
  
  .ler-maximize .path4:before {
    content: "\ed0d";
    margin-left: -1em;
  }
  
  .ler-maximize .path5:before {
    content: "\ed0e";
    margin-left: -1em;
  }
  
  .ler-medal-star .path1:before {
    content: "\ed0f";
  }
  
  .ler-medal-star .path2:before {
    content: "\ed10";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-medal-star .path3:before {
    content: "\ed11";
    margin-left: -1em;
  }
  
  .ler-medal-star .path4:before {
    content: "\ed12";
    margin-left: -1em;
  }
  
  .ler-menu .path1:before {
    content: "\ed13";
    opacity: 0.3;
  }
  
  .ler-menu .path2:before {
    content: "\ed14";
    margin-left: -1em;
  }
  
  .ler-menu .path3:before {
    content: "\ed15";
    margin-left: -1em;
  }
  
  .ler-menu .path4:before {
    content: "\ed16";
    margin-left: -1em;
  }
  
  .ler-message-add .path1:before {
    content: "\ed17";
    opacity: 0.3;
  }
  
  .ler-message-add .path2:before {
    content: "\ed18";
    margin-left: -1em;
  }
  
  .ler-message-add .path3:before {
    content: "\ed19";
    margin-left: -1em;
  }
  
  .ler-message-edit .path1:before {
    content: "\ed1a";
    opacity: 0.3;
  }
  
  .ler-message-edit .path2:before {
    content: "\ed1b";
    margin-left: -1em;
  }
  
  .ler-message-minus .path1:before {
    content: "\ed1c";
    opacity: 0.3;
  }
  
  .ler-message-minus .path2:before {
    content: "\ed1d";
    margin-left: -1em;
  }
  
  .ler-message-notif .path1:before {
    content: "\ed1e";
  }
  
  .ler-message-notif .path2:before {
    content: "\ed1f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-message-notif .path3:before {
    content: "\ed20";
    margin-left: -1em;
  }
  
  .ler-message-notif .path4:before {
    content: "\ed21";
    margin-left: -1em;
  }
  
  .ler-message-notif .path5:before {
    content: "\ed22";
    margin-left: -1em;
  }
  
  .ler-message-programming .path1:before {
    content: "\ed23";
    opacity: 0.3;
  }
  
  .ler-message-programming .path2:before {
    content: "\ed24";
    margin-left: -1em;
  }
  
  .ler-message-programming .path3:before {
    content: "\ed25";
    margin-left: -1em;
  }
  
  .ler-message-programming .path4:before {
    content: "\ed26";
    margin-left: -1em;
  }
  
  .ler-message-question .path1:before {
    content: "\ed27";
    opacity: 0.3;
  }
  
  .ler-message-question .path2:before {
    content: "\ed28";
    margin-left: -1em;
  }
  
  .ler-message-question .path3:before {
    content: "\ed29";
    margin-left: -1em;
  }
  
  .ler-message-text-2 .path1:before {
    content: "\ed2a";
    opacity: 0.3;
  }
  
  .ler-message-text-2 .path2:before {
    content: "\ed2b";
    margin-left: -1em;
  }
  
  .ler-message-text-2 .path3:before {
    content: "\ed2c";
    margin-left: -1em;
  }
  
  .ler-message-text .path1:before {
    content: "\ed2d";
    opacity: 0.3;
  }
  
  .ler-message-text .path2:before {
    content: "\ed2e";
    margin-left: -1em;
  }
  
  .ler-message-text .path3:before {
    content: "\ed2f";
    margin-left: -1em;
  }
  
  .ler-messages .path1:before {
    content: "\ed30";
  }
  
  .ler-messages .path2:before {
    content: "\ed31";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-messages .path3:before {
    content: "\ed32";
    margin-left: -1em;
  }
  
  .ler-messages .path4:before {
    content: "\ed33";
    margin-left: -1em;
  }
  
  .ler-messages .path5:before {
    content: "\ed34";
    margin-left: -1em;
  }
  
  .ler-microsoft .path1:before {
    content: "\ed35";
    opacity: 0.3;
  }
  
  .ler-microsoft .path2:before {
    content: "\ed36";
    margin-left: -1em;
  }
  
  .ler-microsoft .path3:before {
    content: "\ed37";
    margin-left: -1em;
  }
  
  .ler-microsoft .path4:before {
    content: "\ed38";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-milk .path1:before {
    content: "\ed39";
    opacity: 0.3;
  }
  
  .ler-milk .path2:before {
    content: "\ed3a";
    margin-left: -1em;
  }
  
  .ler-milk .path3:before {
    content: "\ed3b";
    margin-left: -1em;
  }
  
  .ler-minus-circle .path1:before {
    content: "\ed3c";
    opacity: 0.3;
  }
  
  .ler-minus-circle .path2:before {
    content: "\ed3d";
    margin-left: -1em;
  }
  
  .ler-minus-folder .path1:before {
    content: "\ed3e";
    opacity: 0.3;
  }
  
  .ler-minus-folder .path2:before {
    content: "\ed3f";
    margin-left: -1em;
  }
  
  .ler-minus-square .path1:before {
    content: "\ed40";
    opacity: 0.3;
  }
  
  .ler-minus-square .path2:before {
    content: "\ed41";
    margin-left: -1em;
  }
  
  .ler-minus:before {
    content: "\ed42";
  }
  
  .ler-monitor-mobile .path1:before {
    content: "\ed43";
    opacity: 0.3;
  }
  
  .ler-monitor-mobile .path2:before {
    content: "\ed44";
    margin-left: -1em;
  }
  
  .ler-moon .path1:before {
    content: "\ed45";
  }
  
  .ler-moon .path2:before {
    content: "\ed46";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-more-2 .path1:before {
    content: "\ed47";
    opacity: 0.3;
  }
  
  .ler-more-2 .path2:before {
    content: "\ed48";
    margin-left: -1em;
  }
  
  .ler-more-2 .path3:before {
    content: "\ed49";
    margin-left: -1em;
  }
  
  .ler-more-2 .path4:before {
    content: "\ed4a";
    margin-left: -1em;
  }
  
  .ler-mouse-circle .path1:before {
    content: "\ed4b";
  }
  
  .ler-mouse-circle .path2:before {
    content: "\ed4c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-mouse-square .path1:before {
    content: "\ed4d";
    opacity: 0.3;
  }
  
  .ler-mouse-square .path2:before {
    content: "\ed4e";
    margin-left: -1em;
  }
  
  .ler-mouse .path1:before {
    content: "\ed4f";
    opacity: 0.3;
  }
  
  .ler-mouse .path2:before {
    content: "\ed50";
    margin-left: -1em;
  }
  
  .ler-nexo .path1:before {
    content: "\ed51";
    opacity: 0.3;
  }
  
  .ler-nexo .path2:before {
    content: "\ed52";
    margin-left: -1em;
  }
  
  .ler-night-day .path1:before {
    content: "\ed53";
  }
  
  .ler-night-day .path2:before {
    content: "\ed54";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-night-day .path3:before {
    content: "\ed55";
    margin-left: -1em;
  }
  
  .ler-night-day .path4:before {
    content: "\ed56";
    margin-left: -1em;
  }
  
  .ler-night-day .path5:before {
    content: "\ed57";
    margin-left: -1em;
  }
  
  .ler-night-day .path6:before {
    content: "\ed58";
    margin-left: -1em;
  }
  
  .ler-night-day .path7:before {
    content: "\ed59";
    margin-left: -1em;
  }
  
  .ler-night-day .path8:before {
    content: "\ed5a";
    margin-left: -1em;
  }
  
  .ler-night-day .path9:before {
    content: "\ed5b";
    margin-left: -1em;
  }
  
  .ler-night-day .path10:before {
    content: "\ed5c";
    margin-left: -1em;
  }
  
  .ler-note-2 .path1:before {
    content: "\ed5d";
    opacity: 0.3;
  }
  
  .ler-note-2 .path2:before {
    content: "\ed5e";
    margin-left: -1em;
  }
  
  .ler-note-2 .path3:before {
    content: "\ed5f";
    margin-left: -1em;
  }
  
  .ler-note-2 .path4:before {
    content: "\ed60";
    margin-left: -1em;
  }
  
  .ler-note .path1:before {
    content: "\ed61";
    opacity: 0.3;
  }
  
  .ler-note .path2:before {
    content: "\ed62";
    margin-left: -1em;
  }
  
  .ler-notepad-bookmark .path1:before {
    content: "\ed63";
  }
  
  .ler-notepad-bookmark .path2:before {
    content: "\ed64";
    margin-left: -1em;
  }
  
  .ler-notepad-bookmark .path3:before {
    content: "\ed65";
    margin-left: -1em;
  }
  
  .ler-notepad-bookmark .path4:before {
    content: "\ed66";
    margin-left: -1em;
  }
  
  .ler-notepad-bookmark .path5:before {
    content: "\ed67";
    margin-left: -1em;
  }
  
  .ler-notepad-bookmark .path6:before {
    content: "\ed68";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notepad-edit .path1:before {
    content: "\ed69";
    opacity: 0.3;
  }
  
  .ler-notepad-edit .path2:before {
    content: "\ed6a";
    margin-left: -1em;
  }
  
  .ler-notepad .path1:before {
    content: "\ed6b";
  }
  
  .ler-notepad .path2:before {
    content: "\ed6c";
    margin-left: -1em;
  }
  
  .ler-notepad .path3:before {
    content: "\ed6d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notepad .path4:before {
    content: "\ed6e";
    margin-left: -1em;
  }
  
  .ler-notepad .path5:before {
    content: "\ed6f";
    margin-left: -1em;
  }
  
  .ler-notification-2 .path1:before {
    content: "\ed70";
  }
  
  .ler-notification-2 .path2:before {
    content: "\ed71";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notification-bing .path1:before {
    content: "\ed72";
  }
  
  .ler-notification-bing .path2:before {
    content: "\ed73";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notification-bing .path3:before {
    content: "\ed74";
    margin-left: -1em;
  }
  
  .ler-notification-circle .path1:before {
    content: "\ed75";
  }
  
  .ler-notification-circle .path2:before {
    content: "\ed76";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notification-favorite .path1:before {
    content: "\ed77";
  }
  
  .ler-notification-favorite .path2:before {
    content: "\ed78";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notification-favorite .path3:before {
    content: "\ed79";
    margin-left: -1em;
  }
  
  .ler-notification-on .path1:before {
    content: "\ed7a";
    opacity: 0.3;
  }
  
  .ler-notification-on .path2:before {
    content: "\ed7b";
    margin-left: -1em;
  }
  
  .ler-notification-on .path3:before {
    content: "\ed7c";
    margin-left: -1em;
  }
  
  .ler-notification-on .path4:before {
    content: "\ed7d";
    margin-left: -1em;
  }
  
  .ler-notification-on .path5:before {
    content: "\ed7e";
    margin-left: -1em;
  }
  
  .ler-notification-status .path1:before {
    content: "\ed7f";
  }
  
  .ler-notification-status .path2:before {
    content: "\ed80";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-notification-status .path3:before {
    content: "\ed81";
    margin-left: -1em;
  }
  
  .ler-notification-status .path4:before {
    content: "\ed82";
    margin-left: -1em;
  }
  
  .ler-notification .path1:before {
    content: "\ed83";
    opacity: 0.3;
  }
  
  .ler-notification .path2:before {
    content: "\ed84";
    margin-left: -1em;
  }
  
  .ler-notification .path3:before {
    content: "\ed85";
    margin-left: -1em;
  }
  
  .ler-ocean .path1:before {
    content: "\ed86";
  }
  
  .ler-ocean .path2:before {
    content: "\ed87";
    margin-left: -1em;
  }
  
  .ler-ocean .path3:before {
    content: "\ed88";
    margin-left: -1em;
  }
  
  .ler-ocean .path4:before {
    content: "\ed89";
    margin-left: -1em;
  }
  
  .ler-ocean .path5:before {
    content: "\ed8a";
    margin-left: -1em;
  }
  
  .ler-ocean .path6:before {
    content: "\ed8b";
    margin-left: -1em;
  }
  
  .ler-ocean .path7:before {
    content: "\ed8c";
    margin-left: -1em;
  }
  
  .ler-ocean .path8:before {
    content: "\ed8d";
    margin-left: -1em;
  }
  
  .ler-ocean .path9:before {
    content: "\ed8e";
    margin-left: -1em;
  }
  
  .ler-ocean .path10:before {
    content: "\ed8f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path11:before {
    content: "\ed90";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path12:before {
    content: "\ed91";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path13:before {
    content: "\ed92";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path14:before {
    content: "\ed93";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path15:before {
    content: "\ed94";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path16:before {
    content: "\ed95";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path17:before {
    content: "\ed96";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path18:before {
    content: "\ed97";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ocean .path19:before {
    content: "\ed98";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-office-bag .path1:before {
    content: "\ed99";
    opacity: 0.3;
  }
  
  .ler-office-bag .path2:before {
    content: "\ed9a";
    margin-left: -1em;
  }
  
  .ler-office-bag .path3:before {
    content: "\ed9b";
    margin-left: -1em;
  }
  
  .ler-office-bag .path4:before {
    content: "\ed9c";
    margin-left: -1em;
  }
  
  .ler-package .path1:before {
    content: "\ed9d";
    opacity: 0.3;
  }
  
  .ler-package .path2:before {
    content: "\ed9e";
    margin-left: -1em;
  }
  
  .ler-package .path3:before {
    content: "\ed9f";
    margin-left: -1em;
  }
  
  .ler-pails .path1:before {
    content: "\eda0";
    opacity: 0.3;
  }
  
  .ler-pails .path2:before {
    content: "\eda1";
    margin-left: -1em;
  }
  
  .ler-pails .path3:before {
    content: "\eda2";
    margin-left: -1em;
  }
  
  .ler-pails .path4:before {
    content: "\eda3";
    margin-left: -1em;
  }
  
  .ler-pails .path5:before {
    content: "\eda4";
    margin-left: -1em;
  }
  
  .ler-pails .path6:before {
    content: "\eda5";
    margin-left: -1em;
  }
  
  .ler-pails .path7:before {
    content: "\eda6";
    margin-left: -1em;
  }
  
  .ler-pails .path8:before {
    content: "\eda7";
    margin-left: -1em;
  }
  
  .ler-pails .path9:before {
    content: "\eda8";
    margin-left: -1em;
  }
  
  .ler-paintbucket .path1:before {
    content: "\eda9";
    opacity: 0.3;
  }
  
  .ler-paintbucket .path2:before {
    content: "\edaa";
    margin-left: -1em;
  }
  
  .ler-paintbucket .path3:before {
    content: "\edab";
    margin-left: -1em;
  }
  
  .ler-paper-clip:before {
    content: "\edac";
  }
  
  .ler-parcel-tracking .path1:before {
    content: "\edad";
  }
  
  .ler-parcel-tracking .path2:before {
    content: "\edae";
    margin-left: -1em;
  }
  
  .ler-parcel-tracking .path3:before {
    content: "\edaf";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-parcel .path1:before {
    content: "\edb0";
    opacity: 0.3;
  }
  
  .ler-parcel .path2:before {
    content: "\edb1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-parcel .path3:before {
    content: "\edb2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-parcel .path4:before {
    content: "\edb3";
    margin-left: -1em;
  }
  
  .ler-parcel .path5:before {
    content: "\edb4";
    margin-left: -1em;
  }
  
  .ler-password-check .path1:before {
    content: "\edb5";
  }
  
  .ler-password-check .path2:before {
    content: "\edb6";
    margin-left: -1em;
  }
  
  .ler-password-check .path3:before {
    content: "\edb7";
    margin-left: -1em;
  }
  
  .ler-password-check .path4:before {
    content: "\edb8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-password-check .path5:before {
    content: "\edb9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-paypal .path1:before {
    content: "\edba";
  }
  
  .ler-paypal .path2:before {
    content: "\edbb";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-pencil .path1:before {
    content: "\edbc";
    opacity: 0.3;
  }
  
  .ler-pencil .path2:before {
    content: "\edbd";
    margin-left: -1em;
  }
  
  .ler-people .path1:before {
    content: "\edbe";
  }
  
  .ler-people .path2:before {
    content: "\edbf";
    margin-left: -1em;
  }
  
  .ler-people .path3:before {
    content: "\edc0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-people .path4:before {
    content: "\edc1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-people .path5:before {
    content: "\edc2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-percentage .path1:before {
    content: "\edc3";
  }
  
  .ler-percentage .path2:before {
    content: "\edc4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-phone .path1:before {
    content: "\edc5";
    opacity: 0.3;
  }
  
  .ler-phone .path2:before {
    content: "\edc6";
    margin-left: -1em;
  }
  
  .ler-photoshop .path1:before {
    content: "\edc7";
    opacity: 0.3;
  }
  
  .ler-photoshop .path2:before {
    content: "\edc8";
    margin-left: -1em;
  }
  
  .ler-picture .path1:before {
    content: "\edc9";
    opacity: 0.3;
  }
  
  .ler-picture .path2:before {
    content: "\edca";
    margin-left: -1em;
  }
  
  .ler-pill:before {
    content: "\edcb";
  }
  
  .ler-pin .path1:before {
    content: "\edcc";
    opacity: 0.3;
  }
  
  .ler-pin .path2:before {
    content: "\edcd";
    margin-left: -1em;
  }
  
  .ler-plus-circle .path1:before {
    content: "\edce";
    opacity: 0.3;
  }
  
  .ler-plus-circle .path2:before {
    content: "\edcf";
    margin-left: -1em;
  }
  
  .ler-plus-square .path1:before {
    content: "\edd0";
    opacity: 0.3;
  }
  
  .ler-plus-square .path2:before {
    content: "\edd1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-plus-square .path3:before {
    content: "\edd2";
    margin-left: -1em;
  }
  
  .ler-plus:before {
    content: "\edd3";
  }
  
  .ler-pointers .path1:before {
    content: "\edd4";
    opacity: 0.3;
  }
  
  .ler-pointers .path2:before {
    content: "\edd5";
    margin-left: -1em;
  }
  
  .ler-pointers .path3:before {
    content: "\edd6";
    margin-left: -1em;
  }
  
  .ler-price-tag .path1:before {
    content: "\edd7";
    opacity: 0.3;
  }
  
  .ler-price-tag .path2:before {
    content: "\edd8";
    margin-left: -1em;
  }
  
  .ler-price-tag .path3:before {
    content: "\edd9";
    margin-left: -1em;
  }
  
  .ler-printer .path1:before {
    content: "\edda";
    opacity: 0.3;
  }
  
  .ler-printer .path2:before {
    content: "\eddb";
    margin-left: -1em;
  }
  
  .ler-printer .path3:before {
    content: "\eddc";
    margin-left: -1em;
  }
  
  .ler-printer .path4:before {
    content: "\eddd";
    margin-left: -1em;
  }
  
  .ler-printer .path5:before {
    content: "\edde";
    margin-left: -1em;
  }
  
  .ler-profile-circle .path1:before {
    content: "\eddf";
    opacity: 0.3;
  }
  
  .ler-profile-circle .path2:before {
    content: "\ede0";
    margin-left: -1em;
  }
  
  .ler-profile-circle .path3:before {
    content: "\ede1";
    margin-left: -1em;
  }
  
  .ler-profile-user .path1:before {
    content: "\ede2";
    opacity: 0.3;
  }
  
  .ler-profile-user .path2:before {
    content: "\ede3";
    margin-left: -1em;
  }
  
  .ler-profile-user .path3:before {
    content: "\ede4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-profile-user .path4:before {
    content: "\ede5";
    margin-left: -1em;
  }
  
  .ler-pulse .path1:before {
    content: "\ede6";
    opacity: 0.3;
  }
  
  .ler-pulse .path2:before {
    content: "\ede7";
    margin-left: -1em;
  }
  
  .ler-purchase .path1:before {
    content: "\ede8";
    opacity: 0.3;
  }
  
  .ler-purchase .path2:before {
    content: "\ede9";
    margin-left: -1em;
  }
  
  .ler-python .path1:before {
    content: "\edea";
    opacity: 0.3;
  }
  
  .ler-python .path2:before {
    content: "\edeb";
    margin-left: -1em;
  }
  
  .ler-question-2 .path1:before {
    content: "\edec";
    opacity: 0.3;
  }
  
  .ler-question-2 .path2:before {
    content: "\eded";
    margin-left: -1em;
  }
  
  .ler-question-2 .path3:before {
    content: "\edee";
    margin-left: -1em;
  }
  
  .ler-question .path1:before {
    content: "\edef";
    opacity: 0.3;
  }
  
  .ler-question .path2:before {
    content: "\edf0";
    margin-left: -1em;
  }
  
  .ler-question .path3:before {
    content: "\edf1";
    margin-left: -1em;
  }
  
  .ler-questionnaire-tablet .path1:before {
    content: "\edf2";
    opacity: 0.3;
  }
  
  .ler-questionnaire-tablet .path2:before {
    content: "\edf3";
    margin-left: -1em;
  }
  
  .ler-ranking .path1:before {
    content: "\edf4";
    opacity: 0.3;
  }
  
  .ler-ranking .path2:before {
    content: "\edf5";
    margin-left: -1em;
  }
  
  .ler-ranking .path3:before {
    content: "\edf6";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ranking .path4:before {
    content: "\edf7";
    margin-left: -1em;
  }
  
  .ler-react .path1:before {
    content: "\edf8";
    opacity: 0.3;
  }
  
  .ler-react .path2:before {
    content: "\edf9";
    margin-left: -1em;
  }
  
  .ler-receipt-square .path1:before {
    content: "\edfa";
    opacity: 0.3;
  }
  
  .ler-receipt-square .path2:before {
    content: "\edfb";
    margin-left: -1em;
  }
  
  .ler-rescue .path1:before {
    content: "\edfc";
    opacity: 0.3;
  }
  
  .ler-rescue .path2:before {
    content: "\edfd";
    margin-left: -1em;
  }
  
  .ler-right-left .path1:before {
    content: "\edfe";
  }
  
  .ler-right-left .path2:before {
    content: "\edff";
    margin-left: -1em;
  }
  
  .ler-right-left .path3:before {
    content: "\ee00";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-right-square .path1:before {
    content: "\ee01";
    opacity: 0.3;
  }
  
  .ler-right-square .path2:before {
    content: "\ee02";
    margin-left: -1em;
  }
  
  .ler-right:before {
    content: "\ee03";
  }
  
  .ler-rocket .path1:before {
    content: "\ee04";
    opacity: 0.3;
  }
  
  .ler-rocket .path2:before {
    content: "\ee05";
    margin-left: -1em;
  }
  
  .ler-route .path1:before {
    content: "\ee06";
  }
  
  .ler-route .path2:before {
    content: "\ee07";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-route .path3:before {
    content: "\ee08";
    margin-left: -1em;
  }
  
  .ler-route .path4:before {
    content: "\ee09";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-router .path1:before {
    content: "\ee0a";
    opacity: 0.3;
  }
  
  .ler-router .path2:before {
    content: "\ee0b";
    margin-left: -1em;
  }
  
  .ler-row-horizontal .path1:before {
    content: "\ee0c";
  }
  
  .ler-row-horizontal .path2:before {
    content: "\ee0d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-row-vertical .path1:before {
    content: "\ee0e";
  }
  
  .ler-row-vertical .path2:before {
    content: "\ee0f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-safe-home .path1:before {
    content: "\ee10";
    opacity: 0.3;
  }
  
  .ler-safe-home .path2:before {
    content: "\ee11";
    margin-left: -1em;
  }
  
  .ler-satellite .path1:before {
    content: "\ee12";
    opacity: 0.3;
  }
  
  .ler-satellite .path2:before {
    content: "\ee13";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-satellite .path3:before {
    content: "\ee14";
    margin-left: -1em;
  }
  
  .ler-satellite .path4:before {
    content: "\ee15";
    margin-left: -1em;
  }
  
  .ler-satellite .path5:before {
    content: "\ee16";
    margin-left: -1em;
  }
  
  .ler-satellite .path6:before {
    content: "\ee17";
    margin-left: -1em;
  }
  
  .ler-save-2 .path1:before {
    content: "\ee18";
    opacity: 0.3;
  }
  
  .ler-save-2 .path2:before {
    content: "\ee19";
    margin-left: -1em;
  }
  
  .ler-save-deposit .path1:before {
    content: "\ee1a";
  }
  
  .ler-save-deposit .path2:before {
    content: "\ee1b";
    margin-left: -1em;
  }
  
  .ler-save-deposit .path3:before {
    content: "\ee1c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-save-deposit .path4:before {
    content: "\ee1d";
    margin-left: -1em;
  }
  
  .ler-scan-barcode .path1:before {
    content: "\ee1e";
  }
  
  .ler-scan-barcode .path2:before {
    content: "\ee1f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-scan-barcode .path3:before {
    content: "\ee20";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-scan-barcode .path4:before {
    content: "\ee21";
    margin-left: -1em;
  }
  
  .ler-scan-barcode .path5:before {
    content: "\ee22";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-scan-barcode .path6:before {
    content: "\ee23";
    margin-left: -1em;
  }
  
  .ler-scan-barcode .path7:before {
    content: "\ee24";
    margin-left: -1em;
  }
  
  .ler-scan-barcode .path8:before {
    content: "\ee25";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-scooter-2:before {
    content: "\ee26";
  }
  
  .ler-scooter .path1:before {
    content: "\ee27";
  }
  
  .ler-scooter .path2:before {
    content: "\ee28";
    margin-left: -1em;
  }
  
  .ler-scooter .path3:before {
    content: "\ee29";
    margin-left: -1em;
  }
  
  .ler-scooter .path4:before {
    content: "\ee2a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-scooter .path5:before {
    content: "\ee2b";
    margin-left: -1em;
  }
  
  .ler-scooter .path6:before {
    content: "\ee2c";
    margin-left: -1em;
  }
  
  .ler-scooter .path7:before {
    content: "\ee2d";
    margin-left: -1em;
  }
  
  .ler-screen .path1:before {
    content: "\ee2e";
    opacity: 0.3;
  }
  
  .ler-screen .path2:before {
    content: "\ee2f";
    margin-left: -1em;
  }
  
  .ler-screen .path3:before {
    content: "\ee30";
    margin-left: -1em;
  }
  
  .ler-screen .path4:before {
    content: "\ee31";
    margin-left: -1em;
  }
  
  .ler-scroll .path1:before {
    content: "\ee32";
    opacity: 0.3;
  }
  
  .ler-scroll .path2:before {
    content: "\ee33";
    margin-left: -1em;
  }
  
  .ler-scroll .path3:before {
    content: "\ee34";
    margin-left: -1em;
  }
  
  .ler-search-list .path1:before {
    content: "\ee35";
    opacity: 0.3;
  }
  
  .ler-search-list .path2:before {
    content: "\ee36";
    margin-left: -1em;
  }
  
  .ler-search-list .path3:before {
    content: "\ee37";
    margin-left: -1em;
  }
  
  .ler-security-check .path1:before {
    content: "\ee38";
  }
  
  .ler-security-check .path2:before {
    content: "\ee39";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-security-check .path3:before {
    content: "\ee3a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-security-check .path4:before {
    content: "\ee3b";
    margin-left: -1em;
  }
  
  .ler-security-user .path1:before {
    content: "\ee3c";
    opacity: 0.3;
  }
  
  .ler-security-user .path2:before {
    content: "\ee3d";
    margin-left: -1em;
  }
  
  .ler-send .path1:before {
    content: "\ee3e";
  }
  
  .ler-send .path2:before {
    content: "\ee3f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-setting-2 .path1:before {
    content: "\ee40";
    opacity: 0.3;
  }
  
  .ler-setting-2 .path2:before {
    content: "\ee41";
    margin-left: -1em;
  }
  
  .ler-setting-3 .path1:before {
    content: "\ee42";
    opacity: 0.3;
  }
  
  .ler-setting-3 .path2:before {
    content: "\ee43";
    margin-left: -1em;
  }
  
  .ler-setting-3 .path3:before {
    content: "\ee44";
    margin-left: -1em;
  }
  
  .ler-setting-3 .path4:before {
    content: "\ee45";
    margin-left: -1em;
  }
  
  .ler-setting-3 .path5:before {
    content: "\ee46";
    margin-left: -1em;
  }
  
  .ler-setting-4:before {
    content: "\ee47";
  }
  
  .ler-setting .path1:before {
    content: "\ee48";
    opacity: 0.3;
  }
  
  .ler-setting .path2:before {
    content: "\ee49";
    margin-left: -1em;
  }
  
  .ler-share .path1:before {
    content: "\ee4a";
    opacity: 0.3;
  }
  
  .ler-share .path2:before {
    content: "\ee4b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-share .path3:before {
    content: "\ee4c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-share .path4:before {
    content: "\ee4d";
    margin-left: -1em;
  }
  
  .ler-share .path5:before {
    content: "\ee4e";
    margin-left: -1em;
  }
  
  .ler-share .path6:before {
    content: "\ee4f";
    margin-left: -1em;
  }
  
  .ler-shield-cross .path1:before {
    content: "\ee50";
    opacity: 0.3;
  }
  
  .ler-shield-cross .path2:before {
    content: "\ee51";
    margin-left: -1em;
  }
  
  .ler-shield-cross .path3:before {
    content: "\ee52";
    margin-left: -1em;
  }
  
  .ler-shield-search .path1:before {
    content: "\ee53";
    opacity: 0.3;
  }
  
  .ler-shield-search .path2:before {
    content: "\ee54";
    margin-left: -1em;
  }
  
  .ler-shield-search .path3:before {
    content: "\ee55";
    margin-left: -1em;
  }
  
  .ler-shield-slash .path1:before {
    content: "\ee56";
  }
  
  .ler-shield-slash .path2:before {
    content: "\ee57";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-shield-slash .path3:before {
    content: "\ee58";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-shield-tick .path1:before {
    content: "\ee59";
    opacity: 0.3;
  }
  
  .ler-shield-tick .path2:before {
    content: "\ee5a";
    margin-left: -1em;
  }
  
  .ler-shield .path1:before {
    content: "\ee5b";
    opacity: 0.3;
  }
  
  .ler-shield .path2:before {
    content: "\ee5c";
    margin-left: -1em;
  }
  
  .ler-ship .path1:before {
    content: "\ee5d";
    opacity: 0.3;
  }
  
  .ler-ship .path2:before {
    content: "\ee5e";
    margin-left: -1em;
  }
  
  .ler-ship .path3:before {
    content: "\ee5f";
    margin-left: -1em;
  }
  
  .ler-shop .path1:before {
    content: "\ee60";
  }
  
  .ler-shop .path2:before {
    content: "\ee61";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-shop .path3:before {
    content: "\ee62";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-shop .path4:before {
    content: "\ee63";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-shop .path5:before {
    content: "\ee64";
    margin-left: -1em;
  }
  
  .ler-simcard-2 .path1:before {
    content: "\ee65";
    opacity: 0.3;
  }
  
  .ler-simcard-2 .path2:before {
    content: "\ee66";
    margin-left: -1em;
  }
  
  .ler-simcard .path1:before {
    content: "\ee67";
    opacity: 0.3;
  }
  
  .ler-simcard .path2:before {
    content: "\ee68";
    margin-left: -1em;
  }
  
  .ler-simcard .path3:before {
    content: "\ee69";
    margin-left: -1em;
  }
  
  .ler-simcard .path4:before {
    content: "\ee6a";
    margin-left: -1em;
  }
  
  .ler-simcard .path5:before {
    content: "\ee6b";
    margin-left: -1em;
  }
  
  .ler-size .path1:before {
    content: "\ee6c";
    opacity: 0.3;
  }
  
  .ler-size .path2:before {
    content: "\ee6d";
    margin-left: -1em;
  }
  
  .ler-slack .path1:before {
    content: "\ee6e";
  }
  
  .ler-slack .path2:before {
    content: "\ee6f";
    margin-left: -1em;
  }
  
  .ler-slack .path3:before {
    content: "\ee70";
    margin-left: -1em;
  }
  
  .ler-slack .path4:before {
    content: "\ee71";
    margin-left: -1em;
  }
  
  .ler-slack .path5:before {
    content: "\ee72";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slack .path6:before {
    content: "\ee73";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slack .path7:before {
    content: "\ee74";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slack .path8:before {
    content: "\ee75";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slider-horizontal-2 .path1:before {
    content: "\ee76";
  }
  
  .ler-slider-horizontal-2 .path2:before {
    content: "\ee77";
    margin-left: -1em;
  }
  
  .ler-slider-horizontal-2 .path3:before {
    content: "\ee78";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slider-horizontal .path1:before {
    content: "\ee79";
    opacity: 0.3;
  }
  
  .ler-slider-horizontal .path2:before {
    content: "\ee7a";
    margin-left: -1em;
  }
  
  .ler-slider-horizontal .path3:before {
    content: "\ee7b";
    margin-left: -1em;
  }
  
  .ler-slider-vertical-2 .path1:before {
    content: "\ee7c";
    opacity: 0.3;
  }
  
  .ler-slider-vertical-2 .path2:before {
    content: "\ee7d";
    margin-left: -1em;
  }
  
  .ler-slider-vertical-2 .path3:before {
    content: "\ee7e";
    margin-left: -1em;
  }
  
  .ler-slider-vertical .path1:before {
    content: "\ee7f";
  }
  
  .ler-slider-vertical .path2:before {
    content: "\ee80";
    margin-left: -1em;
  }
  
  .ler-slider-vertical .path3:before {
    content: "\ee81";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-slider .path1:before {
    content: "\ee82";
    opacity: 0.3;
  }
  
  .ler-slider .path2:before {
    content: "\ee83";
    margin-left: -1em;
  }
  
  .ler-slider .path3:before {
    content: "\ee84";
    margin-left: -1em;
  }
  
  .ler-slider .path4:before {
    content: "\ee85";
    margin-left: -1em;
  }
  
  .ler-sms .path1:before {
    content: "\ee86";
    opacity: 0.3;
  }
  
  .ler-sms .path2:before {
    content: "\ee87";
    margin-left: -1em;
  }
  
  .ler-snapchat .path1:before {
    content: "\ee88";
  }
  
  .ler-snapchat .path2:before {
    content: "\ee89";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-social-media .path1:before {
    content: "\ee8a";
  }
  
  .ler-social-media .path2:before {
    content: "\ee8b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-soft-2 .path1:before {
    content: "\ee8c";
    opacity: 0.3;
  }
  
  .ler-soft-2 .path2:before {
    content: "\ee8d";
    margin-left: -1em;
  }
  
  .ler-soft-3 .path1:before {
    content: "\ee8e";
  }
  
  .ler-soft-3 .path2:before {
    content: "\ee8f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-soft .path1:before {
    content: "\ee90";
    opacity: 0.3;
  }
  
  .ler-soft .path2:before {
    content: "\ee91";
    margin-left: -1em;
  }
  
  .ler-soft .path3:before {
    content: "\ee92";
    margin-left: -1em;
  }
  
  .ler-soft .path4:before {
    content: "\ee93";
    margin-left: -1em;
  }
  
  .ler-soft .path5:before {
    content: "\ee94";
    margin-left: -1em;
  }
  
  .ler-soft .path6:before {
    content: "\ee95";
    margin-left: -1em;
  }
  
  .ler-some-files .path1:before {
    content: "\ee96";
    opacity: 0.3;
  }
  
  .ler-some-files .path2:before {
    content: "\ee97";
    margin-left: -1em;
  }
  
  .ler-sort .path1:before {
    content: "\ee98";
    opacity: 0.3;
  }
  
  .ler-sort .path2:before {
    content: "\ee99";
    margin-left: -1em;
  }
  
  .ler-sort .path3:before {
    content: "\ee9a";
    margin-left: -1em;
  }
  
  .ler-sort .path4:before {
    content: "\ee9b";
    margin-left: -1em;
  }
  
  .ler-speaker .path1:before {
    content: "\ee9c";
    opacity: 0.3;
  }
  
  .ler-speaker .path2:before {
    content: "\ee9d";
    margin-left: -1em;
  }
  
  .ler-speaker .path3:before {
    content: "\ee9e";
    margin-left: -1em;
  }
  
  .ler-spotify .path1:before {
    content: "\ee9f";
  }
  
  .ler-spotify .path2:before {
    content: "\eea0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-spring-framework:before {
    content: "\eea1";
  }
  
  .ler-square-brackets .path1:before {
    content: "\eea2";
    opacity: 0.3;
  }
  
  .ler-square-brackets .path2:before {
    content: "\eea3";
    margin-left: -1em;
  }
  
  .ler-square-brackets .path3:before {
    content: "\eea4";
    margin-left: -1em;
  }
  
  .ler-square-brackets .path4:before {
    content: "\eea5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-star:before {
    content: "\eea6";
  }
  
  .ler-status .path1:before {
    content: "\eea7";
    opacity: 0.3;
  }
  
  .ler-status .path2:before {
    content: "\eea8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-status .path3:before {
    content: "\eea9";
    margin-left: -1em;
  }
  
  .ler-subtitle .path1:before {
    content: "\eeaa";
    opacity: 0.3;
  }
  
  .ler-subtitle .path2:before {
    content: "\eeab";
    margin-left: -1em;
  }
  
  .ler-subtitle .path3:before {
    content: "\eeac";
    margin-left: -1em;
  }
  
  .ler-subtitle .path4:before {
    content: "\eead";
    margin-left: -1em;
  }
  
  .ler-subtitle .path5:before {
    content: "\eeae";
    margin-left: -1em;
  }
  
  .ler-sun .path1:before {
    content: "\eeaf";
  }
  
  .ler-sun .path2:before {
    content: "\eeb0";
    margin-left: -1em;
  }
  
  .ler-sun .path3:before {
    content: "\eeb1";
    margin-left: -1em;
  }
  
  .ler-sun .path4:before {
    content: "\eeb2";
    margin-left: -1em;
  }
  
  .ler-sun .path5:before {
    content: "\eeb3";
    margin-left: -1em;
  }
  
  .ler-sun .path6:before {
    content: "\eeb4";
    margin-left: -1em;
  }
  
  .ler-sun .path7:before {
    content: "\eeb5";
    margin-left: -1em;
  }
  
  .ler-sun .path8:before {
    content: "\eeb6";
    margin-left: -1em;
  }
  
  .ler-sun .path9:before {
    content: "\eeb7";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-support-24 .path1:before {
    content: "\eeb8";
    opacity: 0.3;
  }
  
  .ler-support-24 .path2:before {
    content: "\eeb9";
    margin-left: -1em;
  }
  
  .ler-support-24 .path3:before {
    content: "\eeba";
    margin-left: -1em;
  }
  
  .ler-switch .path1:before {
    content: "\eebb";
  }
  
  .ler-switch .path2:before {
    content: "\eebc";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-syringe .path1:before {
    content: "\eebd";
    opacity: 0.3;
  }
  
  .ler-syringe .path2:before {
    content: "\eebe";
    margin-left: -1em;
  }
  
  .ler-syringe .path3:before {
    content: "\eebf";
    margin-left: -1em;
  }
  
  .ler-tablet-book .path1:before {
    content: "\eec0";
    opacity: 0.3;
  }
  
  .ler-tablet-book .path2:before {
    content: "\eec1";
    margin-left: -1em;
  }
  
  .ler-tablet-delete .path1:before {
    content: "\eec2";
  }
  
  .ler-tablet-delete .path2:before {
    content: "\eec3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-tablet-delete .path3:before {
    content: "\eec4";
    margin-left: -1em;
  }
  
  .ler-tablet-down .path1:before {
    content: "\eec5";
    opacity: 0.3;
  }
  
  .ler-tablet-down .path2:before {
    content: "\eec6";
    margin-left: -1em;
  }
  
  .ler-tablet-down .path3:before {
    content: "\eec7";
    margin-left: -1em;
  }
  
  .ler-tablet-ok .path1:before {
    content: "\eec8";
  }
  
  .ler-tablet-ok .path2:before {
    content: "\eec9";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-tablet-ok .path3:before {
    content: "\eeca";
    margin-left: -1em;
  }
  
  .ler-tablet-text-down .path1:before {
    content: "\eecb";
  }
  
  .ler-tablet-text-down .path2:before {
    content: "\eecc";
    margin-left: -1em;
  }
  
  .ler-tablet-text-down .path3:before {
    content: "\eecd";
    margin-left: -1em;
  }
  
  .ler-tablet-text-down .path4:before {
    content: "\eece";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-tablet-text-up .path1:before {
    content: "\eecf";
  }
  
  .ler-tablet-text-up .path2:before {
    content: "\eed0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-tablet-up .path1:before {
    content: "\eed1";
    opacity: 0.3;
  }
  
  .ler-tablet-up .path2:before {
    content: "\eed2";
    margin-left: -1em;
  }
  
  .ler-tablet-up .path3:before {
    content: "\eed3";
    margin-left: -1em;
  }
  
  .ler-tablet .path1:before {
    content: "\eed4";
    opacity: 0.3;
  }
  
  .ler-tablet .path2:before {
    content: "\eed5";
    margin-left: -1em;
  }
  
  .ler-tablet .path3:before {
    content: "\eed6";
    margin-left: -1em;
  }
  
  .ler-tag-cross .path1:before {
    content: "\eed7";
    opacity: 0.3;
  }
  
  .ler-tag-cross .path2:before {
    content: "\eed8";
    margin-left: -1em;
  }
  
  .ler-tag-cross .path3:before {
    content: "\eed9";
    margin-left: -1em;
  }
  
  .ler-tag .path1:before {
    content: "\eeda";
    opacity: 0.3;
  }
  
  .ler-tag .path2:before {
    content: "\eedb";
    margin-left: -1em;
  }
  
  .ler-tag .path3:before {
    content: "\eedc";
    margin-left: -1em;
  }
  
  .ler-teacher .path1:before {
    content: "\eedd";
    opacity: 0.3;
  }
  
  .ler-teacher .path2:before {
    content: "\eede";
    margin-left: -1em;
  }
  
  .ler-tech-wifi .path1:before {
    content: "\eedf";
    opacity: 0.3;
  }
  
  .ler-tech-wifi .path2:before {
    content: "\eee0";
    margin-left: -1em;
  }
  
  .ler-technology-2 .path1:before {
    content: "\eee1";
    opacity: 0.3;
  }
  
  .ler-technology-2 .path2:before {
    content: "\eee2";
    margin-left: -1em;
  }
  
  .ler-technology-3 .path1:before {
    content: "\eee3";
  }
  
  .ler-technology-3 .path2:before {
    content: "\eee4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology-3 .path3:before {
    content: "\eee5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology-3 .path4:before {
    content: "\eee6";
    margin-left: -1em;
  }
  
  .ler-technology-4 .path1:before {
    content: "\eee7";
  }
  
  .ler-technology-4 .path2:before {
    content: "\eee8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology-4 .path3:before {
    content: "\eee9";
    margin-left: -1em;
  }
  
  .ler-technology-4 .path4:before {
    content: "\eeea";
    margin-left: -1em;
  }
  
  .ler-technology-4 .path5:before {
    content: "\eeeb";
    margin-left: -1em;
  }
  
  .ler-technology-4 .path6:before {
    content: "\eeec";
    margin-left: -1em;
  }
  
  .ler-technology-4 .path7:before {
    content: "\eeed";
    margin-left: -1em;
  }
  
  .ler-technology .path1:before {
    content: "\eeee";
  }
  
  .ler-technology .path2:before {
    content: "\eeef";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path3:before {
    content: "\eef0";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path4:before {
    content: "\eef1";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path5:before {
    content: "\eef2";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path6:before {
    content: "\eef3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path7:before {
    content: "\eef4";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path8:before {
    content: "\eef5";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-technology .path9:before {
    content: "\eef6";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-telephone-geolocation .path1:before {
    content: "\eef7";
  }
  
  .ler-telephone-geolocation .path2:before {
    content: "\eef8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-telephone-geolocation .path3:before {
    content: "\eef9";
    margin-left: -1em;
  }
  
  .ler-test-tubes .path1:before {
    content: "\eefa";
    opacity: 0.3;
  }
  
  .ler-test-tubes .path2:before {
    content: "\eefb";
    margin-left: -1em;
  }
  
  .ler-text-align-center .path1:before {
    content: "\eefc";
  }
  
  .ler-text-align-center .path2:before {
    content: "\eefd";
    margin-left: -1em;
  }
  
  .ler-text-align-center .path3:before {
    content: "\eefe";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-center .path4:before {
    content: "\eeff";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-justify-center .path1:before {
    content: "\ef00";
  }
  
  .ler-text-align-justify-center .path2:before {
    content: "\ef01";
    margin-left: -1em;
  }
  
  .ler-text-align-justify-center .path3:before {
    content: "\ef02";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-justify-center .path4:before {
    content: "\ef03";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-left .path1:before {
    content: "\ef04";
  }
  
  .ler-text-align-left .path2:before {
    content: "\ef05";
    margin-left: -1em;
  }
  
  .ler-text-align-left .path3:before {
    content: "\ef06";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-left .path4:before {
    content: "\ef07";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-right .path1:before {
    content: "\ef08";
  }
  
  .ler-text-align-right .path2:before {
    content: "\ef09";
    margin-left: -1em;
  }
  
  .ler-text-align-right .path3:before {
    content: "\ef0a";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-align-right .path4:before {
    content: "\ef0b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-bold .path1:before {
    content: "\ef0c";
    opacity: 0.3;
  }
  
  .ler-text-bold .path2:before {
    content: "\ef0d";
    margin-left: -1em;
  }
  
  .ler-text-bold .path3:before {
    content: "\ef0e";
    margin-left: -1em;
  }
  
  .ler-text-circle .path1:before {
    content: "\ef0f";
  }
  
  .ler-text-circle .path2:before {
    content: "\ef10";
    margin-left: -1em;
  }
  
  .ler-text-circle .path3:before {
    content: "\ef11";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-circle .path4:before {
    content: "\ef12";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-circle .path5:before {
    content: "\ef13";
    margin-left: -1em;
  }
  
  .ler-text-circle .path6:before {
    content: "\ef14";
    margin-left: -1em;
  }
  
  .ler-text-italic .path1:before {
    content: "\ef15";
    opacity: 0.3;
  }
  
  .ler-text-italic .path2:before {
    content: "\ef16";
    margin-left: -1em;
  }
  
  .ler-text-italic .path3:before {
    content: "\ef17";
    margin-left: -1em;
  }
  
  .ler-text-italic .path4:before {
    content: "\ef18";
    margin-left: -1em;
  }
  
  .ler-text-number .path1:before {
    content: "\ef19";
  }
  
  .ler-text-number .path2:before {
    content: "\ef1a";
    margin-left: -1em;
  }
  
  .ler-text-number .path3:before {
    content: "\ef1b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-number .path4:before {
    content: "\ef1c";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-number .path5:before {
    content: "\ef1d";
    margin-left: -1em;
  }
  
  .ler-text-number .path6:before {
    content: "\ef1e";
    margin-left: -1em;
  }
  
  .ler-text-strikethrough .path1:before {
    content: "\ef1f";
    opacity: 0.3;
  }
  
  .ler-text-strikethrough .path2:before {
    content: "\ef20";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-text-strikethrough .path3:before {
    content: "\ef21";
    margin-left: -1em;
  }
  
  .ler-text-underline .path1:before {
    content: "\ef22";
    opacity: 0.3;
  }
  
  .ler-text-underline .path2:before {
    content: "\ef23";
    margin-left: -1em;
  }
  
  .ler-text-underline .path3:before {
    content: "\ef24";
    margin-left: -1em;
  }
  
  .ler-text:before {
    content: "\ef25";
  }
  
  .ler-thermometer .path1:before {
    content: "\ef26";
    opacity: 0.3;
  }
  
  .ler-thermometer .path2:before {
    content: "\ef27";
    margin-left: -1em;
  }
  
  .ler-theta .path1:before {
    content: "\ef28";
  }
  
  .ler-theta .path2:before {
    content: "\ef29";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-tiktok .path1:before {
    content: "\ef2a";
    opacity: 0.3;
  }
  
  .ler-tiktok .path2:before {
    content: "\ef2b";
    margin-left: -1em;
  }
  
  .ler-time .path1:before {
    content: "\ef2c";
    opacity: 0.3;
  }
  
  .ler-time .path2:before {
    content: "\ef2d";
    margin-left: -1em;
  }
  
  .ler-timer .path1:before {
    content: "\ef2e";
    opacity: 0.3;
  }
  
  .ler-timer .path2:before {
    content: "\ef2f";
    margin-left: -1em;
  }
  
  .ler-timer .path3:before {
    content: "\ef30";
    margin-left: -1em;
  }
  
  .ler-to-left:before {
    content: "\ef31";
  }
  
  .ler-to-right:before {
    content: "\ef32";
  }
  
  .ler-toggle-off-circle .path1:before {
    content: "\ef33";
    opacity: 0.3;
  }
  
  .ler-toggle-off-circle .path2:before {
    content: "\ef34";
    margin-left: -1em;
  }
  
  .ler-toggle-off .path1:before {
    content: "\ef35";
    opacity: 0.3;
  }
  
  .ler-toggle-off .path2:before {
    content: "\ef36";
    margin-left: -1em;
  }
  
  .ler-toggle-on-circle .path1:before {
    content: "\ef37";
    opacity: 0.3;
  }
  
  .ler-toggle-on-circle .path2:before {
    content: "\ef38";
    margin-left: -1em;
  }
  
  .ler-toggle-on .path1:before {
    content: "\ef39";
    opacity: 0.3;
  }
  
  .ler-toggle-on .path2:before {
    content: "\ef3a";
    margin-left: -1em;
  }
  
  .ler-trailer .path1:before {
    content: "\ef3b";
  }
  
  .ler-trailer .path2:before {
    content: "\ef3c";
    margin-left: -1em;
  }
  
  .ler-trailer .path3:before {
    content: "\ef3d";
    margin-left: -1em;
  }
  
  .ler-trailer .path4:before {
    content: "\ef3e";
    margin-left: -1em;
  }
  
  .ler-trailer .path5:before {
    content: "\ef3f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-trash-square .path1:before {
    content: "\ef40";
    opacity: 0.3;
  }
  
  .ler-trash-square .path2:before {
    content: "\ef41";
    margin-left: -1em;
  }
  
  .ler-trash-square .path3:before {
    content: "\ef42";
    margin-left: -1em;
  }
  
  .ler-trash-square .path4:before {
    content: "\ef43";
    margin-left: -1em;
  }
  
  .ler-trash .path1:before {
    content: "\ef44";
    opacity: 0.3;
  }
  
  .ler-trash .path2:before {
    content: "\ef45";
    margin-left: -1em;
  }
  
  .ler-trash .path3:before {
    content: "\ef46";
    margin-left: -1em;
  }
  
  .ler-trash .path4:before {
    content: "\ef47";
    margin-left: -1em;
  }
  
  .ler-trash .path5:before {
    content: "\ef48";
    margin-left: -1em;
  }
  
  .ler-tree .path1:before {
    content: "\ef49";
    opacity: 0.3;
  }
  
  .ler-tree .path2:before {
    content: "\ef4a";
    margin-left: -1em;
  }
  
  .ler-tree .path3:before {
    content: "\ef4b";
    margin-left: -1em;
  }
  
  .ler-trello .path1:before {
    content: "\ef4c";
    opacity: 0.3;
  }
  
  .ler-trello .path2:before {
    content: "\ef4d";
    margin-left: -1em;
  }
  
  .ler-trello .path3:before {
    content: "\ef4e";
    margin-left: -1em;
  }
  
  .ler-triangle .path1:before {
    content: "\ef4f";
  }
  
  .ler-triangle .path2:before {
    content: "\ef50";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-triangle .path3:before {
    content: "\ef51";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-truck .path1:before {
    content: "\ef52";
  }
  
  .ler-truck .path2:before {
    content: "\ef53";
    margin-left: -1em;
  }
  
  .ler-truck .path3:before {
    content: "\ef54";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-truck .path4:before {
    content: "\ef55";
    margin-left: -1em;
  }
  
  .ler-truck .path5:before {
    content: "\ef56";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-ts .path1:before {
    content: "\ef57";
    opacity: 0.3;
  }
  
  .ler-ts .path2:before {
    content: "\ef58";
    margin-left: -1em;
  }
  
  .ler-ts .path3:before {
    content: "\ef59";
    margin-left: -1em;
  }
  
  .ler-twitch .path1:before {
    content: "\ef5a";
    opacity: 0.3;
  }
  
  .ler-twitch .path2:before {
    content: "\ef5b";
    margin-left: -1em;
  }
  
  .ler-twitch .path3:before {
    content: "\ef5c";
    margin-left: -1em;
  }
  
  .ler-twitter .path1:before {
    content: "\ef5d";
    opacity: 0.3;
  }
  
  .ler-twitter .path2:before {
    content: "\ef5e";
    margin-left: -1em;
  }
  
  .ler-two-credit-cart .path1:before {
    content: "\ef5f";
  }
  
  .ler-two-credit-cart .path2:before {
    content: "\ef60";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-two-credit-cart .path3:before {
    content: "\ef61";
    margin-left: -1em;
  }
  
  .ler-two-credit-cart .path4:before {
    content: "\ef62";
    margin-left: -1em;
  }
  
  .ler-two-credit-cart .path5:before {
    content: "\ef63";
    margin-left: -1em;
  }
  
  .ler-underlining .path1:before {
    content: "\ef64";
    opacity: 0.3;
  }
  
  .ler-underlining .path2:before {
    content: "\ef65";
    margin-left: -1em;
  }
  
  .ler-underlining .path3:before {
    content: "\ef66";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-up-down .path1:before {
    content: "\ef67";
    opacity: 0.3;
  }
  
  .ler-up-down .path2:before {
    content: "\ef68";
    margin-left: -1em;
  }
  
  .ler-up-down .path3:before {
    content: "\ef69";
    margin-left: -1em;
  }
  
  .ler-up-square .path1:before {
    content: "\ef6a";
    opacity: 0.3;
  }
  
  .ler-up-square .path2:before {
    content: "\ef6b";
    margin-left: -1em;
  }
  
  .ler-up:before {
    content: "\ef6c";
  }
  
  .ler-update-file .path1:before {
    content: "\ef6d";
    opacity: 0.3;
  }
  
  .ler-update-file .path2:before {
    content: "\ef6e";
    margin-left: -1em;
  }
  
  .ler-update-file .path3:before {
    content: "\ef6f";
    margin-left: -1em;
  }
  
  .ler-update-file .path4:before {
    content: "\ef70";
    margin-left: -1em;
  }
  
  .ler-update-folder .path1:before {
    content: "\ef71";
    opacity: 0.3;
  }
  
  .ler-update-folder .path2:before {
    content: "\ef72";
    margin-left: -1em;
  }
  
  .ler-user-edit .path1:before {
    content: "\ef73";
  }
  
  .ler-user-edit .path2:before {
    content: "\ef74";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-user-edit .path3:before {
    content: "\ef75";
    margin-left: -1em;
  }
  
  .ler-user-square .path1:before {
    content: "\ef76";
    opacity: 0.3;
  }
  
  .ler-user-square .path2:before {
    content: "\ef77";
    margin-left: -1em;
  }
  
  .ler-user-square .path3:before {
    content: "\ef78";
    margin-left: -1em;
  }
  
  .ler-user-tick .path1:before {
    content: "\ef79";
  }
  
  .ler-user-tick .path2:before {
    content: "\ef7a";
    margin-left: -1em;
  }
  
  .ler-user-tick .path3:before {
    content: "\ef7b";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-user .path1:before {
    content: "\ef7c";
    opacity: 0.3;
  }
  
  .ler-user .path2:before {
    content: "\ef7d";
    margin-left: -1em;
  }
  
  .ler-verify .path1:before {
    content: "\ef7e";
    opacity: 0.3;
  }
  
  .ler-verify .path2:before {
    content: "\ef7f";
    margin-left: -1em;
  }
  
  .ler-vibe .path1:before {
    content: "\ef80";
  }
  
  .ler-vibe .path2:before {
    content: "\ef81";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-virus .path1:before {
    content: "\ef82";
    opacity: 0.3;
  }
  
  .ler-virus .path2:before {
    content: "\ef83";
    margin-left: -1em;
  }
  
  .ler-virus .path3:before {
    content: "\ef84";
    margin-left: -1em;
  }
  
  .ler-vue .path1:before {
    content: "\ef85";
    opacity: 0.3;
  }
  
  .ler-vue .path2:before {
    content: "\ef86";
    margin-left: -1em;
  }
  
  .ler-vuesax .path1:before {
    content: "\ef87";
  }
  
  .ler-vuesax .path2:before {
    content: "\ef88";
    margin-left: -1em;
    opacity: 0.4;
  }
  
  .ler-vuesax .path3:before {
    content: "\ef89";
    margin-left: -1em;
  }
  
  .ler-wallet .path1:before {
    content: "\ef8a";
    opacity: 0.3;
  }
  
  .ler-wallet .path2:before {
    content: "\ef8b";
    margin-left: -1em;
  }
  
  .ler-wallet .path3:before {
    content: "\ef8c";
    margin-left: -1em;
  }
  
  .ler-wallet .path4:before {
    content: "\ef8d";
    margin-left: -1em;
  }
  
  .ler-wanchain .path1:before {
    content: "\ef8e";
  }
  
  .ler-wanchain .path2:before {
    content: "\ef8f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-watch .path1:before {
    content: "\ef90";
    opacity: 0.3;
  }
  
  .ler-watch .path2:before {
    content: "\ef91";
    margin-left: -1em;
  }
  
  .ler-whatsapp .path1:before {
    content: "\ef92";
    opacity: 0.4;
  }
  
  .ler-whatsapp .path2:before {
    content: "\ef93";
    margin-left: -1em;
  }
  
  .ler-wifi-home .path1:before {
    content: "\ef94";
    opacity: 0.3;
  }
  
  .ler-wifi-home .path2:before {
    content: "\ef95";
    margin-left: -1em;
  }
  
  .ler-wifi-home .path3:before {
    content: "\ef96";
    margin-left: -1em;
  }
  
  .ler-wifi-home .path4:before {
    content: "\ef97";
    margin-left: -1em;
  }
  
  .ler-wifi-square .path1:before {
    content: "\ef98";
    opacity: 0.3;
  }
  
  .ler-wifi-square .path2:before {
    content: "\ef99";
    margin-left: -1em;
  }
  
  .ler-wifi-square .path3:before {
    content: "\ef9a";
    margin-left: -1em;
  }
  
  .ler-wifi-square .path4:before {
    content: "\ef9b";
    margin-left: -1em;
  }
  
  .ler-wifi .path1:before {
    content: "\ef9c";
  }
  
  .ler-wifi .path2:before {
    content: "\ef9d";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-wifi .path3:before {
    content: "\ef9e";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-wifi .path4:before {
    content: "\ef9f";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-wrench .path1:before {
    content: "\efa0";
    opacity: 0.3;
  }
  
  .ler-wrench .path2:before {
    content: "\efa1";
    margin-left: -1em;
  }
  
  .ler-xaomi .path1:before {
    content: "\efa2";
  }
  
  .ler-xaomi .path2:before {
    content: "\efa3";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-xd .path1:before {
    content: "\efa4";
    opacity: 0.3;
  }
  
  .ler-xd .path2:before {
    content: "\efa5";
    margin-left: -1em;
  }
  
  .ler-xd .path3:before {
    content: "\efa6";
    margin-left: -1em;
  }
  
  .ler-xmr .path1:before {
    content: "\efa7";
  }
  
  .ler-xmr .path2:before {
    content: "\efa8";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-yii .path1:before {
    content: "\efa9";
    opacity: 0.3;
  }
  
  .ler-yii .path2:before {
    content: "\efaa";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-yii .path3:before {
    content: "\efab";
    margin-left: -1em;
  }
  
  .ler-youtube .path1:before {
    content: "\efac";
  }
  
  .ler-youtube .path2:before {
    content: "\efad";
    margin-left: -1em;
    opacity: 0.3;
  }
  
  .ler-duotone i {
    font-style: normal;
  }
  
  @font-face {
    font-family: "keenicons-outline";
    src: url("assets/fonts/keenicons/keenicons-outline.eot?fzo4bm");
    src: url("assets/fonts/keenicons/keenicons-outline.eot?fzo4bm#iefix") format("embedded-opentype"), url("assets/fonts/keenicons/keenicons-outline.ttf?fzo4bm") format("truetype"), url("assets/fonts/keenicons/keenicons-outline.woff?fzo4bm") format("woff"), url("assets/fonts/keenicons/keenicons-outline.svg?fzo4bm#keenicons-outline") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  .ler-outline {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "keenicons-outline" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ler-abstract-1.ler-outline:before {
    content: "\e900";
  }
  
  .ler-abstract-2.ler-outline:before {
    content: "\e901";
  }
  
  .ler-abstract-3.ler-outline:before {
    content: "\e902";
  }
  
  .ler-abstract-4.ler-outline:before {
    content: "\e903";
  }
  
  .ler-abstract-5.ler-outline:before {
    content: "\e904";
  }
  
  .ler-abstract-6.ler-outline:before {
    content: "\e905";
  }
  
  .ler-abstract-7.ler-outline:before {
    content: "\e906";
  }
  
  .ler-abstract-8.ler-outline:before {
    content: "\e907";
  }
  
  .ler-abstract-9.ler-outline:before {
    content: "\e908";
  }
  
  .ler-abstract-10.ler-outline:before {
    content: "\e909";
  }
  
  .ler-abstract-11.ler-outline:before {
    content: "\e90a";
  }
  
  .ler-abstract-12.ler-outline:before {
    content: "\e90b";
  }
  
  .ler-abstract-13.ler-outline:before {
    content: "\e90c";
  }
  
  .ler-abstract-14.ler-outline:before {
    content: "\e90d";
  }
  
  .ler-abstract-15.ler-outline:before {
    content: "\e90e";
  }
  
  .ler-abstract-16.ler-outline:before {
    content: "\e90f";
  }
  
  .ler-abstract-17.ler-outline:before {
    content: "\e910";
  }
  
  .ler-abstract-18.ler-outline:before {
    content: "\e911";
  }
  
  .ler-abstract-19.ler-outline:before {
    content: "\e912";
  }
  
  .ler-abstract-20.ler-outline:before {
    content: "\e913";
  }
  
  .ler-abstract-21.ler-outline:before {
    content: "\e914";
  }
  
  .ler-abstract-22.ler-outline:before {
    content: "\e915";
  }
  
  .ler-abstract-23.ler-outline:before {
    content: "\e916";
  }
  
  .ler-abstract-24.ler-outline:before {
    content: "\e917";
  }
  
  .ler-abstract-25.ler-outline:before {
    content: "\e918";
  }
  
  .ler-abstract-26.ler-outline:before {
    content: "\e919";
  }
  
  .ler-abstract-27.ler-outline:before {
    content: "\e91a";
  }
  
  .ler-abstract-28.ler-outline:before {
    content: "\e91b";
  }
  
  .ler-abstract-29.ler-outline:before {
    content: "\e91c";
  }
  
  .ler-abstract-30.ler-outline:before {
    content: "\e91d";
  }
  
  .ler-abstract-31.ler-outline:before {
    content: "\e91e";
  }
  
  .ler-abstract-32.ler-outline:before {
    content: "\e91f";
  }
  
  .ler-abstract-33.ler-outline:before {
    content: "\e920";
  }
  
  .ler-abstract-34.ler-outline:before {
    content: "\e921";
  }
  
  .ler-abstract-35.ler-outline:before {
    content: "\e922";
  }
  
  .ler-abstract-36.ler-outline:before {
    content: "\e923";
  }
  
  .ler-abstract-37.ler-outline:before {
    content: "\e924";
  }
  
  .ler-abstract-38.ler-outline:before {
    content: "\e925";
  }
  
  .ler-abstract-39.ler-outline:before {
    content: "\e926";
  }
  
  .ler-abstract-40.ler-outline:before {
    content: "\e927";
  }
  
  .ler-abstract-41.ler-outline:before {
    content: "\e928";
  }
  
  .ler-abstract-42.ler-outline:before {
    content: "\e929";
  }
  
  .ler-abstract-43.ler-outline:before {
    content: "\e92a";
  }
  
  .ler-abstract-44.ler-outline:before {
    content: "\e92b";
  }
  
  .ler-abstract-45.ler-outline:before {
    content: "\e92c";
  }
  
  .ler-abstract-46.ler-outline:before {
    content: "\e92d";
  }
  
  .ler-abstract-47.ler-outline:before {
    content: "\e92e";
  }
  
  .ler-abstract-48.ler-outline:before {
    content: "\e92f";
  }
  
  .ler-abstract-49.ler-outline:before {
    content: "\e930";
  }
  
  .ler-abstract.ler-outline:before {
    content: "\e931";
  }
  
  .ler-add-files.ler-outline:before {
    content: "\e932";
  }
  
  .ler-add-folder.ler-outline:before {
    content: "\e933";
  }
  
  .ler-add-item.ler-outline:before {
    content: "\e934";
  }
  
  .ler-add-notepad.ler-outline:before {
    content: "\e935";
  }
  
  .ler-address-book.ler-outline:before {
    content: "\e936";
  }
  
  .ler-airplane-square.ler-outline:before {
    content: "\e937";
  }
  
  .ler-airplane.ler-outline:before {
    content: "\e938";
  }
  
  .ler-airpod.ler-outline:before {
    content: "\e939";
  }
  
  .ler-android.ler-outline:before {
    content: "\e93a";
  }
  
  .ler-angular.ler-outline:before {
    content: "\e93b";
  }
  
  .ler-apple.ler-outline:before {
    content: "\e93c";
  }
  
  .ler-archive-tick.ler-outline:before {
    content: "\e93d";
  }
  
  .ler-archive.ler-outline:before {
    content: "\e93e";
  }
  
  .ler-arrow-circle-left.ler-outline:before {
    content: "\e93f";
  }
  
  .ler-arrow-circle-right.ler-outline:before {
    content: "\e940";
  }
  
  .ler-arrow-diagonal.ler-outline:before {
    content: "\e941";
  }
  
  .ler-arrow-down-left.ler-outline:before {
    content: "\e942";
  }
  
  .ler-arrow-down-refraction.ler-outline:before {
    content: "\e943";
  }
  
  .ler-arrow-down-right.ler-outline:before {
    content: "\e944";
  }
  
  .ler-arrow-down.ler-outline:before {
    content: "\e945";
  }
  
  .ler-arrow-left.ler-outline:before {
    content: "\e946";
  }
  
  .ler-arrow-mix.ler-outline:before {
    content: "\e947";
  }
  
  .ler-arrow-right-left.ler-outline:before {
    content: "\e948";
  }
  
  .ler-arrow-right.ler-outline:before {
    content: "\e949";
  }
  
  .ler-arrow-two-diagonals.ler-outline:before {
    content: "\e94a";
  }
  
  .ler-arrow-up-down.ler-outline:before {
    content: "\e94b";
  }
  
  .ler-arrow-up-left.ler-outline:before {
    content: "\e94c";
  }
  
  .ler-arrow-up-refraction.ler-outline:before {
    content: "\e94d";
  }
  
  .ler-arrow-up-right.ler-outline:before {
    content: "\e94e";
  }
  
  .ler-arrow-up.ler-outline:before {
    content: "\e94f";
  }
  
  .ler-arrow-zigzag.ler-outline:before {
    content: "\e950";
  }
  
  .ler-arrows-circle.ler-outline:before {
    content: "\e951";
  }
  
  .ler-arrows-loop.ler-outline:before {
    content: "\e952";
  }
  
  .ler-artificial-intelligence.ler-outline:before {
    content: "\e953";
  }
  
  .ler-auto-brightness.ler-outline:before {
    content: "\e954";
  }
  
  .ler-avalanche.ler-outline:before {
    content: "\e955";
  }
  
  .ler-award.ler-outline:before {
    content: "\e956";
  }
  
  .ler-badge.ler-outline:before {
    content: "\e957";
  }
  
  .ler-bandage.ler-outline:before {
    content: "\e958";
  }
  
  .ler-bank.ler-outline:before {
    content: "\e959";
  }
  
  .ler-barcode.ler-outline:before {
    content: "\e95a";
  }
  
  .ler-basket-ok.ler-outline:before {
    content: "\e95b";
  }
  
  .ler-basket.ler-outline:before {
    content: "\e95c";
  }
  
  .ler-behance.ler-outline:before {
    content: "\e95d";
  }
  
  .ler-bill.ler-outline:before {
    content: "\e95e";
  }
  
  .ler-binance-usd.ler-outline:before {
    content: "\e95f";
  }
  
  .ler-binance.ler-outline:before {
    content: "\e960";
  }
  
  .ler-bitcoin.ler-outline:before {
    content: "\e961";
  }
  
  .ler-black-down.ler-outline:before {
    content: "\e962";
  }
  
  .ler-black-left-line.ler-outline:before {
    content: "\e963";
  }
  
  .ler-black-left.ler-outline:before {
    content: "\e964";
  }
  
  .ler-black-right-line.ler-outline:before {
    content: "\e965";
  }
  
  .ler-black-right.ler-outline:before {
    content: "\e966";
  }
  
  .ler-black-up.ler-outline:before {
    content: "\e967";
  }
  
  .ler-bluetooth.ler-outline:before {
    content: "\e968";
  }
  
  .ler-book-open.ler-outline:before {
    content: "\e969";
  }
  
  .ler-book-square.ler-outline:before {
    content: "\e96a";
  }
  
  .ler-book.ler-outline:before {
    content: "\e96b";
  }
  
  .ler-bookmark-2.ler-outline:before {
    content: "\e96c";
  }
  
  .ler-bookmark.ler-outline:before {
    content: "\e96d";
  }
  
  .ler-bootstrap.ler-outline:before {
    content: "\e96e";
  }
  
  .ler-briefcase.ler-outline:before {
    content: "\e96f";
  }
  
  .ler-brifecase-cros.ler-outline:before {
    content: "\e970";
  }
  
  .ler-brifecase-tick.ler-outline:before {
    content: "\e971";
  }
  
  .ler-brifecase-timer.ler-outline:before {
    content: "\e972";
  }
  
  .ler-brush.ler-outline:before {
    content: "\e973";
  }
  
  .ler-bucket-square.ler-outline:before {
    content: "\e974";
  }
  
  .ler-bucket.ler-outline:before {
    content: "\e975";
  }
  
  .ler-burger-menu-1.ler-outline:before {
    content: "\e976";
  }
  
  .ler-burger-menu-2.ler-outline:before {
    content: "\e977";
  }
  
  .ler-burger-menu-3.ler-outline:before {
    content: "\e978";
  }
  
  .ler-burger-menu-4.ler-outline:before {
    content: "\e979";
  }
  
  .ler-burger-menu-5.ler-outline:before {
    content: "\e97a";
  }
  
  .ler-burger-menu-6.ler-outline:before {
    content: "\e97b";
  }
  
  .ler-burger-menu.ler-outline:before {
    content: "\e97c";
  }
  
  .ler-bus.ler-outline:before {
    content: "\e97d";
  }
  
  .ler-calculator.ler-outline:before {
    content: "\e97e";
  }
  
  .ler-calendar-2.ler-outline:before {
    content: "\e97f";
  }
  
  .ler-calendar-8.ler-outline:before {
    content: "\e980";
  }
  
  .ler-calendar-add.ler-outline:before {
    content: "\e981";
  }
  
  .ler-calendar-edit.ler-outline:before {
    content: "\e982";
  }
  
  .ler-calendar-remove.ler-outline:before {
    content: "\e983";
  }
  
  .ler-calendar-search.ler-outline:before {
    content: "\e984";
  }
  
  .ler-calendar-tick.ler-outline:before {
    content: "\e985";
  }
  
  .ler-calendar.ler-outline:before {
    content: "\e986";
  }
  
  .ler-call.ler-outline:before {
    content: "\e987";
  }
  
  .ler-capsule.ler-outline:before {
    content: "\e988";
  }
  
  .ler-car-2.ler-outline:before {
    content: "\e989";
  }
  
  .ler-car-3.ler-outline:before {
    content: "\e98a";
  }
  
  .ler-car.ler-outline:before {
    content: "\e98b";
  }
  
  .ler-category.ler-outline:before {
    content: "\e98c";
  }
  
  .ler-cd.ler-outline:before {
    content: "\e98d";
  }
  
  .ler-celsius.ler-outline:before {
    content: "\e98e";
  }
  
  .ler-chart-line-down-2.ler-outline:before {
    content: "\e98f";
  }
  
  .ler-chart-line-down.ler-outline:before {
    content: "\e990";
  }
  
  .ler-chart-line-star.ler-outline:before {
    content: "\e991";
  }
  
  .ler-chart-line-up-2.ler-outline:before {
    content: "\e992";
  }
  
  .ler-chart-line-up.ler-outline:before {
    content: "\e993";
  }
  
  .ler-chart-line.ler-outline:before {
    content: "\e994";
  }
  
  .ler-chart-pie-3.ler-outline:before {
    content: "\e995";
  }
  
  .ler-chart-pie-4.ler-outline:before {
    content: "\e996";
  }
  
  .ler-chart-pie-simple.ler-outline:before {
    content: "\e997";
  }
  
  .ler-chart-pie-too.ler-outline:before {
    content: "\e998";
  }
  
  .ler-chart-simple-2.ler-outline:before {
    content: "\e999";
  }
  
  .ler-chart-simple-3.ler-outline:before {
    content: "\e99a";
  }
  
  .ler-chart-simple.ler-outline:before {
    content: "\e99b";
  }
  
  .ler-chart.ler-outline:before {
    content: "\e99c";
  }
  
  .ler-check-circle.ler-outline:before {
    content: "\e99d";
  }
  
  .ler-check-square.ler-outline:before {
    content: "\e99e";
  }
  
  .ler-check.ler-outline:before {
    content: "\e99f";
  }
  
  .ler-cheque.ler-outline:before {
    content: "\e9a0";
  }
  
  .ler-chrome.ler-outline:before {
    content: "\e9a1";
  }
  
  .ler-classmates.ler-outline:before {
    content: "\e9a2";
  }
  
  .ler-click.ler-outline:before {
    content: "\e9a3";
  }
  
  .ler-clipboard.ler-outline:before {
    content: "\e9a4";
  }
  
  .ler-cloud-add.ler-outline:before {
    content: "\e9a5";
  }
  
  .ler-cloud-change.ler-outline:before {
    content: "\e9a6";
  }
  
  .ler-cloud-download.ler-outline:before {
    content: "\e9a7";
  }
  
  .ler-cloud.ler-outline:before {
    content: "\e9a8";
  }
  
  .ler-code.ler-outline:before {
    content: "\e9a9";
  }
  
  .ler-coffee.ler-outline:before {
    content: "\e9aa";
  }
  
  .ler-color-swatch.ler-outline:before {
    content: "\e9ab";
  }
  
  .ler-colors-square.ler-outline:before {
    content: "\e9ac";
  }
  
  .ler-compass.ler-outline:before {
    content: "\e9ad";
  }
  
  .ler-copy-success.ler-outline:before {
    content: "\e9ae";
  }
  
  .ler-copy.ler-outline:before {
    content: "\e9af";
  }
  
  .ler-courier-express.ler-outline:before {
    content: "\e9b0";
  }
  
  .ler-courier.ler-outline:before {
    content: "\e9b1";
  }
  
  .ler-credit-cart.ler-outline:before {
    content: "\e9b2";
  }
  
  .ler-cross-circle.ler-outline:before {
    content: "\e9b3";
  }
  
  .ler-cross-square.ler-outline:before {
    content: "\e9b4";
  }
  
  .ler-cross.ler-outline:before {
    content: "\e9b5";
  }
  
  .ler-crown-2.ler-outline:before {
    content: "\e9b6";
  }
  
  .ler-crown.ler-outline:before {
    content: "\e9b7";
  }
  
  .ler-css.ler-outline:before {
    content: "\e9b8";
  }
  
  .ler-cube-2.ler-outline:before {
    content: "\e9b9";
  }
  
  .ler-cube-3.ler-outline:before {
    content: "\e9ba";
  }
  
  .ler-cup.ler-outline:before {
    content: "\e9bb";
  }
  
  .ler-dash.ler-outline:before {
    content: "\e9bc";
  }
  
  .ler-data.ler-outline:before {
    content: "\e9bd";
  }
  
  .ler-delete-files.ler-outline:before {
    content: "\e9be";
  }
  
  .ler-delete-folder.ler-outline:before {
    content: "\e9bf";
  }
  
  .ler-delivery-2.ler-outline:before {
    content: "\e9c0";
  }
  
  .ler-delivery-3.ler-outline:before {
    content: "\e9c1";
  }
  
  .ler-delivery-24.ler-outline:before {
    content: "\e9c2";
  }
  
  .ler-delivery-door.ler-outline:before {
    content: "\e9c3";
  }
  
  .ler-delivery-geolocation.ler-outline:before {
    content: "\e9c4";
  }
  
  .ler-delivery-time.ler-outline:before {
    content: "\e9c5";
  }
  
  .ler-delivery.ler-outline:before {
    content: "\e9c6";
  }
  
  .ler-design-2.ler-outline:before {
    content: "\e9c7";
  }
  
  .ler-design-frame.ler-outline:before {
    content: "\e9c8";
  }
  
  .ler-design-mask.ler-outline:before {
    content: "\e9c9";
  }
  
  .ler-design.ler-outline:before {
    content: "\e9ca";
  }
  
  .ler-devices-2.ler-outline:before {
    content: "\e9cb";
  }
  
  .ler-devices.ler-outline:before {
    content: "\e9cc";
  }
  
  .ler-diamonds.ler-outline:before {
    content: "\e9cd";
  }
  
  .ler-directbox-default.ler-outline:before {
    content: "\e9ce";
  }
  
  .ler-disconnect.ler-outline:before {
    content: "\e9cf";
  }
  
  .ler-discount.ler-outline:before {
    content: "\e9d0";
  }
  
  .ler-disk.ler-outline:before {
    content: "\e9d1";
  }
  
  .ler-dislike.ler-outline:before {
    content: "\e9d2";
  }
  
  .ler-dj.ler-outline:before {
    content: "\e9d3";
  }
  
  .ler-document.ler-outline:before {
    content: "\e9d4";
  }
  
  .ler-dollar.ler-outline:before {
    content: "\e9d5";
  }
  
  .ler-dots-circle-vertical.ler-outline:before {
    content: "\e9d6";
  }
  
  .ler-dots-circle.ler-outline:before {
    content: "\e9d7";
  }
  
  .ler-dots-horizontal.ler-outline:before {
    content: "\e9d8";
  }
  
  .ler-dots-square-vertical.ler-outline:before {
    content: "\e9d9";
  }
  
  .ler-dots-square.ler-outline:before {
    content: "\e9da";
  }
  
  .ler-dots-vertical.ler-outline:before {
    content: "\e9db";
  }
  
  .ler-double-check-circle.ler-outline:before {
    content: "\e9dc";
  }
  
  .ler-double-check.ler-outline:before {
    content: "\e9dd";
  }
  
  .ler-double-down.ler-outline:before {
    content: "\e9de";
  }
  
  .ler-double-left-arrow.ler-outline:before {
    content: "\e9df";
  }
  
  .ler-double-left.ler-outline:before {
    content: "\e9e0";
  }
  
  .ler-double-right-arrow.ler-outline:before {
    content: "\e9e1";
  }
  
  .ler-double-right.ler-outline:before {
    content: "\e9e2";
  }
  
  .ler-double-up.ler-outline:before {
    content: "\e9e3";
  }
  
  .ler-down-square.ler-outline:before {
    content: "\e9e4";
  }
  
  .ler-down.ler-outline:before {
    content: "\e9e5";
  }
  
  .ler-dribbble.ler-outline:before {
    content: "\e9e6";
  }
  
  .ler-drop.ler-outline:before {
    content: "\e9e7";
  }
  
  .ler-dropbox.ler-outline:before {
    content: "\e9e8";
  }
  
  .ler-educare.ler-outline:before {
    content: "\e9e9";
  }
  
  .ler-electricity.ler-outline:before {
    content: "\e9ea";
  }
  
  .ler-electronic-clock.ler-outline:before {
    content: "\e9eb";
  }
  
  .ler-element-1.ler-outline:before {
    content: "\e9ec";
  }
  
  .ler-element-2.ler-outline:before {
    content: "\e9ed";
  }
  
  .ler-element-3.ler-outline:before {
    content: "\e9ee";
  }
  
  .ler-element-4.ler-outline:before {
    content: "\e9ef";
  }
  
  .ler-element-5.ler-outline:before {
    content: "\e9f0";
  }
  
  .ler-element-6.ler-outline:before {
    content: "\e9f1";
  }
  
  .ler-element-7.ler-outline:before {
    content: "\e9f2";
  }
  
  .ler-element-8.ler-outline:before {
    content: "\e9f3";
  }
  
  .ler-element-9.ler-outline:before {
    content: "\e9f4";
  }
  
  .ler-element-10.ler-outline:before {
    content: "\e9f5";
  }
  
  .ler-element-11.ler-outline:before {
    content: "\e9f6";
  }
  
  .ler-element-12.ler-outline:before {
    content: "\e9f7";
  }
  
  .ler-element-equal.ler-outline:before {
    content: "\e9f8";
  }
  
  .ler-element-plus.ler-outline:before {
    content: "\e9f9";
  }
  
  .ler-emoji-happy.ler-outline:before {
    content: "\e9fa";
  }
  
  .ler-enjin-coin.ler-outline:before {
    content: "\e9fb";
  }
  
  .ler-entrance-left.ler-outline:before {
    content: "\e9fc";
  }
  
  .ler-entrance-right.ler-outline:before {
    content: "\e9fd";
  }
  
  .ler-eraser.ler-outline:before {
    content: "\e9fe";
  }
  
  .ler-euro.ler-outline:before {
    content: "\e9ff";
  }
  
  .ler-exit-down.ler-outline:before {
    content: "\ea00";
  }
  
  .ler-exit-left.ler-outline:before {
    content: "\ea01";
  }
  
  .ler-exit-right-corner.ler-outline:before {
    content: "\ea02";
  }
  
  .ler-exit-right.ler-outline:before {
    content: "\ea03";
  }
  
  .ler-exit-up.ler-outline:before {
    content: "\ea04";
  }
  
  .ler-external-drive.ler-outline:before {
    content: "\ea05";
  }
  
  .ler-eye-slash.ler-outline:before {
    content: "\ea06";
  }
  
  .ler-eye.ler-outline:before {
    content: "\ea07";
  }
  
  .ler-facebook.ler-outline:before {
    content: "\ea08";
  }
  
  .ler-faceid.ler-outline:before {
    content: "\ea09";
  }
  
  .ler-fasten.ler-outline:before {
    content: "\ea0a";
  }
  
  .ler-fat-rows.ler-outline:before {
    content: "\ea0b";
  }
  
  .ler-feather.ler-outline:before {
    content: "\ea0c";
  }
  
  .ler-figma.ler-outline:before {
    content: "\ea0d";
  }
  
  .ler-file-added.ler-outline:before {
    content: "\ea0e";
  }
  
  .ler-file-deleted.ler-outline:before {
    content: "\ea0f";
  }
  
  .ler-file-down.ler-outline:before {
    content: "\ea10";
  }
  
  .ler-file-left.ler-outline:before {
    content: "\ea11";
  }
  
  .ler-file-right.ler-outline:before {
    content: "\ea12";
  }
  
  .ler-file-sheet.ler-outline:before {
    content: "\ea13";
  }
  
  .ler-file-up.ler-outline:before {
    content: "\ea14";
  }
  
  .ler-file.ler-outline:before {
    content: "\ea15";
  }
  
  .ler-files-tablet.ler-outline:before {
    content: "\ea16";
  }
  
  .ler-filter-edit.ler-outline:before {
    content: "\ea17";
  }
  
  .ler-filter-search.ler-outline:before {
    content: "\ea18";
  }
  
  .ler-filter-square.ler-outline:before {
    content: "\ea19";
  }
  
  .ler-filter-tablet.ler-outline:before {
    content: "\ea1a";
  }
  
  .ler-filter-tick.ler-outline:before {
    content: "\ea1b";
  }
  
  .ler-filter.ler-outline:before {
    content: "\ea1c";
  }
  
  .ler-finance-calculator.ler-outline:before {
    content: "\ea1d";
  }
  
  .ler-financial-schedule.ler-outline:before {
    content: "\ea1e";
  }
  
  .ler-fingerprint-scanning.ler-outline:before {
    content: "\ea1f";
  }
  
  .ler-flag.ler-outline:before {
    content: "\ea20";
  }
  
  .ler-flash-circle.ler-outline:before {
    content: "\ea21";
  }
  
  .ler-flask.ler-outline:before {
    content: "\ea22";
  }
  
  .ler-focus.ler-outline:before {
    content: "\ea23";
  }
  
  .ler-folder-added.ler-outline:before {
    content: "\ea24";
  }
  
  .ler-folder-down.ler-outline:before {
    content: "\ea25";
  }
  
  .ler-folder-up.ler-outline:before {
    content: "\ea26";
  }
  
  .ler-folder.ler-outline:before {
    content: "\ea27";
  }
  
  .ler-frame.ler-outline:before {
    content: "\ea28";
  }
  
  .ler-gear.ler-outline:before {
    content: "\ea29";
  }
  
  .ler-general-mouse.ler-outline:before {
    content: "\ea2a";
  }
  
  .ler-geolocation-home.ler-outline:before {
    content: "\ea2b";
  }
  
  .ler-geolocation.ler-outline:before {
    content: "\ea2c";
  }
  
  .ler-ghost.ler-outline:before {
    content: "\ea2d";
  }
  
  .ler-gift.ler-outline:before {
    content: "\ea2e";
  }
  
  .ler-github.ler-outline:before {
    content: "\ea2f";
  }
  
  .ler-glass.ler-outline:before {
    content: "\ea30";
  }
  
  .ler-google-play.ler-outline:before {
    content: "\ea31";
  }
  
  .ler-google.ler-outline:before {
    content: "\ea32";
  }
  
  .ler-graph-2.ler-outline:before {
    content: "\ea33";
  }
  
  .ler-graph-3.ler-outline:before {
    content: "\ea34";
  }
  
  .ler-graph-4.ler-outline:before {
    content: "\ea35";
  }
  
  .ler-graph-up.ler-outline:before {
    content: "\ea36";
  }
  
  .ler-graph.ler-outline:before {
    content: "\ea37";
  }
  
  .ler-grid-2.ler-outline:before {
    content: "\ea38";
  }
  
  .ler-grid-frame.ler-outline:before {
    content: "\ea39";
  }
  
  .ler-grid.ler-outline:before {
    content: "\ea3a";
  }
  
  .ler-handcart.ler-outline:before {
    content: "\ea3b";
  }
  
  .ler-happy-emoji.ler-outline:before {
    content: "\ea3c";
  }
  
  .ler-heart-circle.ler-outline:before {
    content: "\ea3d";
  }
  
  .ler-heart.ler-outline:before {
    content: "\ea3e";
  }
  
  .ler-home-1.ler-outline:before {
    content: "\ea3f";
  }
  
  .ler-home-2.ler-outline:before {
    content: "\ea40";
  }
  
  .ler-home-3.ler-outline:before {
    content: "\ea41";
  }
  
  .ler-home.ler-outline:before {
    content: "\ea42";
  }
  
  .ler-html.ler-outline:before {
    content: "\ea43";
  }
  
  .ler-icon.ler-outline:before {
    content: "\ea44";
  }
  
  .ler-illustrator.ler-outline:before {
    content: "\ea45";
  }
  
  .ler-information-2.ler-outline:before {
    content: "\ea46";
  }
  
  .ler-information-3.ler-outline:before {
    content: "\ea47";
  }
  
  .ler-information-4.ler-outline:before {
    content: "\ea48";
  }
  
  .ler-information-5.ler-outline:before {
    content: "\ea49";
  }
  
  .ler-information.ler-outline:before {
    content: "\ea4a";
  }
  
  .ler-instagram.ler-outline:before {
    content: "\ea4b";
  }
  
  .ler-joystick.ler-outline:before {
    content: "\ea4c";
  }
  
  .ler-js-2.ler-outline:before {
    content: "\ea4d";
  }
  
  .ler-js.ler-outline:before {
    content: "\ea4e";
  }
  
  .ler-kanban.ler-outline:before {
    content: "\ea4f";
  }
  
  .ler-key-square.ler-outline:before {
    content: "\ea50";
  }
  
  .ler-key.ler-outline:before {
    content: "\ea51";
  }
  
  .ler-keyboard.ler-outline:before {
    content: "\ea52";
  }
  
  .ler-laptop.ler-outline:before {
    content: "\ea53";
  }
  
  .ler-laravel.ler-outline:before {
    content: "\ea54";
  }
  
  .ler-left-square.ler-outline:before {
    content: "\ea55";
  }
  
  .ler-left.ler-outline:before {
    content: "\ea56";
  }
  
  .ler-like-2.ler-outline:before {
    content: "\ea57";
  }
  
  .ler-like-folder.ler-outline:before {
    content: "\ea58";
  }
  
  .ler-like-shapes.ler-outline:before {
    content: "\ea59";
  }
  
  .ler-like-tag.ler-outline:before {
    content: "\ea5a";
  }
  
  .ler-like.ler-outline:before {
    content: "\ea5b";
  }
  
  .ler-loading.ler-outline:before {
    content: "\ea5c";
  }
  
  .ler-lock-2.ler-outline:before {
    content: "\ea5d";
  }
  
  .ler-lock-3.ler-outline:before {
    content: "\ea5e";
  }
  
  .ler-lock.ler-outline:before {
    content: "\ea5f";
  }
  
  .ler-logistic.ler-outline:before {
    content: "\ea60";
  }
  
  .ler-lots-shopping.ler-outline:before {
    content: "\ea61";
  }
  
  .ler-lovely.ler-outline:before {
    content: "\ea62";
  }
  
  .ler-lts.ler-outline:before {
    content: "\ea63";
  }
  
  .ler-magnifier.ler-outline:before {
    content: "\ea64";
  }
  
  .ler-map.ler-outline:before {
    content: "\ea65";
  }
  
  .ler-mask.ler-outline:before {
    content: "\ea66";
  }
  
  .ler-maximize.ler-outline:before {
    content: "\ea67";
  }
  
  .ler-medal-star.ler-outline:before {
    content: "\ea68";
  }
  
  .ler-menu.ler-outline:before {
    content: "\ea69";
  }
  
  .ler-message-add.ler-outline:before {
    content: "\ea6a";
  }
  
  .ler-message-edit.ler-outline:before {
    content: "\ea6b";
  }
  
  .ler-message-minus.ler-outline:before {
    content: "\ea6c";
  }
  
  .ler-message-notif.ler-outline:before {
    content: "\ea6d";
  }
  
  .ler-message-programming.ler-outline:before {
    content: "\ea6e";
  }
  
  .ler-message-question.ler-outline:before {
    content: "\ea6f";
  }
  
  .ler-message-text-2.ler-outline:before {
    content: "\ea70";
  }
  
  .ler-message-text.ler-outline:before {
    content: "\ea71";
  }
  
  .ler-messages.ler-outline:before {
    content: "\ea72";
  }
  
  .ler-microsoft.ler-outline:before {
    content: "\ea73";
  }
  
  .ler-milk.ler-outline:before {
    content: "\ea74";
  }
  
  .ler-minus-circle.ler-outline:before {
    content: "\ea75";
  }
  
  .ler-minus-folder.ler-outline:before {
    content: "\ea76";
  }
  
  .ler-minus-square.ler-outline:before {
    content: "\ea77";
  }
  
  .ler-minus.ler-outline:before {
    content: "\ea78";
  }
  
  .ler-monitor-mobile.ler-outline:before {
    content: "\ea79";
  }
  
  .ler-moon.ler-outline:before {
    content: "\ea7a";
  }
  
  .ler-more-2.ler-outline:before {
    content: "\ea7b";
  }
  
  .ler-mouse-circle.ler-outline:before {
    content: "\ea7c";
  }
  
  .ler-mouse-square.ler-outline:before {
    content: "\ea7d";
  }
  
  .ler-mouse.ler-outline:before {
    content: "\ea7e";
  }
  
  .ler-nexo.ler-outline:before {
    content: "\ea7f";
  }
  
  .ler-night-day.ler-outline:before {
    content: "\ea80";
  }
  
  .ler-note-2.ler-outline:before {
    content: "\ea81";
  }
  
  .ler-note.ler-outline:before {
    content: "\ea82";
  }
  
  .ler-notepad-bookmark.ler-outline:before {
    content: "\ea83";
  }
  
  .ler-notepad-edit.ler-outline:before {
    content: "\ea84";
  }
  
  .ler-notepad.ler-outline:before {
    content: "\ea85";
  }
  
  .ler-notification-2.ler-outline:before {
    content: "\ea86";
  }
  
  .ler-notification-bing.ler-outline:before {
    content: "\ea87";
  }
  
  .ler-notification-circle.ler-outline:before {
    content: "\ea88";
  }
  
  .ler-notification-favorite.ler-outline:before {
    content: "\ea89";
  }
  
  .ler-notification-on.ler-outline:before {
    content: "\ea8a";
  }
  
  .ler-notification-status.ler-outline:before {
    content: "\ea8b";
  }
  
  .ler-notification.ler-outline:before {
    content: "\ea8c";
  }
  
  .ler-ocean.ler-outline:before {
    content: "\ea8d";
  }
  
  .ler-office-bag.ler-outline:before {
    content: "\ea8e";
  }
  
  .ler-package.ler-outline:before {
    content: "\ea8f";
  }
  
  .ler-pails.ler-outline:before {
    content: "\ea90";
  }
  
  .ler-paintbucket.ler-outline:before {
    content: "\ea91";
  }
  
  .ler-paper-clip.ler-outline:before {
    content: "\ea92";
  }
  
  .ler-parcel-tracking.ler-outline:before {
    content: "\ea93";
  }
  
  .ler-parcel.ler-outline:before {
    content: "\ea94";
  }
  
  .ler-password-check.ler-outline:before {
    content: "\ea95";
  }
  
  .ler-paypal.ler-outline:before {
    content: "\ea96";
  }
  
  .ler-pencil.ler-outline:before {
    content: "\ea97";
  }
  
  .ler-people.ler-outline:before {
    content: "\ea98";
  }
  
  .ler-percentage.ler-outline:before {
    content: "\ea99";
  }
  
  .ler-phone.ler-outline:before {
    content: "\ea9a";
  }
  
  .ler-photoshop.ler-outline:before {
    content: "\ea9b";
  }
  
  .ler-picture.ler-outline:before {
    content: "\ea9c";
  }
  
  .ler-pill.ler-outline:before {
    content: "\ea9d";
  }
  
  .ler-pin.ler-outline:before {
    content: "\ea9e";
  }
  
  .ler-plus-circle.ler-outline:before {
    content: "\ea9f";
  }
  
  .ler-plus-square.ler-outline:before {
    content: "\eaa0";
  }
  
  .ler-plus.ler-outline:before {
    content: "\eaa1";
  }
  
  .ler-pointers.ler-outline:before {
    content: "\eaa2";
  }
  
  .ler-price-tag.ler-outline:before {
    content: "\eaa3";
  }
  
  .ler-printer.ler-outline:before {
    content: "\eaa4";
  }
  
  .ler-profile-circle.ler-outline:before {
    content: "\eaa5";
  }
  
  .ler-profile-user.ler-outline:before {
    content: "\eaa6";
  }
  
  .ler-pulse.ler-outline:before {
    content: "\eaa7";
  }
  
  .ler-purchase.ler-outline:before {
    content: "\eaa8";
  }
  
  .ler-python.ler-outline:before {
    content: "\eaa9";
  }
  
  .ler-question-2.ler-outline:before {
    content: "\eaaa";
  }
  
  .ler-question.ler-outline:before {
    content: "\eaab";
  }
  
  .ler-questionnaire-tablet.ler-outline:before {
    content: "\eaac";
  }
  
  .ler-ranking.ler-outline:before {
    content: "\eaad";
  }
  
  .ler-react.ler-outline:before {
    content: "\eaae";
  }
  
  .ler-receipt-square.ler-outline:before {
    content: "\eaaf";
  }
  
  .ler-rescue.ler-outline:before {
    content: "\eab0";
  }
  
  .ler-right-left.ler-outline:before {
    content: "\eab1";
  }
  
  .ler-right-square.ler-outline:before {
    content: "\eab2";
  }
  
  .ler-right.ler-outline:before {
    content: "\eab3";
  }
  
  .ler-rocket.ler-outline:before {
    content: "\eab4";
  }
  
  .ler-route.ler-outline:before {
    content: "\eab5";
  }
  
  .ler-router.ler-outline:before {
    content: "\eab6";
  }
  
  .ler-row-horizontal.ler-outline:before {
    content: "\eab7";
  }
  
  .ler-row-vertical.ler-outline:before {
    content: "\eab8";
  }
  
  .ler-safe-home.ler-outline:before {
    content: "\eab9";
  }
  
  .ler-satellite.ler-outline:before {
    content: "\eaba";
  }
  
  .ler-save-2.ler-outline:before {
    content: "\eabb";
  }
  
  .ler-save-deposit.ler-outline:before {
    content: "\eabc";
  }
  
  .ler-scan-barcode.ler-outline:before {
    content: "\eabd";
  }
  
  .ler-scooter-2.ler-outline:before {
    content: "\eabe";
  }
  
  .ler-scooter.ler-outline:before {
    content: "\eabf";
  }
  
  .ler-screen.ler-outline:before {
    content: "\eac0";
  }
  
  .ler-scroll.ler-outline:before {
    content: "\eac1";
  }
  
  .ler-search-list.ler-outline:before {
    content: "\eac2";
  }
  
  .ler-security-check.ler-outline:before {
    content: "\eac3";
  }
  
  .ler-security-user.ler-outline:before {
    content: "\eac4";
  }
  
  .ler-send.ler-outline:before {
    content: "\eac5";
  }
  
  .ler-setting-2.ler-outline:before {
    content: "\eac6";
  }
  
  .ler-setting-3.ler-outline:before {
    content: "\eac7";
  }
  
  .ler-setting-4.ler-outline:before {
    content: "\eac8";
  }
  
  .ler-setting.ler-outline:before {
    content: "\eac9";
  }
  
  .ler-share.ler-outline:before {
    content: "\eaca";
  }
  
  .ler-shield-cross.ler-outline:before {
    content: "\eacb";
  }
  
  .ler-shield-search.ler-outline:before {
    content: "\eacc";
  }
  
  .ler-shield-slash.ler-outline:before {
    content: "\eacd";
  }
  
  .ler-shield-tick.ler-outline:before {
    content: "\eace";
  }
  
  .ler-shield.ler-outline:before {
    content: "\eacf";
  }
  
  .ler-ship.ler-outline:before {
    content: "\ead0";
  }
  
  .ler-shop.ler-outline:before {
    content: "\ead1";
  }
  
  .ler-simcard-2.ler-outline:before {
    content: "\ead2";
  }
  
  .ler-simcard.ler-outline:before {
    content: "\ead3";
  }
  
  .ler-size.ler-outline:before {
    content: "\ead4";
  }
  
  .ler-slack.ler-outline:before {
    content: "\ead5";
  }
  
  .ler-slider-horizontal-2.ler-outline:before {
    content: "\ead6";
  }
  
  .ler-slider-horizontal.ler-outline:before {
    content: "\ead7";
  }
  
  .ler-slider-vertical-2.ler-outline:before {
    content: "\ead8";
  }
  
  .ler-slider-vertical.ler-outline:before {
    content: "\ead9";
  }
  
  .ler-slider.ler-outline:before {
    content: "\eada";
  }
  
  .ler-sms.ler-outline:before {
    content: "\eadb";
  }
  
  .ler-snapchat.ler-outline:before {
    content: "\eadc";
  }
  
  .ler-social-media.ler-outline:before {
    content: "\eadd";
  }
  
  .ler-soft-2.ler-outline:before {
    content: "\eade";
  }
  
  .ler-soft-3.ler-outline:before {
    content: "\eadf";
  }
  
  .ler-soft.ler-outline:before {
    content: "\eae0";
  }
  
  .ler-some-files.ler-outline:before {
    content: "\eae1";
  }
  
  .ler-sort.ler-outline:before {
    content: "\eae2";
  }
  
  .ler-speaker.ler-outline:before {
    content: "\eae3";
  }
  
  .ler-spotify.ler-outline:before {
    content: "\eae4";
  }
  
  .ler-spring-framework.ler-outline:before {
    content: "\eae5";
  }
  
  .ler-square-brackets.ler-outline:before {
    content: "\eae6";
  }
  
  .ler-star.ler-outline:before {
    content: "\eae7";
  }
  
  .ler-status.ler-outline:before {
    content: "\eae8";
  }
  
  .ler-subtitle.ler-outline:before {
    content: "\eae9";
  }
  
  .ler-sun.ler-outline:before {
    content: "\eaea";
  }
  
  .ler-support-24.ler-outline:before {
    content: "\eaeb";
  }
  
  .ler-switch.ler-outline:before {
    content: "\eaec";
  }
  
  .ler-syringe.ler-outline:before {
    content: "\eaed";
  }
  
  .ler-tablet-book.ler-outline:before {
    content: "\eaee";
  }
  
  .ler-tablet-delete.ler-outline:before {
    content: "\eaef";
  }
  
  .ler-tablet-down.ler-outline:before {
    content: "\eaf0";
  }
  
  .ler-tablet-ok.ler-outline:before {
    content: "\eaf1";
  }
  
  .ler-tablet-text-down.ler-outline:before {
    content: "\eaf2";
  }
  
  .ler-tablet-text-up.ler-outline:before {
    content: "\eaf3";
  }
  
  .ler-tablet-up.ler-outline:before {
    content: "\eaf4";
  }
  
  .ler-tablet.ler-outline:before {
    content: "\eaf5";
  }
  
  .ler-tag-cross.ler-outline:before {
    content: "\eaf6";
  }
  
  .ler-tag.ler-outline:before {
    content: "\eaf7";
  }
  
  .ler-teacher.ler-outline:before {
    content: "\eaf8";
  }
  
  .ler-tech-wifi.ler-outline:before {
    content: "\eaf9";
  }
  
  .ler-technology-2.ler-outline:before {
    content: "\eafa";
  }
  
  .ler-technology-3.ler-outline:before {
    content: "\eafb";
  }
  
  .ler-technology-4.ler-outline:before {
    content: "\eafc";
  }
  
  .ler-technology.ler-outline:before {
    content: "\eafd";
  }
  
  .ler-telephone-geolocation.ler-outline:before {
    content: "\eafe";
  }
  
  .ler-test-tubes.ler-outline:before {
    content: "\eaff";
  }
  
  .ler-text-align-center.ler-outline:before {
    content: "\eb00";
  }
  
  .ler-text-align-justify-center.ler-outline:before {
    content: "\eb01";
  }
  
  .ler-text-align-left.ler-outline:before {
    content: "\eb02";
  }
  
  .ler-text-align-right.ler-outline:before {
    content: "\eb03";
  }
  
  .ler-text-bold.ler-outline:before {
    content: "\eb04";
  }
  
  .ler-text-circle.ler-outline:before {
    content: "\eb05";
  }
  
  .ler-text-italic.ler-outline:before {
    content: "\eb06";
  }
  
  .ler-text-number.ler-outline:before {
    content: "\eb07";
  }
  
  .ler-text-strikethrough.ler-outline:before {
    content: "\eb08";
  }
  
  .ler-text-underline.ler-outline:before {
    content: "\eb09";
  }
  
  .ler-text.ler-outline:before {
    content: "\eb0a";
  }
  
  .ler-thermometer.ler-outline:before {
    content: "\eb0b";
  }
  
  .ler-theta.ler-outline:before {
    content: "\eb0c";
  }
  
  .ler-tiktok.ler-outline:before {
    content: "\eb0d";
  }
  
  .ler-time.ler-outline:before {
    content: "\eb0e";
  }
  
  .ler-timer.ler-outline:before {
    content: "\eb0f";
  }
  
  .ler-to-left.ler-outline:before {
    content: "\eb10";
  }
  
  .ler-to-right.ler-outline:before {
    content: "\eb11";
  }
  
  .ler-toggle-off-circle.ler-outline:before {
    content: "\eb12";
  }
  
  .ler-toggle-off.ler-outline:before {
    content: "\eb13";
  }
  
  .ler-toggle-on-circle.ler-outline:before {
    content: "\eb14";
  }
  
  .ler-toggle-on.ler-outline:before {
    content: "\eb15";
  }
  
  .ler-trailer.ler-outline:before {
    content: "\eb16";
  }
  
  .ler-trash-square.ler-outline:before {
    content: "\eb17";
  }
  
  .ler-trash.ler-outline:before {
    content: "\eb18";
  }
  
  .ler-tree.ler-outline:before {
    content: "\eb19";
  }
  
  .ler-trello.ler-outline:before {
    content: "\eb1a";
  }
  
  .ler-triangle.ler-outline:before {
    content: "\eb1b";
  }
  
  .ler-truck.ler-outline:before {
    content: "\eb1c";
  }
  
  .ler-ts.ler-outline:before {
    content: "\eb1d";
  }
  
  .ler-twitch.ler-outline:before {
    content: "\eb1e";
  }
  
  .ler-twitter.ler-outline:before {
    content: "\eb1f";
  }
  
  .ler-two-credit-cart.ler-outline:before {
    content: "\eb20";
  }
  
  .ler-underlining.ler-outline:before {
    content: "\eb21";
  }
  
  .ler-up-down.ler-outline:before {
    content: "\eb22";
  }
  
  .ler-up-square.ler-outline:before {
    content: "\eb23";
  }
  
  .ler-up.ler-outline:before {
    content: "\eb24";
  }
  
  .ler-update-file.ler-outline:before {
    content: "\eb25";
  }
  
  .ler-update-folder.ler-outline:before {
    content: "\eb26";
  }
  
  .ler-user-edit.ler-outline:before {
    content: "\eb27";
  }
  
  .ler-user-square.ler-outline:before {
    content: "\eb28";
  }
  
  .ler-user-tick.ler-outline:before {
    content: "\eb29";
  }
  
  .ler-user.ler-outline:before {
    content: "\eb2a";
  }
  
  .ler-verify.ler-outline:before {
    content: "\eb2b";
  }
  
  .ler-vibe.ler-outline:before {
    content: "\eb2c";
  }
  
  .ler-virus.ler-outline:before {
    content: "\eb2d";
  }
  
  .ler-vue.ler-outline:before {
    content: "\eb2e";
  }
  
  .ler-vuesax.ler-outline:before {
    content: "\eb2f";
  }
  
  .ler-wallet.ler-outline:before {
    content: "\eb30";
  }
  
  .ler-wanchain.ler-outline:before {
    content: "\eb31";
  }
  
  .ler-watch.ler-outline:before {
    content: "\eb32";
  }
  
  .ler-whatsapp.ler-outline:before {
    content: "\eb33";
  }
  
  .ler-wifi-home.ler-outline:before {
    content: "\eb34";
  }
  
  .ler-wifi-square.ler-outline:before {
    content: "\eb35";
  }
  
  .ler-wifi.ler-outline:before {
    content: "\eb36";
  }
  
  .ler-wrench.ler-outline:before {
    content: "\eb37";
  }
  
  .ler-xaomi.ler-outline:before {
    content: "\eb38";
  }
  
  .ler-xd.ler-outline:before {
    content: "\eb39";
  }
  
  .ler-xmr.ler-outline:before {
    content: "\eb3a";
  }
  
  .ler-yii.ler-outline:before {
    content: "\eb3b";
  }
  
  .ler-youtube.ler-outline:before {
    content: "\eb3c";
  }
  
  @font-face {
    font-family: "keenicons-solid";
    src: url("assets/fonts/keenicons/keenicons-solid.eot?812fv7");
    src: url("assets/fonts/keenicons/keenicons-solid.eot?812fv7#iefix") format("embedded-opentype"), url("assets/fonts/keenicons/keenicons-solid.ttf?812fv7") format("truetype"), url("assets/fonts/keenicons/keenicons-solid.woff?812fv7") format("woff"), url("assets/fonts/keenicons/keenicons-solid.svg?812fv7#keenicons-solid") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  .ler-solid {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "keenicons-solid" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ler-abstract-1.ler-solid:before {
    content: "\e900";
  }
  
  .ler-abstract-2.ler-solid:before {
    content: "\e901";
  }
  
  .ler-abstract-3.ler-solid:before {
    content: "\e902";
  }
  
  .ler-abstract-4.ler-solid:before {
    content: "\e903";
  }
  
  .ler-abstract-5.ler-solid:before {
    content: "\e904";
  }
  
  .ler-abstract-6.ler-solid:before {
    content: "\e905";
  }
  
  .ler-abstract-7.ler-solid:before {
    content: "\e906";
  }
  
  .ler-abstract-8.ler-solid:before {
    content: "\e907";
  }
  
  .ler-abstract-9.ler-solid:before {
    content: "\e908";
  }
  
  .ler-abstract-10.ler-solid:before {
    content: "\e909";
  }
  
  .ler-abstract-11.ler-solid:before {
    content: "\e90a";
  }
  
  .ler-abstract-12.ler-solid:before {
    content: "\e90b";
  }
  
  .ler-abstract-13.ler-solid:before {
    content: "\e90c";
  }
  
  .ler-abstract-14.ler-solid:before {
    content: "\e90d";
  }
  
  .ler-abstract-15.ler-solid:before {
    content: "\e90e";
  }
  
  .ler-abstract-16.ler-solid:before {
    content: "\e90f";
  }
  
  .ler-abstract-17.ler-solid:before {
    content: "\e910";
  }
  
  .ler-abstract-18.ler-solid:before {
    content: "\e911";
  }
  
  .ler-abstract-19.ler-solid:before {
    content: "\e912";
  }
  
  .ler-abstract-20.ler-solid:before {
    content: "\e913";
  }
  
  .ler-abstract-21.ler-solid:before {
    content: "\e914";
  }
  
  .ler-abstract-22.ler-solid:before {
    content: "\e915";
  }
  
  .ler-abstract-23.ler-solid:before {
    content: "\e916";
  }
  
  .ler-abstract-24.ler-solid:before {
    content: "\e917";
  }
  
  .ler-abstract-25.ler-solid:before {
    content: "\e918";
  }
  
  .ler-abstract-26.ler-solid:before {
    content: "\e919";
  }
  
  .ler-abstract-27.ler-solid:before {
    content: "\e91a";
  }
  
  .ler-abstract-28.ler-solid:before {
    content: "\e91b";
  }
  
  .ler-abstract-29.ler-solid:before {
    content: "\e91c";
  }
  
  .ler-abstract-30.ler-solid:before {
    content: "\e91d";
  }
  
  .ler-abstract-31.ler-solid:before {
    content: "\e91e";
  }
  
  .ler-abstract-32.ler-solid:before {
    content: "\e91f";
  }
  
  .ler-abstract-33.ler-solid:before {
    content: "\e920";
  }
  
  .ler-abstract-34.ler-solid:before {
    content: "\e921";
  }
  
  .ler-abstract-35.ler-solid:before {
    content: "\e922";
  }
  
  .ler-abstract-36.ler-solid:before {
    content: "\e923";
  }
  
  .ler-abstract-37.ler-solid:before {
    content: "\e924";
  }
  
  .ler-abstract-38.ler-solid:before {
    content: "\e925";
  }
  
  .ler-abstract-39.ler-solid:before {
    content: "\e926";
  }
  
  .ler-abstract-40.ler-solid:before {
    content: "\e927";
  }
  
  .ler-abstract-41.ler-solid:before {
    content: "\e928";
  }
  
  .ler-abstract-42.ler-solid:before {
    content: "\e929";
  }
  
  .ler-abstract-43.ler-solid:before {
    content: "\e92a";
  }
  
  .ler-abstract-44.ler-solid:before {
    content: "\e92b";
  }
  
  .ler-abstract-45.ler-solid:before {
    content: "\e92c";
  }
  
  .ler-abstract-46.ler-solid:before {
    content: "\e92d";
  }
  
  .ler-abstract-47.ler-solid:before {
    content: "\e92e";
  }
  
  .ler-abstract-48.ler-solid:before {
    content: "\e92f";
  }
  
  .ler-abstract-49.ler-solid:before {
    content: "\e930";
  }
  
  .ler-abstract.ler-solid:before {
    content: "\e931";
  }
  
  .ler-add-files.ler-solid:before {
    content: "\e932";
  }
  
  .ler-add-folder.ler-solid:before {
    content: "\e933";
  }
  
  .ler-add-item.ler-solid:before {
    content: "\e934";
  }
  
  .ler-add-notepad.ler-solid:before {
    content: "\e935";
  }
  
  .ler-address-book.ler-solid:before {
    content: "\e936";
  }
  
  .ler-airplane-square.ler-solid:before {
    content: "\e937";
  }
  
  .ler-airplane.ler-solid:before {
    content: "\e938";
  }
  
  .ler-airpod.ler-solid:before {
    content: "\e939";
  }
  
  .ler-android.ler-solid:before {
    content: "\e93a";
  }
  
  .ler-angular.ler-solid:before {
    content: "\e93b";
  }
  
  .ler-apple.ler-solid:before {
    content: "\e93c";
  }
  
  .ler-archive-tick.ler-solid:before {
    content: "\e93d";
  }
  
  .ler-archive.ler-solid:before {
    content: "\e93e";
  }
  
  .ler-arrow-circle-left.ler-solid:before {
    content: "\e93f";
  }
  
  .ler-arrow-circle-right.ler-solid:before {
    content: "\e940";
  }
  
  .ler-arrow-diagonal.ler-solid:before {
    content: "\e941";
  }
  
  .ler-arrow-down-left.ler-solid:before {
    content: "\e942";
  }
  
  .ler-arrow-down-refraction.ler-solid:before {
    content: "\e943";
  }
  
  .ler-arrow-down-right.ler-solid:before {
    content: "\e944";
  }
  
  .ler-arrow-down.ler-solid:before {
    content: "\e945";
  }
  
  .ler-arrow-left.ler-solid:before {
    content: "\e946";
  }
  
  .ler-arrow-mix.ler-solid:before {
    content: "\e947";
  }
  
  .ler-arrow-right-left.ler-solid:before {
    content: "\e948";
  }
  
  .ler-arrow-right.ler-solid:before {
    content: "\e949";
  }
  
  .ler-arrow-two-diagonals.ler-solid:before {
    content: "\e94a";
  }
  
  .ler-arrow-up-down.ler-solid:before {
    content: "\e94b";
  }
  
  .ler-arrow-up-left.ler-solid:before {
    content: "\e94c";
  }
  
  .ler-arrow-up-refraction.ler-solid:before {
    content: "\e94d";
  }
  
  .ler-arrow-up-right.ler-solid:before {
    content: "\e94e";
  }
  
  .ler-arrow-up.ler-solid:before {
    content: "\e94f";
  }
  
  .ler-arrow-zigzag.ler-solid:before {
    content: "\e950";
  }
  
  .ler-arrows-circle.ler-solid:before {
    content: "\e951";
  }
  
  .ler-arrows-loop.ler-solid:before {
    content: "\e952";
  }
  
  .ler-artificial-intelligence.ler-solid:before {
    content: "\e953";
  }
  
  .ler-auto-brightness.ler-solid:before {
    content: "\e954";
  }
  
  .ler-avalanche.ler-solid:before {
    content: "\e955";
  }
  
  .ler-award.ler-solid:before {
    content: "\e956";
  }
  
  .ler-badge.ler-solid:before {
    content: "\e957";
  }
  
  .ler-bandage.ler-solid:before {
    content: "\e958";
  }
  
  .ler-bank.ler-solid:before {
    content: "\e959";
  }
  
  .ler-barcode.ler-solid:before {
    content: "\e95a";
  }
  
  .ler-basket-ok.ler-solid:before {
    content: "\e95b";
  }
  
  .ler-basket.ler-solid:before {
    content: "\e95c";
  }
  
  .ler-behance.ler-solid:before {
    content: "\e95d";
  }
  
  .ler-bill.ler-solid:before {
    content: "\e95e";
  }
  
  .ler-binance-usd.ler-solid:before {
    content: "\e95f";
  }
  
  .ler-binance.ler-solid:before {
    content: "\e960";
  }
  
  .ler-bitcoin.ler-solid:before {
    content: "\e961";
  }
  
  .ler-black-down.ler-solid:before {
    content: "\e962";
  }
  
  .ler-black-left-line.ler-solid:before {
    content: "\e963";
  }
  
  .ler-black-left.ler-solid:before {
    content: "\e964";
  }
  
  .ler-black-right-line.ler-solid:before {
    content: "\e965";
  }
  
  .ler-black-right.ler-solid:before {
    content: "\e966";
  }
  
  .ler-black-up.ler-solid:before {
    content: "\e967";
  }
  
  .ler-bluetooth.ler-solid:before {
    content: "\e968";
  }
  
  .ler-book-open.ler-solid:before {
    content: "\e969";
  }
  
  .ler-book-square.ler-solid:before {
    content: "\e96a";
  }
  
  .ler-book.ler-solid:before {
    content: "\e96b";
  }
  
  .ler-bookmark-2.ler-solid:before {
    content: "\e96c";
  }
  
  .ler-bookmark.ler-solid:before {
    content: "\e96d";
  }
  
  .ler-bootstrap.ler-solid:before {
    content: "\e96e";
  }
  
  .ler-briefcase.ler-solid:before {
    content: "\e96f";
  }
  
  .ler-brifecase-cros.ler-solid:before {
    content: "\e970";
  }
  
  .ler-brifecase-tick.ler-solid:before {
    content: "\e971";
  }
  
  .ler-brifecase-timer.ler-solid:before {
    content: "\e972";
  }
  
  .ler-brush.ler-solid:before {
    content: "\e973";
  }
  
  .ler-bucket-square.ler-solid:before {
    content: "\e974";
  }
  
  .ler-bucket.ler-solid:before {
    content: "\e975";
  }
  
  .ler-burger-menu-1.ler-solid:before {
    content: "\e976";
  }
  
  .ler-burger-menu-2.ler-solid:before {
    content: "\e977";
  }
  
  .ler-burger-menu-3.ler-solid:before {
    content: "\e978";
  }
  
  .ler-burger-menu-4.ler-solid:before {
    content: "\e979";
  }
  
  .ler-burger-menu-5.ler-solid:before {
    content: "\e97a";
  }
  
  .ler-burger-menu-6.ler-solid:before {
    content: "\e97b";
  }
  
  .ler-burger-menu.ler-solid:before {
    content: "\e97c";
  }
  
  .ler-bus.ler-solid:before {
    content: "\e97d";
  }
  
  .ler-calculator.ler-solid:before {
    content: "\e97e";
  }
  
  .ler-calendar-2.ler-solid:before {
    content: "\e97f";
  }
  
  .ler-calendar-8.ler-solid:before {
    content: "\e980";
  }
  
  .ler-calendar-add.ler-solid:before {
    content: "\e981";
  }
  
  .ler-calendar-edit.ler-solid:before {
    content: "\e982";
  }
  
  .ler-calendar-remove.ler-solid:before {
    content: "\e983";
  }
  
  .ler-calendar-search.ler-solid:before {
    content: "\e984";
  }
  
  .ler-calendar-tick.ler-solid:before {
    content: "\e985";
  }
  
  .ler-calendar.ler-solid:before {
    content: "\e986";
  }
  
  .ler-call.ler-solid:before {
    content: "\e987";
  }
  
  .ler-capsule.ler-solid:before {
    content: "\e988";
  }
  
  .ler-car-2.ler-solid:before {
    content: "\e989";
  }
  
  .ler-car-3.ler-solid:before {
    content: "\e98a";
  }
  
  .ler-car.ler-solid:before {
    content: "\e98b";
  }
  
  .ler-category.ler-solid:before {
    content: "\e98c";
  }
  
  .ler-cd.ler-solid:before {
    content: "\e98d";
  }
  
  .ler-celsius.ler-solid:before {
    content: "\e98e";
  }
  
  .ler-chart-line-down-2.ler-solid:before {
    content: "\e98f";
  }
  
  .ler-chart-line-down.ler-solid:before {
    content: "\e990";
  }
  
  .ler-chart-line-star.ler-solid:before {
    content: "\e991";
  }
  
  .ler-chart-line-up-2.ler-solid:before {
    content: "\e992";
  }
  
  .ler-chart-line-up.ler-solid:before {
    content: "\e993";
  }
  
  .ler-chart-line.ler-solid:before {
    content: "\e994";
  }
  
  .ler-chart-pie-3.ler-solid:before {
    content: "\e995";
  }
  
  .ler-chart-pie-4.ler-solid:before {
    content: "\e996";
  }
  
  .ler-chart-pie-simple.ler-solid:before {
    content: "\e997";
  }
  
  .ler-chart-pie-too.ler-solid:before {
    content: "\e998";
  }
  
  .ler-chart-simple-2.ler-solid:before {
    content: "\e999";
  }
  
  .ler-chart-simple-3.ler-solid:before {
    content: "\e99a";
  }
  
  .ler-chart-simple.ler-solid:before {
    content: "\e99b";
  }
  
  .ler-chart.ler-solid:before {
    content: "\e99c";
  }
  
  .ler-check-circle.ler-solid:before {
    content: "\e99d";
  }
  
  .ler-check-square.ler-solid:before {
    content: "\e99e";
  }
  
  .ler-check.ler-solid:before {
    content: "\e99f";
  }
  
  .ler-cheque.ler-solid:before {
    content: "\e9a0";
  }
  
  .ler-chrome.ler-solid:before {
    content: "\e9a1";
  }
  
  .ler-classmates.ler-solid:before {
    content: "\e9a2";
  }
  
  .ler-click.ler-solid:before {
    content: "\e9a3";
  }
  
  .ler-clipboard.ler-solid:before {
    content: "\e9a4";
  }
  
  .ler-cloud-add.ler-solid:before {
    content: "\e9a5";
  }
  
  .ler-cloud-change.ler-solid:before {
    content: "\e9a6";
  }
  
  .ler-cloud-download.ler-solid:before {
    content: "\e9a7";
  }
  
  .ler-cloud.ler-solid:before {
    content: "\e9a8";
  }
  
  .ler-code.ler-solid:before {
    content: "\e9a9";
  }
  
  .ler-coffee.ler-solid:before {
    content: "\e9aa";
  }
  
  .ler-color-swatch.ler-solid:before {
    content: "\e9ab";
  }
  
  .ler-colors-square.ler-solid:before {
    content: "\e9ac";
  }
  
  .ler-compass.ler-solid:before {
    content: "\e9ad";
  }
  
  .ler-copy-success.ler-solid:before {
    content: "\e9ae";
  }
  
  .ler-copy.ler-solid:before {
    content: "\e9af";
  }
  
  .ler-courier-express.ler-solid:before {
    content: "\e9b0";
  }
  
  .ler-courier.ler-solid:before {
    content: "\e9b1";
  }
  
  .ler-credit-cart.ler-solid:before {
    content: "\e9b2";
  }
  
  .ler-cross-circle.ler-solid:before {
    content: "\e9b3";
  }
  
  .ler-cross-square.ler-solid:before {
    content: "\e9b4";
  }
  
  .ler-cross.ler-solid:before {
    content: "\e9b5";
  }
  
  .ler-crown-2.ler-solid:before {
    content: "\e9b6";
  }
  
  .ler-crown.ler-solid:before {
    content: "\e9b7";
  }
  
  .ler-css.ler-solid:before {
    content: "\e9b8";
  }
  
  .ler-cube-2.ler-solid:before {
    content: "\e9b9";
  }
  
  .ler-cube-3.ler-solid:before {
    content: "\e9ba";
  }
  
  .ler-cup.ler-solid:before {
    content: "\e9bb";
  }
  
  .ler-dash.ler-solid:before {
    content: "\e9bc";
  }
  
  .ler-data.ler-solid:before {
    content: "\e9bd";
  }
  
  .ler-delete-files.ler-solid:before {
    content: "\e9be";
  }
  
  .ler-delete-folder.ler-solid:before {
    content: "\e9bf";
  }
  
  .ler-delivery-2.ler-solid:before {
    content: "\e9c0";
  }
  
  .ler-delivery-3.ler-solid:before {
    content: "\e9c1";
  }
  
  .ler-delivery-24.ler-solid:before {
    content: "\e9c2";
  }
  
  .ler-delivery-door.ler-solid:before {
    content: "\e9c3";
  }
  
  .ler-delivery-geolocation.ler-solid:before {
    content: "\e9c4";
  }
  
  .ler-delivery-time.ler-solid:before {
    content: "\e9c5";
  }
  
  .ler-delivery.ler-solid:before {
    content: "\e9c6";
  }
  
  .ler-design-2.ler-solid:before {
    content: "\e9c7";
  }
  
  .ler-design-frame.ler-solid:before {
    content: "\e9c8";
  }
  
  .ler-design-mask.ler-solid:before {
    content: "\e9c9";
  }
  
  .ler-design.ler-solid:before {
    content: "\e9ca";
  }
  
  .ler-devices-2.ler-solid:before {
    content: "\e9cb";
  }
  
  .ler-devices.ler-solid:before {
    content: "\e9cc";
  }
  
  .ler-diamonds.ler-solid:before {
    content: "\e9cd";
  }
  
  .ler-directbox-default.ler-solid:before {
    content: "\e9ce";
  }
  
  .ler-disconnect.ler-solid:before {
    content: "\e9cf";
  }
  
  .ler-discount.ler-solid:before {
    content: "\e9d0";
  }
  
  .ler-disk.ler-solid:before {
    content: "\e9d1";
  }
  
  .ler-dislike.ler-solid:before {
    content: "\e9d2";
  }
  
  .ler-dj.ler-solid:before {
    content: "\e9d3";
  }
  
  .ler-document.ler-solid:before {
    content: "\e9d4";
  }
  
  .ler-dollar.ler-solid:before {
    content: "\e9d5";
  }
  
  .ler-dots-circle-vertical.ler-solid:before {
    content: "\e9d6";
  }
  
  .ler-dots-circle.ler-solid:before {
    content: "\e9d7";
  }
  
  .ler-dots-horizontal.ler-solid:before {
    content: "\e9d8";
  }
  
  .ler-dots-square-vertical.ler-solid:before {
    content: "\e9d9";
  }
  
  .ler-dots-square.ler-solid:before {
    content: "\e9da";
  }
  
  .ler-dots-vertical.ler-solid:before {
    content: "\e9db";
  }
  
  .ler-double-check-circle.ler-solid:before {
    content: "\e9dc";
  }
  
  .ler-double-check.ler-solid:before {
    content: "\e9dd";
  }
  
  .ler-double-down.ler-solid:before {
    content: "\e9de";
  }
  
  .ler-double-left-arrow.ler-solid:before {
    content: "\e9df";
  }
  
  .ler-double-left.ler-solid:before {
    content: "\e9e0";
  }
  
  .ler-double-right-arrow.ler-solid:before {
    content: "\e9e1";
  }
  
  .ler-double-right.ler-solid:before {
    content: "\e9e2";
  }
  
  .ler-double-up.ler-solid:before {
    content: "\e9e3";
  }
  
  .ler-down-square.ler-solid:before {
    content: "\e9e4";
  }
  
  .ler-down.ler-solid:before {
    content: "\e9e5";
  }
  
  .ler-dribbble.ler-solid:before {
    content: "\e9e6";
  }
  
  .ler-drop.ler-solid:before {
    content: "\e9e7";
  }
  
  .ler-dropbox.ler-solid:before {
    content: "\e9e8";
  }
  
  .ler-educare.ler-solid:before {
    content: "\e9e9";
  }
  
  .ler-electricity.ler-solid:before {
    content: "\e9ea";
  }
  
  .ler-electronic-clock.ler-solid:before {
    content: "\e9eb";
  }
  
  .ler-element-1.ler-solid:before {
    content: "\e9ec";
  }
  
  .ler-element-2.ler-solid:before {
    content: "\e9ed";
  }
  
  .ler-element-3.ler-solid:before {
    content: "\e9ee";
  }
  
  .ler-element-4.ler-solid:before {
    content: "\e9ef";
  }
  
  .ler-element-5.ler-solid:before {
    content: "\e9f0";
  }
  
  .ler-element-6.ler-solid:before {
    content: "\e9f1";
  }
  
  .ler-element-7.ler-solid:before {
    content: "\e9f2";
  }
  
  .ler-element-8.ler-solid:before {
    content: "\e9f3";
  }
  
  .ler-element-9.ler-solid:before {
    content: "\e9f4";
  }
  
  .ler-element-10.ler-solid:before {
    content: "\e9f5";
  }
  
  .ler-element-11.ler-solid:before {
    content: "\e9f6";
  }
  
  .ler-element-12.ler-solid:before {
    content: "\e9f7";
  }
  
  .ler-element-equal.ler-solid:before {
    content: "\e9f8";
  }
  
  .ler-element-plus.ler-solid:before {
    content: "\e9f9";
  }
  
  .ler-emoji-happy.ler-solid:before {
    content: "\e9fa";
  }
  
  .ler-enjin-coin.ler-solid:before {
    content: "\e9fb";
  }
  
  .ler-entrance-left.ler-solid:before {
    content: "\e9fc";
  }
  
  .ler-entrance-right.ler-solid:before {
    content: "\e9fd";
  }
  
  .ler-eraser.ler-solid:before {
    content: "\e9fe";
  }
  
  .ler-euro.ler-solid:before {
    content: "\e9ff";
  }
  
  .ler-exit-down.ler-solid:before {
    content: "\ea00";
  }
  
  .ler-exit-left.ler-solid:before {
    content: "\ea01";
  }
  
  .ler-exit-right-corner.ler-solid:before {
    content: "\ea02";
  }
  
  .ler-exit-right.ler-solid:before {
    content: "\ea03";
  }
  
  .ler-exit-up.ler-solid:before {
    content: "\ea04";
  }
  
  .ler-external-drive.ler-solid:before {
    content: "\ea05";
  }
  
  .ler-eye-slash.ler-solid:before {
    content: "\ea06";
  }
  
  .ler-eye.ler-solid:before {
    content: "\ea07";
  }
  
  .ler-facebook.ler-solid:before {
    content: "\ea08";
  }
  
  .ler-faceid.ler-solid:before {
    content: "\ea09";
  }
  
  .ler-fasten.ler-solid:before {
    content: "\ea0a";
  }
  
  .ler-fat-rows.ler-solid:before {
    content: "\ea0b";
  }
  
  .ler-feather.ler-solid:before {
    content: "\ea0c";
  }
  
  .ler-figma.ler-solid:before {
    content: "\ea0d";
  }
  
  .ler-file-added.ler-solid:before {
    content: "\ea0e";
  }
  
  .ler-file-deleted.ler-solid:before {
    content: "\ea0f";
  }
  
  .ler-file-down.ler-solid:before {
    content: "\ea10";
  }
  
  .ler-file-left.ler-solid:before {
    content: "\ea11";
  }
  
  .ler-file-right.ler-solid:before {
    content: "\ea12";
  }
  
  .ler-file-sheet.ler-solid:before {
    content: "\ea13";
  }
  
  .ler-file-up.ler-solid:before {
    content: "\ea14";
  }
  
  .ler-file.ler-solid:before {
    content: "\ea15";
  }
  
  .ler-files-tablet.ler-solid:before {
    content: "\ea16";
  }
  
  .ler-filter-edit.ler-solid:before {
    content: "\ea17";
  }
  
  .ler-filter-search.ler-solid:before {
    content: "\ea18";
  }
  
  .ler-filter-square.ler-solid:before {
    content: "\ea19";
  }
  
  .ler-filter-tablet.ler-solid:before {
    content: "\ea1a";
  }
  
  .ler-filter-tick.ler-solid:before {
    content: "\ea1b";
  }
  
  .ler-filter.ler-solid:before {
    content: "\ea1c";
  }
  
  .ler-finance-calculator.ler-solid:before {
    content: "\ea1d";
  }
  
  .ler-financial-schedule.ler-solid:before {
    content: "\ea1e";
  }
  
  .ler-fingerprint-scanning.ler-solid:before {
    content: "\ea1f";
  }
  
  .ler-flag.ler-solid:before {
    content: "\ea20";
  }
  
  .ler-flash-circle.ler-solid:before {
    content: "\ea21";
  }
  
  .ler-flask.ler-solid:before {
    content: "\ea22";
  }
  
  .ler-focus.ler-solid:before {
    content: "\ea23";
  }
  
  .ler-folder-added.ler-solid:before {
    content: "\ea24";
  }
  
  .ler-folder-down.ler-solid:before {
    content: "\ea25";
  }
  
  .ler-folder-up.ler-solid:before {
    content: "\ea26";
  }
  
  .ler-folder.ler-solid:before {
    content: "\ea27";
  }
  
  .ler-frame.ler-solid:before {
    content: "\ea28";
  }
  
  .ler-gear.ler-solid:before {
    content: "\ea29";
  }
  
  .ler-general-mouse.ler-solid:before {
    content: "\ea2a";
  }
  
  .ler-geolocation-home.ler-solid:before {
    content: "\ea2b";
  }
  
  .ler-geolocation.ler-solid:before {
    content: "\ea2c";
  }
  
  .ler-ghost.ler-solid:before {
    content: "\ea2d";
  }
  
  .ler-gift.ler-solid:before {
    content: "\ea2e";
  }
  
  .ler-github.ler-solid:before {
    content: "\ea2f";
  }
  
  .ler-glass.ler-solid:before {
    content: "\ea30";
  }
  
  .ler-google-play.ler-solid:before {
    content: "\ea31";
  }
  
  .ler-google.ler-solid:before {
    content: "\ea32";
  }
  
  .ler-graph-2.ler-solid:before {
    content: "\ea33";
  }
  
  .ler-graph-3.ler-solid:before {
    content: "\ea34";
  }
  
  .ler-graph-4.ler-solid:before {
    content: "\ea35";
  }
  
  .ler-graph-up.ler-solid:before {
    content: "\ea36";
  }
  
  .ler-graph.ler-solid:before {
    content: "\ea37";
  }
  
  .ler-grid-2.ler-solid:before {
    content: "\ea38";
  }
  
  .ler-grid-frame.ler-solid:before {
    content: "\ea39";
  }
  
  .ler-grid.ler-solid:before {
    content: "\ea3a";
  }
  
  .ler-handcart.ler-solid:before {
    content: "\ea3b";
  }
  
  .ler-happy-emoji.ler-solid:before {
    content: "\ea3c";
  }
  
  .ler-heart-circle.ler-solid:before {
    content: "\ea3d";
  }
  
  .ler-heart.ler-solid:before {
    content: "\ea3e";
  }
  
  .ler-home-1.ler-solid:before {
    content: "\ea3f";
  }
  
  .ler-home-2.ler-solid:before {
    content: "\ea40";
  }
  
  .ler-home-3.ler-solid:before {
    content: "\ea41";
  }
  
  .ler-home.ler-solid:before {
    content: "\ea42";
  }
  
  .ler-html.ler-solid:before {
    content: "\ea43";
  }
  
  .ler-icon.ler-solid:before {
    content: "\ea44";
  }
  
  .ler-illustrator.ler-solid:before {
    content: "\ea45";
  }
  
  .ler-information-2.ler-solid:before {
    content: "\ea46";
  }
  
  .ler-information-3.ler-solid:before {
    content: "\ea47";
  }
  
  .ler-information-4.ler-solid:before {
    content: "\ea48";
  }
  
  .ler-information-5.ler-solid:before {
    content: "\ea49";
  }
  
  .ler-information.ler-solid:before {
    content: "\ea4a";
  }
  
  .ler-instagram.ler-solid:before {
    content: "\ea4b";
  }
  
  .ler-joystick.ler-solid:before {
    content: "\ea4c";
  }
  
  .ler-js-2.ler-solid:before {
    content: "\ea4d";
  }
  
  .ler-js.ler-solid:before {
    content: "\ea4e";
  }
  
  .ler-kanban.ler-solid:before {
    content: "\ea4f";
  }
  
  .ler-key-square.ler-solid:before {
    content: "\ea50";
  }
  
  .ler-key.ler-solid:before {
    content: "\ea51";
  }
  
  .ler-keyboard.ler-solid:before {
    content: "\ea52";
  }
  
  .ler-laptop.ler-solid:before {
    content: "\ea53";
  }
  
  .ler-laravel.ler-solid:before {
    content: "\ea54";
  }
  
  .ler-left-square.ler-solid:before {
    content: "\ea55";
  }
  
  .ler-left.ler-solid:before {
    content: "\ea56";
  }
  
  .ler-like-2.ler-solid:before {
    content: "\ea57";
  }
  
  .ler-like-folder.ler-solid:before {
    content: "\ea58";
  }
  
  .ler-like-shapes.ler-solid:before {
    content: "\ea59";
  }
  
  .ler-like-tag.ler-solid:before {
    content: "\ea5a";
  }
  
  .ler-like.ler-solid:before {
    content: "\ea5b";
  }
  
  .ler-loading.ler-solid:before {
    content: "\ea5c";
  }
  
  .ler-lock-2.ler-solid:before {
    content: "\ea5d";
  }
  
  .ler-lock-3.ler-solid:before {
    content: "\ea5e";
  }
  
  .ler-lock.ler-solid:before {
    content: "\ea5f";
  }
  
  .ler-logistic.ler-solid:before {
    content: "\ea60";
  }
  
  .ler-lots-shopping.ler-solid:before {
    content: "\ea61";
  }
  
  .ler-lovely.ler-solid:before {
    content: "\ea62";
  }
  
  .ler-lts.ler-solid:before {
    content: "\ea63";
  }
  
  .ler-magnifier.ler-solid:before {
    content: "\ea64";
  }
  
  .ler-map.ler-solid:before {
    content: "\ea65";
  }
  
  .ler-mask.ler-solid:before {
    content: "\ea66";
  }
  
  .ler-maximize.ler-solid:before {
    content: "\ea67";
  }
  
  .ler-medal-star.ler-solid:before {
    content: "\ea68";
  }
  
  .ler-menu.ler-solid:before {
    content: "\ea69";
  }
  
  .ler-message-add.ler-solid:before {
    content: "\ea6a";
  }
  
  .ler-message-edit.ler-solid:before {
    content: "\ea6b";
  }
  
  .ler-message-minus.ler-solid:before {
    content: "\ea6c";
  }
  
  .ler-message-notif.ler-solid:before {
    content: "\ea6d";
  }
  
  .ler-message-programming.ler-solid:before {
    content: "\ea6e";
  }
  
  .ler-message-question.ler-solid:before {
    content: "\ea6f";
  }
  
  .ler-message-text-2.ler-solid:before {
    content: "\ea70";
  }
  
  .ler-message-text.ler-solid:before {
    content: "\ea71";
  }
  
  .ler-messages.ler-solid:before {
    content: "\ea72";
  }
  
  .ler-microsoft.ler-solid:before {
    content: "\ea73";
  }
  
  .ler-milk.ler-solid:before {
    content: "\ea74";
  }
  
  .ler-minus-circle.ler-solid:before {
    content: "\ea75";
  }
  
  .ler-minus-folder.ler-solid:before {
    content: "\ea76";
  }
  
  .ler-minus-square.ler-solid:before {
    content: "\ea77";
  }
  
  .ler-minus.ler-solid:before {
    content: "\ea78";
  }
  
  .ler-monitor-mobile.ler-solid:before {
    content: "\ea79";
  }
  
  .ler-moon.ler-solid:before {
    content: "\ea7a";
  }
  
  .ler-more-2.ler-solid:before {
    content: "\ea7b";
  }
  
  .ler-mouse-circle.ler-solid:before {
    content: "\ea7c";
  }
  
  .ler-mouse-square.ler-solid:before {
    content: "\ea7d";
  }
  
  .ler-mouse.ler-solid:before {
    content: "\ea7e";
  }
  
  .ler-nexo.ler-solid:before {
    content: "\ea7f";
  }
  
  .ler-night-day.ler-solid:before {
    content: "\ea80";
  }
  
  .ler-note-2.ler-solid:before {
    content: "\ea81";
  }
  
  .ler-note.ler-solid:before {
    content: "\ea82";
  }
  
  .ler-notepad-bookmark.ler-solid:before {
    content: "\ea83";
  }
  
  .ler-notepad-edit.ler-solid:before {
    content: "\ea84";
  }
  
  .ler-notepad.ler-solid:before {
    content: "\ea85";
  }
  
  .ler-notification-2.ler-solid:before {
    content: "\ea86";
  }
  
  .ler-notification-bing.ler-solid:before {
    content: "\ea87";
  }
  
  .ler-notification-circle.ler-solid:before {
    content: "\ea88";
  }
  
  .ler-notification-favorite.ler-solid:before {
    content: "\ea89";
  }
  
  .ler-notification-on.ler-solid:before {
    content: "\ea8a";
  }
  
  .ler-notification-status.ler-solid:before {
    content: "\ea8b";
  }
  
  .ler-notification.ler-solid:before {
    content: "\ea8c";
  }
  
  .ler-ocean.ler-solid:before {
    content: "\ea8d";
  }
  
  .ler-office-bag.ler-solid:before {
    content: "\ea8e";
  }
  
  .ler-package.ler-solid:before {
    content: "\ea8f";
  }
  
  .ler-pails.ler-solid:before {
    content: "\ea90";
  }
  
  .ler-paintbucket.ler-solid:before {
    content: "\ea91";
  }
  
  .ler-paper-clip.ler-solid:before {
    content: "\ea92";
  }
  
  .ler-parcel-tracking.ler-solid:before {
    content: "\ea93";
  }
  
  .ler-parcel.ler-solid:before {
    content: "\ea94";
  }
  
  .ler-password-check.ler-solid:before {
    content: "\ea95";
  }
  
  .ler-paypal.ler-solid:before {
    content: "\ea96";
  }
  
  .ler-pencil.ler-solid:before {
    content: "\ea97";
  }
  
  .ler-people.ler-solid:before {
    content: "\ea98";
  }
  
  .ler-percentage.ler-solid:before {
    content: "\ea99";
  }
  
  .ler-phone.ler-solid:before {
    content: "\ea9a";
  }
  
  .ler-photoshop.ler-solid:before {
    content: "\ea9b";
  }
  
  .ler-picture.ler-solid:before {
    content: "\ea9c";
  }
  
  .ler-pill.ler-solid:before {
    content: "\ea9d";
  }
  
  .ler-pin.ler-solid:before {
    content: "\ea9e";
  }
  
  .ler-plus-circle.ler-solid:before {
    content: "\ea9f";
  }
  
  .ler-plus-square.ler-solid:before {
    content: "\eaa0";
  }
  
  .ler-plus.ler-solid:before {
    content: "\eaa1";
  }
  
  .ler-pointers.ler-solid:before {
    content: "\eaa2";
  }
  
  .ler-price-tag.ler-solid:before {
    content: "\eaa3";
  }
  
  .ler-printer.ler-solid:before {
    content: "\eaa4";
  }
  
  .ler-profile-circle.ler-solid:before {
    content: "\eaa5";
  }
  
  .ler-profile-user.ler-solid:before {
    content: "\eaa6";
  }
  
  .ler-pulse.ler-solid:before {
    content: "\eaa7";
  }
  
  .ler-purchase.ler-solid:before {
    content: "\eaa8";
  }
  
  .ler-python.ler-solid:before {
    content: "\eaa9";
  }
  
  .ler-question-2.ler-solid:before {
    content: "\eaaa";
  }
  
  .ler-question.ler-solid:before {
    content: "\eaab";
  }
  
  .ler-questionnaire-tablet.ler-solid:before {
    content: "\eaac";
  }
  
  .ler-ranking.ler-solid:before {
    content: "\eaad";
  }
  
  .ler-react.ler-solid:before {
    content: "\eaae";
  }
  
  .ler-receipt-square.ler-solid:before {
    content: "\eaaf";
  }
  
  .ler-rescue.ler-solid:before {
    content: "\eab0";
  }
  
  .ler-right-left.ler-solid:before {
    content: "\eab1";
  }
  
  .ler-right-square.ler-solid:before {
    content: "\eab2";
  }
  
  .ler-right.ler-solid:before {
    content: "\eab3";
  }
  
  .ler-rocket.ler-solid:before {
    content: "\eab4";
  }
  
  .ler-route.ler-solid:before {
    content: "\eab5";
  }
  
  .ler-router.ler-solid:before {
    content: "\eab6";
  }
  
  .ler-row-horizontal.ler-solid:before {
    content: "\eab7";
  }
  
  .ler-row-vertical.ler-solid:before {
    content: "\eab8";
  }
  
  .ler-safe-home.ler-solid:before {
    content: "\eab9";
  }
  
  .ler-satellite.ler-solid:before {
    content: "\eaba";
  }
  
  .ler-save-2.ler-solid:before {
    content: "\eabb";
  }
  
  .ler-save-deposit.ler-solid:before {
    content: "\eabc";
  }
  
  .ler-scan-barcode.ler-solid:before {
    content: "\eabd";
  }
  
  .ler-scooter-2.ler-solid:before {
    content: "\eabe";
  }
  
  .ler-scooter.ler-solid:before {
    content: "\eabf";
  }
  
  .ler-screen.ler-solid:before {
    content: "\eac0";
  }
  
  .ler-scroll.ler-solid:before {
    content: "\eac1";
  }
  
  .ler-search-list.ler-solid:before {
    content: "\eac2";
  }
  
  .ler-security-check.ler-solid:before {
    content: "\eac3";
  }
  
  .ler-security-user.ler-solid:before {
    content: "\eac4";
  }
  
  .ler-send.ler-solid:before {
    content: "\eac5";
  }
  
  .ler-setting-2.ler-solid:before {
    content: "\eac6";
  }
  
  .ler-setting-3.ler-solid:before {
    content: "\eac7";
  }
  
  .ler-setting-4.ler-solid:before {
    content: "\eac8";
  }
  
  .ler-setting.ler-solid:before {
    content: "\eac9";
  }
  
  .ler-share.ler-solid:before {
    content: "\eaca";
  }
  
  .ler-shield-cross.ler-solid:before {
    content: "\eacb";
  }
  
  .ler-shield-search.ler-solid:before {
    content: "\eacc";
  }
  
  .ler-shield-slash.ler-solid:before {
    content: "\eacd";
  }
  
  .ler-shield-tick.ler-solid:before {
    content: "\eace";
  }
  
  .ler-shield.ler-solid:before {
    content: "\eacf";
  }
  
  .ler-ship.ler-solid:before {
    content: "\ead0";
  }
  
  .ler-shop.ler-solid:before {
    content: "\ead1";
  }
  
  .ler-simcard-2.ler-solid:before {
    content: "\ead2";
  }
  
  .ler-simcard.ler-solid:before {
    content: "\ead3";
  }
  
  .ler-size.ler-solid:before {
    content: "\ead4";
  }
  
  .ler-slack.ler-solid:before {
    content: "\ead5";
  }
  
  .ler-slider-horizontal-2.ler-solid:before {
    content: "\ead6";
  }
  
  .ler-slider-horizontal.ler-solid:before {
    content: "\ead7";
  }
  
  .ler-slider-vertical-2.ler-solid:before {
    content: "\ead8";
  }
  
  .ler-slider-vertical.ler-solid:before {
    content: "\ead9";
  }
  
  .ler-slider.ler-solid:before {
    content: "\eada";
  }
  
  .ler-sms.ler-solid:before {
    content: "\eadb";
  }
  
  .ler-snapchat.ler-solid:before {
    content: "\eadc";
  }
  
  .ler-social-media.ler-solid:before {
    content: "\eadd";
  }
  
  .ler-soft-2.ler-solid:before {
    content: "\eade";
  }
  
  .ler-soft-3.ler-solid:before {
    content: "\eadf";
  }
  
  .ler-soft.ler-solid:before {
    content: "\eae0";
  }
  
  .ler-some-files.ler-solid:before {
    content: "\eae1";
  }
  
  .ler-sort.ler-solid:before {
    content: "\eae2";
  }
  
  .ler-speaker.ler-solid:before {
    content: "\eae3";
  }
  
  .ler-spotify.ler-solid:before {
    content: "\eae4";
  }
  
  .ler-spring-framework.ler-solid:before {
    content: "\eae5";
  }
  
  .ler-square-brackets.ler-solid:before {
    content: "\eae6";
  }
  
  .ler-star.ler-solid:before {
    content: "\eae7";
  }
  
  .ler-status.ler-solid:before {
    content: "\eae8";
  }
  
  .ler-subtitle.ler-solid:before {
    content: "\eae9";
  }
  
  .ler-sun.ler-solid:before {
    content: "\eaea";
  }
  
  .ler-support-24.ler-solid:before {
    content: "\eaeb";
  }
  
  .ler-switch.ler-solid:before {
    content: "\eaec";
  }
  
  .ler-syringe.ler-solid:before {
    content: "\eaed";
  }
  
  .ler-tablet-book.ler-solid:before {
    content: "\eaee";
  }
  
  .ler-tablet-delete.ler-solid:before {
    content: "\eaef";
  }
  
  .ler-tablet-down.ler-solid:before {
    content: "\eaf0";
  }
  
  .ler-tablet-ok.ler-solid:before {
    content: "\eaf1";
  }
  
  .ler-tablet-text-down.ler-solid:before {
    content: "\eaf2";
  }
  
  .ler-tablet-text-up.ler-solid:before {
    content: "\eaf3";
  }
  
  .ler-tablet-up.ler-solid:before {
    content: "\eaf4";
  }
  
  .ler-tablet.ler-solid:before {
    content: "\eaf5";
  }
  
  .ler-tag-cross.ler-solid:before {
    content: "\eaf6";
  }
  
  .ler-tag.ler-solid:before {
    content: "\eaf7";
  }
  
  .ler-teacher.ler-solid:before {
    content: "\eaf8";
  }
  
  .ler-tech-wifi.ler-solid:before {
    content: "\eaf9";
  }
  
  .ler-technology-2.ler-solid:before {
    content: "\eafa";
  }
  
  .ler-technology-3.ler-solid:before {
    content: "\eafb";
  }
  
  .ler-technology-4.ler-solid:before {
    content: "\eafc";
  }
  
  .ler-technology.ler-solid:before {
    content: "\eafd";
  }
  
  .ler-telephone-geolocation.ler-solid:before {
    content: "\eafe";
  }
  
  .ler-test-tubes.ler-solid:before {
    content: "\eaff";
  }
  
  .ler-text-align-center.ler-solid:before {
    content: "\eb00";
  }
  
  .ler-text-align-justify-center.ler-solid:before {
    content: "\eb01";
  }
  
  .ler-text-align-left.ler-solid:before {
    content: "\eb02";
  }
  
  .ler-text-align-right.ler-solid:before {
    content: "\eb03";
  }
  
  .ler-text-bold.ler-solid:before {
    content: "\eb04";
  }
  
  .ler-text-circle.ler-solid:before {
    content: "\eb05";
  }
  
  .ler-text-italic.ler-solid:before {
    content: "\eb06";
  }
  
  .ler-text-number.ler-solid:before {
    content: "\eb07";
  }
  
  .ler-text-strikethrough.ler-solid:before {
    content: "\eb08";
  }
  
  .ler-text-underline.ler-solid:before {
    content: "\eb09";
  }
  
  .ler-text.ler-solid:before {
    content: "\eb0a";
  }
  
  .ler-thermometer.ler-solid:before {
    content: "\eb0b";
  }
  
  .ler-theta.ler-solid:before {
    content: "\eb0c";
  }
  
  .ler-tiktok.ler-solid:before {
    content: "\eb0d";
  }
  
  .ler-time.ler-solid:before {
    content: "\eb0e";
  }
  
  .ler-timer.ler-solid:before {
    content: "\eb0f";
  }
  
  .ler-to-left.ler-solid:before {
    content: "\eb10";
  }
  
  .ler-to-right.ler-solid:before {
    content: "\eb11";
  }
  
  .ler-toggle-off-circle.ler-solid:before {
    content: "\eb12";
  }
  
  .ler-toggle-off.ler-solid:before {
    content: "\eb13";
  }
  
  .ler-toggle-on-circle.ler-solid:before {
    content: "\eb14";
  }
  
  .ler-toggle-on.ler-solid:before {
    content: "\eb15";
  }
  
  .ler-trailer.ler-solid:before {
    content: "\eb16";
  }
  
  .ler-trash-square.ler-solid:before {
    content: "\eb17";
  }
  
  .ler-trash.ler-solid:before {
    content: "\eb18";
  }
  
  .ler-tree.ler-solid:before {
    content: "\eb19";
  }
  
  .ler-trello.ler-solid:before {
    content: "\eb1a";
  }
  
  .ler-triangle.ler-solid:before {
    content: "\eb1b";
  }
  
  .ler-truck.ler-solid:before {
    content: "\eb1c";
  }
  
  .ler-ts.ler-solid:before {
    content: "\eb1d";
  }
  
  .ler-twitch.ler-solid:before {
    content: "\eb1e";
  }
  
  .ler-twitter.ler-solid:before {
    content: "\eb1f";
  }
  
  .ler-two-credit-cart.ler-solid:before {
    content: "\eb20";
  }
  
  .ler-underlining.ler-solid:before {
    content: "\eb21";
  }
  
  .ler-up-down.ler-solid:before {
    content: "\eb22";
  }
  
  .ler-up-square.ler-solid:before {
    content: "\eb23";
  }
  
  .ler-up.ler-solid:before {
    content: "\eb24";
  }
  
  .ler-update-file.ler-solid:before {
    content: "\eb25";
  }
  
  .ler-update-folder.ler-solid:before {
    content: "\eb26";
  }
  
  .ler-user-edit.ler-solid:before {
    content: "\eb27";
  }
  
  .ler-user-square.ler-solid:before {
    content: "\eb28";
  }
  
  .ler-user-tick.ler-solid:before {
    content: "\eb29";
  }
  
  .ler-user.ler-solid:before {
    content: "\eb2a";
  }
  
  .ler-verify.ler-solid:before {
    content: "\eb2b";
  }
  
  .ler-vibe.ler-solid:before {
    content: "\eb2c";
  }
  
  .ler-virus.ler-solid:before {
    content: "\eb2d";
  }
  
  .ler-vue.ler-solid:before {
    content: "\eb2e";
  }
  
  .ler-vuesax.ler-solid:before {
    content: "\eb2f";
  }
  
  .ler-wallet.ler-solid:before {
    content: "\eb30";
  }
  
  .ler-wanchain.ler-solid:before {
    content: "\eb31";
  }
  
  .ler-watch.ler-solid:before {
    content: "\eb32";
  }
  
  .ler-whatsapp.ler-solid:before {
    content: "\eb33";
  }
  
  .ler-wifi-home.ler-solid:before {
    content: "\eb34";
  }
  
  .ler-wifi-square.ler-solid:before {
    content: "\eb35";
  }
  
  .ler-wifi.ler-solid:before {
    content: "\eb36";
  }
  
  .ler-wrench.ler-solid:before {
    content: "\eb37";
  }
  
  .ler-xaomi.ler-solid:before {
    content: "\eb38";
  }
  
  .ler-xd.ler-solid:before {
    content: "\eb39";
  }
  
  .ler-xmr.ler-solid:before {
    content: "\eb3a";
  }
  
  .ler-yii.ler-solid:before {
    content: "\eb3b";
  }
  
  .ler-youtube.ler-solid:before {
    content: "\eb3c";
  }
  