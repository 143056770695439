.ip-standard-top-padding {
}

.app-default {
  min-height: calc(100vh - var(--vh-offset, 0px));
}

.ip-fix-button:hover .svg-icon {
  fill: #fff !important;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 15em;
  padding: 0;
  width: 15em;
}

.ql-container {
  border: 1px solid #dee2e6 !important;
  border-top: 0px !important;
  border-radius: 0px 0px 15px 15px !important;
  overflow: auto;
}
.ql-toolbar {
  border-top-left-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important;
  border-bottom: 0 !important;
  border: 1px solid #dee2e6 !important;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

#fr-logo {
  display: none;
}
.disabled-loading {
  pointer-events: none;
}

.full-width-tooltip .tooltip-inner {
  max-width: 100%;
}
.tooltip-inner {
  word-wrap: break-word;
  text-align: left !important;
  padding: 10px;
  white-space: pre-line;
}

span.ng-clear-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ng-select.is-invalid .ng-select-container,
.ng-select.is-valid .ng-select-container {
  padding-right: calc(1.5em + 1.3rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.325rem) center !important;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem) !important;
}
.ng-select.is-invalid .ng-select-container {
  border-color: #f64e60 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e") !important;
}

.ng-select.is-valid .ng-select-container {
  border-color: #00af76 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
}

.ng-select .ng-select-container.ng-has-value {
  max-height: unset !important;

  overflow: visible !important;
  min-height: calc(1.5em + 1.5rem + 2px) !important;
}
.ng-select-the .ng-select-container .ng-value {
  /* @apply flex flex-nowrap items-center text-nowrap relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700; */
  display: flex;
  color: #fff !important;
  border-radius: 5px !important;
  overflow: hidden;
  font-size: 0.75em !important;
}
.ng-select-the .ng-select-container .ng-input {
  top: 0 !important;
  height: 100%;
}

.ng-select-the .ng-select-container .ng-input input {
  height: 100%;
}

.ng-select-the.single .ng-select-container .ng-value-label {
  background-color: #fff !important;
  color: #000 !important;
  padding: 0 !important;
}

.ng-select-the .ng-select-container .ng-value-label {
  background-color: #007bff;
  padding: 1px 10px !important;
}

.ng-select-the .ng-select-container .ng-value-icon {
  background-color: #52a3ff;
  border: none !important;
}

.ng-select-the .ng-option-selected {
  /* color: #fff !important;
  background-color: #007bff !important; */
}

.ng-select-the .ng-option:hover {
  /* color: #fff !important;
  background-color: #52a3ff !important; */
}

.ng-select-the .ng-placeholder {
  position: unset !important;
  color: #aaa5b7 !important;
  font-size: 1.1rem;
  font-weight: 500;
}

.ng-select-the .ng-select-container .ng-value-icon:hover {
  background-color: #007bff !important;
}

.ng-select-the .ng-select-container,
.selected-list .c-btn {
  display: inline-flex;
  width: 100% !important;
  height: calc(1.5em + 1.5rem + 4px) !important;
  padding: 0rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #bbbbc7;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e3ea !important;
  border-radius: 0.25rem;
}

.selected-list .c-angle-down,
.selected-list .c-angle-up {
  height: unset !important;
}
.selected-list .c-list .c-token {
  border-radius: 7px !important;
}

.fake-link {
  cursor: pointer !important;
}

.fake-drag-drop {
  cursor: grab !important;
}
.fake-drag-drop:active {
  cursor: grabbing !important;
}

.profile-popover-class {
  max-width: 100% !important;
  background-color: #fff !important;
  border: none !important;
  box-shadow: #00000041 0 0 9px 0px;
}

.ngx-toastr.ip-custom-toast {
  display: contents !important;
  background-color: transparent !important;
}
.ngx-toastr.ip-custom-toast:hover {
  box-shadow: none !important;
}
.toast-container .ngx-toastr {
  box-shadow: none !important;
}
.toast-container .ngx-toastr.toastr-custom-emails {
  background-color: #fff !important;
  color: #000 !important;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 2px 7px #00000061 !important;
}

.bootstrap3-info.ng2-tag-input.ng2-tag-input--focused {
  box-shadow: none !important;
  border: 1px solid #ccc;
  height: fit-content !important;
}

.bootstrap3-info.ng2-tag-input.ng2-tag-input {
  box-shadow: none !important;
  border: 1px solid #ccc;
  height: fit-content !important;
}

.bootstrap3-info tag {
  height: -webkit-fit-content !important;
  height: -moz-fit-content;
  height: fit-content;
  background: #007bff !important;
}

.bootstrap3-info
  tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background: rgb(0, 104, 214) !important;
}

.bootstrap3-info tag.tag--editing {
  cursor: text;
  background: #fff !important;
  color: #000 !important;
}

.svg-muted {
  fill: #b5b5c3;
}

.svg-muted-light {
  fill: #cdd2d6;
}

.svg-danger {
  fill: #f64e60;
}

.svg-primary {
  fill: #0078ff;
}

.svg-white {
  fill: #fff;
}

.svg-success {
  fill: #07d294;
}

.svg-black {
  fill: #000;
}

.add-new-modal .modal-dialog {
  width: 100% !important;
  max-width: 740px;
}

.sales-item-modal .modal-dialog {
  width: 100% !important;
  max-width: 840px;
}

.sales-item-modal .modal-body {
  max-height: 90vh;
  overflow: auto;
}

.modal-content {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hint__circle {
  height: 30px;
  width: 30px;
  margin: 0 auto;
  -webkit-border-radius: 100px;
  color: black;
  text-align: center;
  line-height: 30px;
  font-family: arial;
  font-size: 20px;
  border: 2px solid black;
  opacity: 0.4;
}

.hint__circle:hover {
  border: 2px solid #f37920;
  color: #f37920;
  cursor: pointer;
  opacity: 1;
}
.introjs-tooltiptext {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.introjs-tooltiptext h5:first-child {
  font-size: 30px;
  font-weight: 700 !important;
}
.introjs-tooltiptext h5 {
  font-size: 23px;
  font-weight: 300 !important;
}

.StripeElement--invalid {
  border: 1px solid red;
  border-radius: 0.42rem !important;
}

.btn-suc-fix {
  background-color: #20c997 !important;
  border-color: #19b98a !important;
}

.modal-content {
}

.bundle-modal .modal-dialog {
  padding-top: 100px;
  max-width: 920px;
}

.bundle-modal .modal-content {
  border-radius: 27px !important;
  background: #ffffff00;
  border: none !important;
}
.bundle-modal .modal-dialog-centered {
  padding-top: 0 !important;
}

.mymodalcustomclass {
  max-height: 100vh;
}
.mymodalcustomclass .modal-dialog {
  max-height: 100%;
  height: 100%;

  max-width: 1000px;
}
.mymodalcustomclass .modal-body {
  max-height: 70vh;
  height: 70vh;
  overflow: auto;
  overflow-x: hidden;
}

@media (max-width: 992px) {
  .mymodalcustomclass .modal-dialog {
    max-width: 100% !important;
  }
}

.modal-content > .container {
  transition: all 0.5s ease-in-out;
  animation-name: example;
  animation-duration: 0.4s;
}

@keyframes example {
  0% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

ngx-intl-tel-input .iti {
  width: 100% !important;
}

ngx-intl-tel-input .iti__selected-flag {
  border-radius: 1.5rem 0.5em 0.5em 1.5rem;
}

ngx-intl-tel-input #country-search-box {
  outline: none;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

ngx-intl-tel-input .country-dropdown {
  border-radius: 1.5rem !important;
  overflow: hidden;
}

.disablePhone input {
  background-color: #f3f6f9;
}

.swal2-styled.swal2-confirm {
  background-color: #007bff !important;
}
*/ .introjs-helperNumberLayer {
  font-family: tahoma, Arial, verdana, sans-serif;
  font-size: 14px;
  text-shadow: none;
  width: 10px;
  height: 10px;
  line-height: 22px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: #dc3545;
  color: transparent;
  box-shadow: 0 0 0 0 #fff;
  -webkit-animation: pulse-blue 1s infinite;
  animation: pulse-blue 1s infinite;
  top: -9px;
  left: -13px;
}

.introjs-helperLayer {
  background-color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 17px;
}

.introjs-tooltip {
  letter-spacing: 0.1em;
  color: #2c3e50;
  font-family: inherit;
  border-radius: 2px;
  padding: 1.5em;
  min-width: 300px;
}

@-webkit-keyframes dyinglight {
  15% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
  50% {
    -webkit-transform: rotate(-89deg);
    transform: rotate(-89deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@keyframes dyinglight {
  15% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
  50% {
    -webkit-transform: rotate(-89deg);
    transform: rotate(-89deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
.dl {
  position: absolute;
  left: 50%;
  top: -100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
}

.dl__square {
  display: block;
  width: 50px;
  height: 50px;
  background: #fff;
}

.dl__container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
  -webkit-animation: dyinglight 1s ease infinite;
  animation: dyinglight 1s ease infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dl__corner--top:before,
.dl__corner--top:after,
.dl__corner--bottom:before,
.dl__corner--bottom:after {
  position: absolute;
  width: 17px;
  height: 17px;
  color: #fff;
  content: "";
}

.dl__corner--top:before {
  border-left: 1px solid;
  border-top: 1px solid;
  top: -6px;
  left: -6px;
}
.dl__corner--top:after {
  border-right: 1px solid;
  border-top: 1px solid;
  top: -6px;
  right: -6px;
}

.dl__corner--bottom:before {
  border-left: 1px solid;
  border-bottom: 1px solid;
  bottom: -6px;
  left: -6px;
}
.dl__corner--bottom:after {
  border-right: 1px solid;
  border-bottom: 1px solid;
  bottom: -6px;
  right: -6px;
}

.loading-screen-wrapper {
  z-index: 99999999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.loading-screen-icon {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__poster {
  background-size: cover !important;
}

.introjs-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid transparent;

  font-size: 1rem;

  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #007bff;
  border-color: #007bff;
  width: 33.3%;
  padding: 1em 0;
  text-shadow: none;
}

.introjs-prevbutton {
  border-radius: 0.2em 0 0 0.2em;
}

.introjs-nextbutton {
  border-radius: 0 0.2em 0.2em 0;
}

.introjs-button:hover,
.introjs-button:focus {
  background: #007bff;
  color: #fff !important;
  box-shadow: none;
  border-color: #007bff;
  text-decoration: none !important;
  text-shadow: none;
}

.fake-hide {
  visibility: hidden !important;
}
.fake-disabled {
  pointer-events: none;
}

.loader-dots {
  text-align: center;
}
.loader-dots span {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  border-radius: 10px;
  animation: loader-dots 0.8s infinite alternate;
}
.loader-dots span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.loader-dots span:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes loader-dots {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

.advanced-pie-legend-wrapper {
  text-align: left;
  margin-left: 1.2em;
  width: auto;
}

ngx-charts-advanced-pie-chart > div {
  display: flex;
  gap: 15px;
}

.o-70-hov {
  transition: all 0.2s ease-in-out !important;
  opacity: 0.7;
}

.o-70-hov:hover {
  opacity: 1;
}

.highcharts-credits {
  display: none;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.red-notification-dot {
  background: #f00;
  width: 10px;
  height: 10px;
  box-sizing: border-box !important;
  border-radius: 50%;
  position: relative;

  -webkit-animation: heartbeat 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s
    infinite;
  -moz-animation: heartbeat 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s
    infinite;
  animation: heartbeat 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s infinite;
}
@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.box-danger-shade {
  box-shadow: 0 0 1rem 0rem #f64e5fa8 !important;
  border: solid 1px #f64e60 !important;
}

.box-success-shade {
  box-shadow: 0 0 1rem 0rem #13e03fa8 !important;
  border: solid 1px #13e03f !important;
}

.notiflix-report-content,
.notiflix-confirm-content {
  width: 550px !important;
}
.notiflix-report-title,
.notiflix-confirm-head h5 {
  font-size: 1.6em !important;
}

.notiflix-report-message,
.notiflix-report-button,
.notiflix-confirm-head div,
.nx-confirm-button-cancel,
.nx-confirm-button-ok {
  font-size: 1.4em !important;
}

.btn-light-ip {
  color: #b8bcd4 !important;
  background-color: #fff !important;
}

.custom-bootstrap-nav .nav-link {
  padding: 0 !important;
  padding-bottom: 0.2em !important;
}

.custom-bootstrap-nav .nav-item {
  padding: 0.5rem 1em 1em 0;
}

.custom-bootstrap-nav .nav-link,
.custom-bootstrap-nav .nav-link:hover {
  color: #212529 !important;
}
.custom-bootstrap-nav .nav-link.active {
  color: #212529 !important;
  font-weight: bold;
  border-bottom: 4px solid #007bff !important;
}

.otp-input {
  width: 50px !important;
  height: 50px !important;
  border-radius: 0.42rem !important;
  border: 1px solid #e5eaee !important;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #007bff !important;
}

.otp-input:focus {
  border: 1px solid #007bff !important;
}

.ng-otp-input-wrapper {
  display: flex;
  justify-content: center;
}

.inter-nav-item {
  font-size: 1.1em;
}
.inter-nav-item.active {
  transition: all 0.2s ease-in-out !important;
  color: #616161 !important;
  font-weight: 700;
}

.inter-nav-item.active .svg-muted {
  fill: #616161 !important;
}

.svg-animation-fix {
  transition: all 0.2s ease-in-out !important;
}

.item-actions .custom-secret-button,
.item-actions .custom-item-button:hover {
  text-decoration: none !important;
}

.item-actions .custom-item-button .svg-icon {
  transform: scale(1);
  transition: all 0.1s ease-in-out;
}

.item-actions .custom-item-button .svg-icon:hover {
  transform: scale(1.2);
}

.learmo-full-screen-modal {
}
.learmo-full-screen-modal .modal-dialog {
  margin: 100px 0 0 0 !important;
  height: calc(100vh - 100px);
}
.learmo-full-screen-modal .modal-content {
  margin: 0;
  height: calc(100vh - 100px);
  max-height: 100vh;
  flex-direction: column;
  border-top-left-radius: 2.25rem !important;
  border-top-right-radius: 2.25rem !important;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-animate-in {
  animation: fadeIn 0.2s, slideIn 0.4s !important;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.modal-animate-out {
  animation: fadeIn 0.35s reverse, slideIn 0.35s reverse !important;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.global-modal-class .modal-content {
  border-radius: 2.25rem !important;
}
.payment-modal .modal-dialog {
  max-width: 650px;
}
.informative-item-view-modal .modal-dialog {
  padding-top: 100px;
  max-width: 1000px;
}

.informative-item-view-modal .modal-content {
  border-radius: 27px !important;
  background: #ffffff00;
  border: none !important;
}
.informative-item-view-modal .modal-dialog-centered {
  align-items: start !important;
  padding-top: 0 !important;
}

.informative-item-view-window .modal-dialog {
  width: 100% !important;
  max-width: 1082px;
}

.more-info-section {
  background: #007bffe3;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.more-info-section:hover {
  opacity: 1;
}

.more-info-section .label-content {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.more-info-section:hover .label-content {
  transform: translateY(0);
  opacity: 1;
}

body {
  overflow-x: hidden !important;
  overflow-y: auto;
}

.skeleton {
  position: relative;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
}
.skeleton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.404),
    transparent
  );
  -webkit-animation: skeleton 1s infinite;
  animation: skeleton 1s infinite;
}

@-webkit-keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

gallery-core {
  gap: 0.5em;
}

.g-slider {
  gap: 0.5em;
}

gallery {
  height: 100% !important;
  background-color: unset !important;
}
.g-image-item {
  background-size: auto 100% !important;

  border-radius: 1em;
}

.ql-editor {
  min-height: 100px;
}

.ql-mention-list {
  background-color: white;
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  padding: 0px 0px 0px 20px;
  z-index: 100;
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 10px 10px;
}

.ql-mention-list .ql-mention-list-item.selected {
  background-color: rgba(0, 0, 0, 0.12);
}

span.mention {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 2px 5px;
  border-radius: 2px;
}

.categories-area {
  position: relative;
  text-align: left;

  width: 30%;
}

.categories-area .categories-container {
  position: sticky;
  top: 7em;
  margin-top: 1.5rem !important;
}

.categories-area .title {
  font-size: 1.6em;
}

.categories-area ul {
  margin-left: 3.5em;
}
.categories-area ul li::marker {
  content: "-";
}

.categories-area ul li.category-item {
  text-indent: 0.2em;
  font-size: 1.3em;
  color: #919191;
  font-weight: 300;
  padding-bottom: 0.6em;
  transition: all 0.15s ease-in-out;
}

.categories-area ul li.category-item:hover {
  color: #919191;
  font-weight: 500;
  padding-left: 2px;
}
.categories-area ul li.category-item.active .count-label {
  color: #ffffff;
  background-color: #007bff;
}
.categories-area ul li.category-item.active {
  color: #007bff;
  font-weight: 500;
  padding-left: 4px;
}

.hide-card {
  margin: 0 !important;
  animation: drop-card 1s forwards cubic-bezier(0.54, -0.38, 0.34, 1.42);
}

@keyframes drop-card {
  99% {
    transform: translateY(-200px);
    opacity: 0;
    display: inline-block;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
    height: 0;
    display: none;
  }
}

.blockbuilder-branding {
  display: none !important;
}

.tab-content {
  height: 100%;
}

.popover {
  max-width: 100% !important;
}

.separator.separator-content {
  display: flex;
  align-items: center;
  border-bottom: 0;
  text-align: center;
}
.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #ecf0f3;
}

.separator.separator-content::after {
  margin-left: 1.25rem;
}
.separator.separator-content::before {
  margin-right: 1.25rem;
}

.separator.separator-content::before,
.separator.separator-content::after {
  content: " ";
  width: 50%;
  border-bottom: 1px solid #ecf0f3;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden !important;
}

.swal2-loader {
  align-items: center !important;
  justify-content: center !important;
  width: 2.2em !important;
  height: 2.2em !important;
  margin: 0 1.875em !important;
  -webkit-animation: swal2-rotate-loading 0.5s linear 0s infinite normal !important;
  animation: swal2-rotate-loading 0.5s linear 0s infinite normal !important;
  border-width: 0.25em !important;
  border-style: solid !important;
  border-radius: 100% !important;
  border-color: var(--bs-primary) var(--bs-primary) transparent !important;
}

.shortcuts-grid .shortcut-item {
  position: relative;
  width: 100%;
  min-height: 160px;
  border-radius: 1.7em;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.shortcuts-grid .shortcut-item:not(.soon):hover {
  border: 1px solid #007bff !important;
  border-radius: 2.3em;
  transform: translateY(-5px);
}

.shortcuts-grid .shortcut-item.soon {
  opacity: 0.7;
  cursor: auto;
}

.pulse {
  animation: pulse 500ms forwards;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #007bff;
  }
  100% {
    box-shadow: 0 0 0 10px #3a98fd01;
  }
}

.helpPopover .main-container {
  background-color: #f3f6f9 !important;
}

.helpPopover .popover-arrow::after {
  border-top-color: #f3f6f9 !important;
}
.helpPopover {
  border: none !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  max-width: 90vw !important;
}
.helpPopover .popover-body {
  width: 400px !important;
  max-width: 90vw !important;
  padding: 0 !important;
}

.onboarding-new-modal .modal-dialog {
  width: fit-content !important;
}

.nav-earnings a {
  transition: all 150ms ease-in-out !important;
  color: #80808f !important;
  font-weight: 500 !important;
  text-decoration: none !important;
}

.nav-earnings a.active {
  color: #007bff !important;
  background-color: #f3f6f9 !important;
}

.nav-earnings a.active:hover,
.nav-earnings a.active:active {
  color: #007bff !important;
  background-color: #f3f6f9 !important;
}

.notfoundsvg svg {
  max-width: 22em;
}

.first-letter-capital {
  text-transform: capitalize;
}

.secret-actions {
  transition: all 0.25s ease-in-out;
}

.secret-actions-hidden {
  display: none;
  opacity: 0;
  transform: translateX(-20px);
  flex: 0 0 auto;
  padding-right: 1em;

  transition: all 0.25s ease-in-out;
}

.secret-trigger:hover .secret-actions-hidden {
  display: block;
  opacity: 1;
  transform: unset !important;
  animation: disableUntilAnimationDone 0.35s normal forwards ease-in-out;
}

@media (max-width: 992px) {
  .secret-actions-hidden {
    display: block;
    opacity: 1;
    transform: unset !important;
    animation: disableUntilAnimationDone 0.35s normal forwards ease-in-out;
  }
}

@keyframes disableUntilAnimationDone {
  0% {
    pointer-events: none;
  }

  100% {
    pointer-events: auto !important;
  }
}

.secret-trigger:hover .secret-actions .custom-secret-button,
.secret-trigger:hover .secret-actions .custom-secret-button:hover {
  text-decoration: none !important;
}

.secret-trigger:hover .secret-actions .custom-secret-button i {
  width: 40px;
  height: 40px;
  font-size: 1.2em !important;
  color: #343a40 !important;
  transition: all 0.1s ease-in-out;
}

.secret-trigger:hover .secret-actions .custom-secret-button i:hover {
  font-size: 1.4em !important;
}

.portal-btn-animation {
  transition: all 0.3s cubic-bezier(1, -0.03, 0, -0.16) !important;
}

.portal-btn-animation:hover {
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-slide {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  cursor: pointer;
}
.swiper-slide:not(.swiper-slide-active) > * {
  pointer-events: none;
}

.swiper-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  transition: all 0.3s ease-in-out !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 15px !important;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 6%);
  background-color: var(--bs-light) !important;
  color: var(--bs-primary);
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: var(--bs-primary) !important;
  background-color: var(--bs-white) !important;
  transform: scale(1.2);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-weight: 800;
  font-size: 1.4em !important;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.95rem;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 0.85rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.ng-touched.ng-valid.validate ~ .valid-feedback,
.ng-touched.ng-valid.validate ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control[formcontrolname]:not([ngnoform]):valid,
.form-control[formcontrolname]:not([ngnoform]).ng-touched.ng-valid.validate {
  border-color: var(--bs-success);
  padding-right: calc(1.5em + 1.55rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2375CC68' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}
.was-validated .form-control[formcontrolname]:not([ngnoform]):valid:focus,
.form-control[formcontrolname]:not(
    [ngnoform]
  ).ng-touched.ng-valid.validate:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control[formcontrolname]:not([ngnoform]):valid,
textarea.form-control[formcontrolname]:not(
    [ngnoform]
  ).ng-touched.ng-valid.validate {
  padding-right: calc(1.5em + 1.55rem);
  background-position: top calc(0.375em + 0.3875rem) right
    calc(0.375em + 0.3875rem);
}

.was-validated .form-select[formcontrolname]:not([ngnoform]):valid,
.form-select[formcontrolname]:not([ngnoform]).ng-touched.ng-valid.validate {
  border-color: var(--bs-success);
}
.was-validated
  .form-select[formcontrolname]:not([ngnoform]):valid:not([multiple]):not(
    [size]
  ),
.was-validated
  .form-select[formcontrolname]:not([ngnoform]):valid:not([multiple])[size="1"],
.form-select[formcontrolname]:not([ngnoform]).ng-touched.ng-valid.validate:not(
    [multiple]
  ):not([size]),
.form-select[formcontrolname]:not([ngnoform]).ng-touched.ng-valid.validate:not(
    [multiple]
  )[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2375CC68' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}
.was-validated .form-select[formcontrolname]:not([ngnoform]):valid:focus,
.form-select[formcontrolname]:not(
    [ngnoform]
  ).ng-touched.ng-valid.validate:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated
  .input-group
  > .form-control[formcontrolname]:not([ngnoform]):not(:focus):valid,
.input-group
  > .form-control[formcontrolname]:not([ngnoform]):not(
    :focus
  ).ng-touched.ng-valid.validate,
.was-validated
  .input-group
  > .form-select[formcontrolname]:not([ngnoform]):not(:focus):valid,
.input-group
  > .form-select[formcontrolname]:not([ngnoform]):not(
    :focus
  ).ng-touched.ng-valid.validate,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).ng-touched.ng-valid.validate {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.95rem;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 0.85rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.ng-invalid.ng-touched[formcontrolname]:not([ngnoform]) ~ .invalid-feedback,
.ng-invalid.ng-touched[formcontrolname]:not([ngnoform]) ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control[formcontrolname]:not([ngnoform]):invalid,
.form-control[formcontrolname]:not([ngnoform]).ng-invalid.ng-touched,
[formcontrolname]:not([ngnoform]).ng-invalid.ng-touched .ql-container {
  border-color: var(--bs-danger) !important;
  padding-right: calc(1.5em + 1.55rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}

[formcontrolname]:not([ngnoform]).ng-invalid.ng-touched .ql-toolbar {
  border-color: var(--bs-danger) !important;
  padding-right: calc(1.5em + 1.55rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}

.was-validated .form-control[formcontrolname]:not([ngnoform]):invalid:focus,
.form-control[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]):focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control[formcontrolname]:not([ngnoform]):invalid,
textarea.form-control[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]) {
  padding-right: calc(1.5em + 1.55rem);
  background-position: top calc(0.375em + 0.3875rem) right
    calc(0.375em + 0.3875rem);
}

.was-validated .form-select[formcontrolname]:not([ngnoform]):invalid,
.form-select[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]) {
  border-color: var(--bs-danger);
}
.was-validated
  .form-select[formcontrolname]:not([ngnoform]):invalid:not([multiple]):not(
    [size]
  ),
.was-validated
  .form-select[formcontrolname]:not([ngnoform]):invalid:not(
    [multiple]
  )[size="1"],
.form-select[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]):not([multiple]):not(
    [size]
  ),
.form-select[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]):not(
    [multiple]
  )[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}
.was-validated .form-select[formcontrolname]:not([ngnoform]):invalid:focus,
.form-select[formcontrolname]:not(
    [ngnoform]
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]):focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated
  .input-group
  > .form-control[formcontrolname]:not([ngnoform]):not(:focus):invalid,
.input-group
  > .form-control[formcontrolname]:not([ngnoform]):not(
    :focus
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]),
.was-validated
  .input-group
  > .form-select[formcontrolname]:not([ngnoform]):not(:focus):invalid,
.input-group
  > .form-select[formcontrolname]:not([ngnoform]):not(
    :focus
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]),
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group
  > .form-floating:not(
    :focus-within
  ).ng-invalid.ng-touched[formcontrolname]:not([ngnoform]) {
  z-index: 4;
}

.plyr__progress__container {
  flex-grow: 1;
}

body[data-learmo-focus-mode="on"] #kt_app_sidebar {
  margin-left: -7%;

  transform: translateX(-85%);
}

body[data-learmo-focus-mode="on"] #kt_app_sidebar #kt_app_sidebar_wrapper {
  border-right: 5px solid var(--bs-primary);
}

body[data-learmo-focus-mode="on"]
  #kt_app_sidebar:hover
  #kt_app_sidebar_wrapper {
  border-right: 0px solid var(--bs-primary);
}

body[data-learmo-focus-mode="on"] #kt_app_main {
  margin-left: 0;
}

body[data-learmo-focus-mode="on"] #kt_app_sidebar:hover ~ #kt_app_main {
  margin-left: calc(var(--bs-app-sidebar-width-actual)) !important;
}

body[data-learmo-focus-mode="on"] #kt_app_sidebar:hover {
  margin-left: -7%;
  opacity: 1;
  transform: translateX(0);
}

[data-learmo-focus-mode="on"] {
  --bs-app-sidebar-width: 135px;
  --bs-app-sidebar-gap-start: 0px;
  --bs-app-sidebar-gap-end: 0px;
  --bs-app-sidebar-gap-top: 0px;
  --bs-app-sidebar-gap-bottom: 0px;
}

body si-top-menu,
body #kt_app_sidebar,
#kt_app_main,
body #kt_app_sidebar #kt_app_sidebar_wrapper,
body .app-header {
  transition: all 0.5s ease-in-out !important;
}

body[data-learmo-focus-mode="on"] #kt_app_sidebar:hover ~ #kt_app_main {
}
body[data-learmo-focus-mode="on"] #kt_app_main {
}

.app-wrapper {
  height: calc(100vh - var(--bs-app-header-height));
}

@media (min-width: 992px) {
  .app-wrapper {
    min-height: 829px;
  }
}

.form-control {
  transition: all 0.3s ease-in-out !important;
}
.form-control:hover:not(:focus) {
  border: 1px solid #86b7fe;
}
.form-control:focus {
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-component-active-bg), 0.25);
}

.swal2-actions {
  gap: 1em;
}

.hide-and-keep-area {
  visibility: hidden;
  pointer-events: none;
}

.the-tree.no-hover .course-hierarchy-item:not(.dragging) {
  pointer-events: none;
}

.course-hierarchy-item {
  transition: 0.2s all ease-in-out;
  background-color: var(--bs-body-bg);
}

.course-hierarchy-item:hover,
.course-hierarchy-item.dragging {
  transform: translateY(-2px);
  box-shadow: var(--bs-box-shadow-xs);
}

.course-hierarchy-item.module:hover,
.course-hierarchy-item.module.dragging {
  border-color: var(--bs-primary) !important;
}

.course-hierarchy-item.submodule:hover,
.course-hierarchy-item.submodule.dragging {
  border-color: var(--bs-info) !important;
}

.course-hierarchy-item.lesson:hover,
.course-hierarchy-item.lesson.dragging {
  border-color: var(--bs-success) !important;
}

.course-hierarchy-item.quiz:hover,
.course-hierarchy-item.quiz.dragging {
  border-color: var(--bs-danger) !important;
}

.course-hierarchy-item.assignment:hover,
.course-hierarchy-item.assignment.dragging {
  border-color: var(--bs-warning) !important;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -8px !important;
  left: 0;
  border-width: 16px 8px 0 !important;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -8px !important;
  left: 0;
  border-width: 0 8px 16px !important;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 16px !important;
  border-left-color: initial;
  right: -8px !important;
  transform-origin: center left;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -8px !important;
  border-width: 8px 16px 8px 0 !important;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box {
  border-radius: 15px !important;
}
.tippy-box[data-animation="shift-toward-extreme"][data-state="hidden"] {
  opacity: 0;
}
.tippy-box[data-animation="shift-toward-extreme"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(-20px);
}
.tippy-box[data-animation="shift-toward-extreme"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(20px);
}
.tippy-box[data-animation="shift-toward-extreme"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(-20px);
}
.tippy-box[data-animation="shift-toward-extreme"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(20px);
}

.form-label span[aria-hidden="true"] {
  color: #f00;
}

.learmo-form formly-wrapper-form-field > div {
  margin-bottom: 2.5em !important;
}

.checkered {
  --checkeredBackgroundColor: rgba(255, 255, 255);
  --checkeredSquareColor: rgb(100 100 100 / 11%);
  --checkeredSquareSize: 10px;

  background-color: var(--checkeredBackgroundColor);
  background-image: linear-gradient(
      45deg,
      var(--checkeredSquareColor) 25%,
      transparent 25%
    ),
    linear-gradient(135deg, var(--checkeredSquareColor) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--checkeredSquareColor) 75%),
    linear-gradient(135deg, transparent 75%, var(--checkeredSquareColor) 75%);
  background-size: calc(2 * var(--checkeredSquareSize))
    calc(2 * var(--checkeredSquareSize));
  background-position: 0 0, var(--checkeredSquareSize) 0,
    var(--checkeredSquareSize) calc(-1 * var(--checkeredSquareSize)),
    0 calc(-1 * var(--checkeredSquareSize));
}

.checkout-iam-here-indicator {
  background-color: rgba(var(--bs-primary-rgb), 0.25) !important;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: animationFadeIn;
}

.default-create-button {
  padding: 0.7em 1em !important;
  border-radius: 0.7em;
}

.channel-stats-bg {
  background-image: url(assets/images/bg/bg-3.png);
}

.tippy-box[data-theme~="chat-with-ai"] .tippy-content {
  padding: 0;
}

body.show-bg::before {
  transform: translateY(0);
}

body::before {
  transition: all 0.5s cubic-bezier(0.54, 0.3, 0.49, 1);
  transform: translateY(-100%);
}

body::before {
  --bs-header-bg: linear-gradient(90deg, #c9eaff 0%, #c9eaff 100%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background: var(--bs-header-bg);

  border-radius: 0 0 40px 40px;

  z-index: -1;
}

body {
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  body::before {
    background-size: 100% 340px;
    height: 260px;
  }
}
