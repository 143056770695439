.shepherd-button {
  background: #ffffff;

  border-radius: 0;
  color: #16202d;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 0;
  padding: 0.8rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
}

.shepherd-button:hover {
  background: #16202d;
  color: #ffffff;
}

.shepherd-button.shepherd-button-secondary {
  background: #cad5d5;
}

.shepherd-button.shepherd-button-secondary:hover {
  color: #cad5d5;
  background: #16202d;
}

.shepherd-cancel-icon {
  font-family: "GT Pressura", sans-serif;
}

.shepherd-arrow:before {
  background: #fff !important;
}

.shepherd-element {
  /* border: solid 4px #ffffff; */
}

.shepherd-element,
.shepherd-button {
  border-radius: 0.8em;
}

.shepherd-header,
.shepherd-footer {
  border-radius: 0;
}

.shepherd-footer {
  gap: 0.5em;
  padding: 0.5rem 0.5rem 0.5rem;
}

/* .shepherd-footer button:not(:last-of-type) {
  border-right: solid 4px #16202d;
} */

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  margin-top: -7px;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: transparent;

  padding-bottom: 0;
  font-weight: 700;
}

.shepherd-has-title .shepherd-content .shepherd-header .shepherd-title {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
}

.shepherd-text {
  font-size: 1.2rem;
  padding: 1rem;
  margin: 0.8rem 0.5rem 0.8em;
  border: 1px solid #ecf0f3 !important;
}

.shepherd-text a,
.shepherd-text a:visited,
.shepherd-text a:active {
  border-bottom: 1px dotted;
  border-bottom-color: rgba(0, 0, 0, 0.75);
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.shepherd-text a:hover,
.shepherd-text a:visited:hover,
.shepherd-text a:active:hover {
  border-bottom-style: solid;
}
