.lang-icon {
  display: inline-block;
  width: 25px;
  height: 15px;
  background-image: url('assets/images/lang-flags.png');
}
.lang-icon-aau {
  background-position: -0px -0px;
}
.lang-icon-abq {
  background-position: -25px -0px;
}
.lang-icon-ab {
  background-position: -50px -0px;
}
.lang-icon-ady {
  background-position: -75px -0px;
}
.lang-icon-kbd {
  background-position: -100px -0px;
}
.lang-icon-af {
  background-position: -125px -0px;
}
.lang-icon-oj {
  background-position: -150px -0px;
}
.lang-icon-ain {
  background-position: -175px -0px;
}
.lang-icon-ar {
  background-position: -200px -0px;
}
.lang-icon-ar-sa {
  background-position: -225px -0px;
}
.lang-icon-ar-dz {
  background-position: -250px -0px;
}
.lang-icon-ar-acy {
  background-position: -275px -0px;
}
.lang-icon-ar-afb {
  background-position: -300px -0px;
}
.lang-icon-ar-eg {
  background-position: -325px -0px;
}
.lang-icon-ar-ma {
  background-position: -350px -0px;
}
.lang-icon-ar-apc {
  background-position: -375px -0px;
}
.lang-icon-ar-lb {
  background-position: -400px -0px;
}
.lang-icon-ar-ly {
  background-position: -425px -0px;
}
.lang-icon-ar-iq {
  background-position: -450px -0px;
}
.lang-icon-ar-sd {
  background-position: -475px -0px;
}
.lang-icon-ar-tn {
  background-position: -500px -0px;
}
.lang-icon-ar-arb {
  background-position: -525px -0px;
}
.lang-icon-akk {
  background-position: -550px -0px;
}
.lang-icon-agx {
  background-position: -575px -0px;
  width: 22px;
}
.lang-icon-ofs {
  background-position: -0px -15px;
}
.lang-icon-atv {
  background-position: -25px -15px;
}
.lang-icon-alt {
  background-position: -50px -15px;
}
.lang-icon-alq {
  background-position: -75px -15px;
}
.lang-icon-am {
  background-position: -100px -15px;
}
.lang-icon-an {
  background-position: -125px -15px;
}
.lang-icon-frp {
  background-position: -150px -15px;
  width: 23px;
}
.lang-icon-xno {
  background-position: -175px -15px;
}
.lang-icon-apw {
  background-position: -200px -15px;
}
.lang-icon-arc {
  background-position: -225px -15px;
}
.lang-icon-arw {
  background-position: -250px -15px;
}
.lang-icon-aqc {
  background-position: -275px -15px;
  width: 22px;
}
.lang-icon-rup {
  background-position: -300px -15px;
}
.lang-icon-aer {
  background-position: -325px -15px;
}
.lang-icon-as {
  background-position: -350px -15px;
}
.lang-icon-ast {
  background-position: -375px -15px;
}
.lang-icon-akv {
  background-position: -400px -15px;
  width: 22px;
}
.lang-icon-av {
  background-position: -425px -15px;
}
.lang-icon-ae {
  background-position: -450px -15px;
}
.lang-icon-ay {
  background-position: -475px -15px;
  width: 15px;
}
.lang-icon-az {
  background-position: -500px -15px;
}
.lang-icon-kva {
  background-position: -525px -15px;
  width: 22px;
}
.lang-icon-ace {
  background-position: -550px -15px;
}
.lang-icon-ms {
  background-position: -575px -15px;
}
.lang-icon-ba {
  background-position: -0px -30px;
}
.lang-icon-ban {
  background-position: -25px -30px;
}
.lang-icon-bbl {
  background-position: -50px -30px;
}
.lang-icon-be {
  background-position: -75px -30px;
}
.lang-icon-be-tara {
  background-position: -100px -30px;
}
.lang-icon-bpy {
  background-position: -125px -30px;
}
.lang-icon-bue {
  background-position: -150px -30px;
}
.lang-icon-bis {
  background-position: -175px -30px;
}
.lang-icon-bg {
  background-position: -200px -30px;
}
.lang-icon-bla {
  background-position: -225px -30px;
}
.lang-icon-bm {
  background-position: -250px -30px;
}
.lang-icon-bn {
  background-position: -275px -30px;
}
.lang-icon-bo {
  background-position: -300px -30px;
}
.lang-icon-bod {
  background-position: -325px -30px;
}
.lang-icon-kap {
  background-position: -350px -30px;
  width: 22px;
}
.lang-icon-blc {
  background-position: -375px -30px;
}
.lang-icon-br {
  background-position: -400px -30px;
}
.lang-icon-brx {
  background-position: -425px -30px;
}
.lang-icon-bs {
  background-position: -450px -30px;
}
.lang-icon-bdk {
  background-position: -475px -30px;
}
.lang-icon-bph {
  background-position: -500px -30px;
}
.lang-icon-bua {
  background-position: -525px -30px;
}
.lang-icon-bug {
  background-position: -550px -30px;
}
.lang-icon-bsk {
  background-position: -575px -30px;
}
.lang-icon-ca {
  background-position: -0px -45px;
}
.lang-icon-cay {
  background-position: -25px -45px;
}
.lang-icon-ce {
  background-position: -50px -45px;
}
.lang-icon-ceb {
  background-position: -75px -45px;
}
.lang-icon-cho {
  background-position: -100px -45px;
}
.lang-icon-cji {
  background-position: -125px -45px;
}
.lang-icon-ch {
  background-position: -150px -45px;
}
.lang-icon-chr {
  background-position: -175px -45px;
}
.lang-icon-cs {
  background-position: -200px -45px;
}
.lang-icon-chg {
  background-position: -225px -45px;
}
.lang-icon-chn {
  background-position: -250px -45px;
}
.lang-icon-sn {
  background-position: -275px -45px;
}
.lang-icon-co {
  background-position: -300px -45px;
}
.lang-icon-cop {
  background-position: -325px -45px;
}
.lang-icon-cv {
  background-position: -350px -45px;
}
.lang-icon-cy {
  background-position: -375px -45px;
}
.lang-icon-da {
  background-position: -400px -45px;
}
.lang-icon-dak {
  background-position: -425px -45px;
}
.lang-icon-dlc {
  background-position: -450px -45px;
}
.lang-icon-dar {
  background-position: -475px -45px;
}
.lang-icon-dng {
  background-position: -500px -45px;
}
.lang-icon-zap {
  background-position: -525px -45px;
}
.lang-icon-dsb {
  background-position: -550px -45px;
}
.lang-icon-koy {
  background-position: -575px -45px;
}
.lang-icon-lut {
  background-position: -0px -60px;
}
.lang-icon-lad {
  background-position: -25px -60px;
}
.lang-icon-de {
  background-position: -50px -60px;
}
.lang-icon-de-at {
  background-position: -75px -60px;
}
.lang-icon-de-ch {
  background-position: -100px -60px;
  width: 15px;
}
.lang-icon-de-gsw {
  background-position: -125px -60px;
  width: 15px;
}
.lang-icon-de-goh {
  background-position: -150px -60px;
}
.lang-icon-de-gmh {
  background-position: -175px -60px;
}
.lang-icon-de-osx {
  background-position: -200px -60px;
}
.lang-icon-nv {
  background-position: -225px -60px;
}
.lang-icon-dv {
  background-position: -250px -60px;
}
.lang-icon-dz {
  background-position: -275px -60px;
}
.lang-icon-et {
  background-position: -300px -60px;
}
.lang-icon-egy {
  background-position: -325px -60px;
}
.lang-icon-elx {
  background-position: -350px -60px;
}
.lang-icon-el {
  background-position: -375px -60px;
}
.lang-icon-el-cy {
  background-position: -400px -60px;
}
.lang-icon-el-ka {
  background-position: -425px -60px;
}
.lang-icon-en {
  background-position: -450px -60px;
}
.lang-icon-en-us {
  background-position: -475px -60px;
}
.lang-icon-en-gb {
  background-position: -500px -60px;
}
.lang-icon-en-au {
  background-position: -525px -60px;
}
.lang-icon-en-ca {
  background-position: -550px -60px;
}
.lang-icon-en-nz {
  background-position: -575px -60px;
}
.lang-icon-en-ie {
  background-position: -0px -75px;
}
.lang-icon-en-sg {
  background-position: -25px -75px;
}
.lang-icon-en-old {
  background-position: -50px -75px;
}
.lang-icon-en-mid {
  background-position: -75px -75px;
}
.lang-icon-es {
  background-position: -100px -75px;
}
.lang-icon-es-es {
  background-position: -125px -75px;
}
.lang-icon-es-mx {
  background-position: -150px -75px;
}
.lang-icon-es-ar {
  background-position: -175px -75px;
}
.lang-icon-es-cl {
  background-position: -200px -75px;
}
.lang-icon-es-pe {
  background-position: -225px -75px;
}
.lang-icon-es-ve {
  background-position: -250px -75px;
}
.lang-icon-es-co {
  background-position: -275px -75px;
}
.lang-icon-es-bo {
  background-position: -300px -75px;
}
.lang-icon-es-py {
  background-position: -325px -75px;
}
.lang-icon-es-uy {
  background-position: -350px -75px;
}
.lang-icon-es-cu {
  background-position: -375px -75px;
}
.lang-icon-es-do {
  background-position: -400px -75px;
}
.lang-icon-es-pa {
  background-position: -425px -75px;
}
.lang-icon-es-cr {
  background-position: -450px -75px;
}
.lang-icon-es-hn {
  background-position: -475px -75px;
}
.lang-icon-es-ni {
  background-position: -500px -75px;
}
.lang-icon-es-old {
  background-position: -525px -75px;
  width: 20px;
}
.lang-icon-es-sv {
  background-position: -550px -75px;
}
.lang-icon-es-gt {
  background-position: -575px -75px;
}
.lang-icon-es-pr {
  background-position: -0px -90px;
}
.lang-icon-es-an {
  background-position: -25px -90px;
}
.lang-icon-sux {
  background-position: -50px -90px;
  width: 15px;
}
.lang-icon-egl {
  background-position: -75px -90px;
}
.lang-icon-enq {
  background-position: -100px -90px;
}
.lang-icon-eo {
  background-position: -125px -90px;
}
.lang-icon-ext {
  background-position: -150px -90px;
}
.lang-icon-eu {
  background-position: -175px -90px;
}
.lang-icon-myv {
  background-position: -200px -90px;
}
.lang-icon-evn {
  background-position: -225px -90px;
}
.lang-icon-ee {
  background-position: -250px -90px;
}
.lang-icon-ett {
  background-position: -275px -90px;
}
.lang-icon-wls {
  background-position: -300px -90px;
}
.lang-icon-fa {
  background-position: -325px -90px;
}
.lang-icon-fa-af {
  background-position: -350px -90px;
}
.lang-icon-fa-old {
  background-position: -375px -90px;
  width: 15px;
}
.lang-icon-fa-mid {
  background-position: -400px -90px;
}
.lang-icon-fa-tj {
  background-position: -425px -90px;
}
.lang-icon-fj {
  background-position: -450px -90px;
}
.lang-icon-fo {
  background-position: -475px -90px;
}
.lang-icon-fr {
  background-position: -500px -90px;
}
.lang-icon-fr-ac {
  background-position: -525px -90px;
}
.lang-icon-fr-be {
  background-position: -550px -90px;
}
.lang-icon-fr-ca {
  background-position: -575px -90px;
}
.lang-icon-fr-ch {
  background-position: -0px -105px;
  width: 15px;
}
.lang-icon-fr-frc {
  background-position: -25px -105px;
}
.lang-icon-fr-old {
  background-position: -50px -105px;
}
.lang-icon-fr-qc {
  background-position: -75px -105px;
}
.lang-icon-frr {
  background-position: -100px -105px;
}
.lang-icon-fy {
  background-position: -125px -105px;
}
.lang-icon-fur {
  background-position: -150px -105px;
}
.lang-icon-ga {
  background-position: -175px -105px;
}
.lang-icon-gv {
  background-position: -200px -105px;
}
.lang-icon-gag {
  background-position: -225px -105px;
}
.lang-icon-gd {
  background-position: -250px -105px;
}
.lang-icon-gan-hans {
  background-position: -275px -105px;
}
.lang-icon-gan-hant {
  background-position: -300px -105px;
}
.lang-icon-gl {
  background-position: -325px -105px;
}
.lang-icon-gez {
  background-position: -350px -105px;
}
.lang-icon-glk {
  background-position: -375px -105px;
}
.lang-icon-gdo {
  background-position: -400px -105px;
  width: 22px;
}
.lang-icon-sga {
  background-position: -425px -105px;
}
.lang-icon-huz {
  background-position: -450px -105px;
  width: 22px;
}
.lang-icon-grn {
  background-position: -475px -105px;
}
.lang-icon-got {
  background-position: -500px -105px;
}
.lang-icon-gu {
  background-position: -525px -105px;
}
.lang-icon-zhc {
  background-position: -550px -105px;
}
.lang-icon-ha-latn {
  background-position: -575px -105px;
}
.lang-icon-ha-arab {
  background-position: -0px -120px;
}
.lang-icon-zh-hans {
  background-position: -25px -120px;
}
.lang-icon-zh-hant {
  background-position: -50px -120px;
}
.lang-icon-yue-hans {
  background-position: -75px -120px;
}
.lang-icon-yue-hant {
  background-position: -100px -120px;
}
.lang-icon-hak-hans {
  background-position: -125px -120px;
}
.lang-icon-hak-hant {
  background-position: -150px -120px;
}
.lang-icon-hsn-hans {
  background-position: -175px -120px;
}
.lang-icon-hsn-hant {
  background-position: -200px -120px;
}
.lang-icon-czh-hans {
  background-position: -225px -120px;
}
.lang-icon-czh-hant {
  background-position: -250px -120px;
}
.lang-icon-nan-hans {
  background-position: -275px -120px;
}
.lang-icon-nan-hant {
  background-position: -300px -120px;
}
.lang-icon-cdo-hans {
  background-position: -325px -120px;
}
.lang-icon-cdo-hant {
  background-position: -350px -120px;
}
.lang-icon-ko {
  background-position: -375px -120px;
}
.lang-icon-ko-mid {
  background-position: -400px -120px;
}
.lang-icon-xal {
  background-position: -425px -120px;
}
.lang-icon-haw {
  background-position: -450px -120px;
}
.lang-icon-hy {
  background-position: -475px -120px;
}
.lang-icon-hy-e {
  background-position: -500px -120px;
}
.lang-icon-hy-w {
  background-position: -525px -120px;
}
.lang-icon-hy-h {
  background-position: -550px -120px;
}
.lang-icon-hy-mid {
  background-position: -575px -120px;
}
.lang-icon-hy-clas {
  background-position: -0px -135px;
}
.lang-icon-grc {
  background-position: -25px -135px;
  width: 15px;
}
.lang-icon-hif {
  background-position: -50px -135px;
}
.lang-icon-hi {
  background-position: -75px -135px;
}
.lang-icon-gin {
  background-position: -100px -135px;
}
.lang-icon-arp {
  background-position: -125px -135px;
}
.lang-icon-ho {
  background-position: -150px -135px;
}
.lang-icon-hit {
  background-position: -175px -135px;
}
.lang-icon-hmn {
  background-position: -200px -135px;
}
.lang-icon-hop {
  background-position: -225px -135px;
}
.lang-icon-hsb {
  background-position: -250px -135px;
}
.lang-icon-inh {
  background-position: -275px -135px;
}
.lang-icon-jv {
  background-position: -300px -135px;
}
.lang-icon-jv-bms {
  background-position: -325px -135px;
}
.lang-icon-hr {
  background-position: -350px -135px;
}
.lang-icon-hur {
  background-position: -375px -135px;
}
.lang-icon-rw {
  background-position: -400px -135px;
}
.lang-icon-iu {
  background-position: -425px -135px;
}
.lang-icon-ipk {
  background-position: -450px -135px;
}
.lang-icon-id {
  background-position: -475px -135px;
}
.lang-icon-ig {
  background-position: -500px -135px;
}
.lang-icon-is {
  background-position: -525px -135px;
}
.lang-icon-it {
  background-position: -550px -135px;
}
.lang-icon-itl {
  background-position: -575px -135px;
}
.lang-icon-he {
  background-position: -0px -150px;
}
.lang-icon-he-hbo {
  background-position: -25px -150px;
}
.lang-icon-jmk {
  background-position: -50px -150px;
}
.lang-icon-kry {
  background-position: -75px -150px;
}
.lang-icon-cu {
  background-position: -100px -150px;
}
.lang-icon-cjy-hans {
  background-position: -125px -150px;
}
.lang-icon-cjy-hant {
  background-position: -150px -150px;
}
.lang-icon-ka {
  background-position: -175px -150px;
}
.lang-icon-ka-old {
  background-position: -200px -150px;
}
.lang-icon-kjj {
  background-position: -225px -150px;
}
.lang-icon-kab {
  background-position: -250px -150px;
}
.lang-icon-mh {
  background-position: -275px -150px;
}
.lang-icon-kl {
  background-position: -300px -150px;
}
.lang-icon-mwp {
  background-position: -325px -150px;
}
.lang-icon-moh {
  background-position: -350px -150px;
}
.lang-icon-kn {
  background-position: -375px -150px;
}
.lang-icon-cak {
  background-position: -400px -150px;
}
.lang-icon-kdr {
  background-position: -425px -150px;
}
.lang-icon-kpt {
  background-position: -450px -150px;
  width: 22px;
}
.lang-icon-kar {
  background-position: -475px -150px;
}
.lang-icon-krl {
  background-position: -500px -150px;
}
.lang-icon-csb {
  background-position: -525px -150px;
}
.lang-icon-gbb {
  background-position: -550px -150px;
}
.lang-icon-ks-deva {
  background-position: -575px -150px;
}
.lang-icon-ks-arab {
  background-position: -0px -165px;
}
.lang-icon-kk {
  background-position: -25px -165px;
}
.lang-icon-km {
  background-position: -50px -165px;
}
.lang-icon-kw {
  background-position: -75px -165px;
}
.lang-icon-khi {
  background-position: -100px -165px;
}
.lang-icon-kca {
  background-position: -125px -165px;
}
.lang-icon-khv {
  background-position: -150px -165px;
  width: 22px;
}
.lang-icon-krc {
  background-position: -175px -165px;
}
.lang-icon-ky {
  background-position: -200px -165px;
}
.lang-icon-gil {
  background-position: -225px -165px;
}
.lang-icon-rn {
  background-position: -250px -165px;
}
.lang-icon-sw {
  background-position: -275px -165px;
}
.lang-icon-kv {
  background-position: -300px -165px;
}
.lang-icon-kv-koi {
  background-position: -325px -165px;
}
.lang-icon-kv-kpv {
  background-position: -350px -165px;
}
.lang-icon-kmk {
  background-position: -375px -165px;
}
.lang-icon-ht {
  background-position: -400px -165px;
}
.lang-icon-kea {
  background-position: -425px -165px;
}
.lang-icon-ani {
  background-position: -450px -165px;
  width: 22px;
}
.lang-icon-ksk {
  background-position: -475px -165px;
}
.lang-icon-ku-latn {
  background-position: -500px -165px;
}
.lang-icon-ku-arab {
  background-position: -525px -165px;
}
.lang-icon-kgg {
  background-position: -550px -165px;
}
.lang-icon-kwk {
  background-position: -575px -165px;
}
.lang-icon-lkt {
  background-position: -0px -180px;
}
.lang-icon-las {
  background-position: -25px -180px;
}
.lang-icon-ltg {
  background-position: -50px -180px;
}
.lang-icon-lo {
  background-position: -75px -180px;
}
.lang-icon-lzz {
  background-position: -100px -180px;
}
.lang-icon-la {
  background-position: -125px -180px;
  width: 16px;
}
.lang-icon-la-va {
  background-position: -150px -180px;
  width: 15px;
}
.lang-icon-sm {
  background-position: -175px -180px;
}
.lang-icon-lez {
  background-position: -200px -180px;
}
.lang-icon-liv {
  background-position: -225px -180px;
}
.lang-icon-lld {
  background-position: -250px -180px;
}
.lang-icon-lv {
  background-position: -275px -180px;
}
.lang-icon-unm {
  background-position: -300px -180px;
}
.lang-icon-lb {
  background-position: -325px -180px;
}
.lang-icon-lbe {
  background-position: -350px -180px;
}
.lang-icon-lt {
  background-position: -375px -180px;
}
.lang-icon-lij {
  background-position: -400px -180px;
  width: 23px;
}
.lang-icon-lim {
  background-position: -425px -180px;
}
.lang-icon-ln {
  background-position: -450px -180px;
}
.lang-icon-loz {
  background-position: -475px -180px;
}
.lang-icon-ckt {
  background-position: -500px -180px;
}
.lang-icon-lud {
  background-position: -525px -180px;
}
.lang-icon-lmo {
  background-position: -550px -180px;
}
.lang-icon-sva {
  background-position: -575px -180px;
}
.lang-icon-mas {
  background-position: -0px -195px;
}
.lang-icon-yua {
  background-position: -25px -195px;
}
.lang-icon-mdh {
  background-position: -50px -195px;
}
.lang-icon-hu {
  background-position: -75px -195px;
}
.lang-icon-mkz {
  background-position: -100px -195px;
}
.lang-icon-mk {
  background-position: -125px -195px;
}
.lang-icon-mg {
  background-position: -150px -195px;
}
.lang-icon-ml {
  background-position: -175px -195px;
}
.lang-icon-mt {
  background-position: -200px -195px;
}
.lang-icon-mnc {
  background-position: -225px -195px;
}
.lang-icon-mns {
  background-position: -250px -195px;
}
.lang-icon-xmf {
  background-position: -275px -195px;
}
.lang-icon-mr {
  background-position: -300px -195px;
  width: 26px;
}
.lang-icon-mic {
  background-position: -325px -195px;
}
.lang-icon-mwl {
  background-position: -350px -195px;
  width: 23px;
}
.lang-icon-mo {
  background-position: -375px -195px;
}
.lang-icon-mdf {
  background-position: -400px -195px;
}
.lang-icon-mnw {
  background-position: -425px -195px;
}
.lang-icon-mn {
  background-position: -450px -195px;
}
.lang-icon-cmg {
  background-position: -475px -195px;
}
.lang-icon-arn {
  background-position: -500px -195px;
}
.lang-icon-mfe {
  background-position: -525px -195px;
}
.lang-icon-mof {
  background-position: -550px -195px;
}
.lang-icon-mia {
  background-position: -575px -195px;
}
.lang-icon-rut {
  background-position: -0px -210px;
  width: 22px;
}
.lang-icon-mhr {
  background-position: -25px -210px;
}
.lang-icon-mus {
  background-position: -50px -210px;
}
.lang-icon-mrj {
  background-position: -75px -210px;
}
.lang-icon-my {
  background-position: -100px -210px;
}
.lang-icon-nah {
  background-position: -125px -210px;
}
.lang-icon-na {
  background-position: -150px -210px;
}
.lang-icon-nap {
  background-position: -175px -210px;
}
.lang-icon-new {
  background-position: -200px -210px;
  width: 13px;
}
.lang-icon-ppl {
  background-position: -225px -210px;
}
.lang-icon-yrk {
  background-position: -250px -210px;
}
.lang-icon-aii {
  background-position: -275px -210px;
}
.lang-icon-nll {
  background-position: -300px -210px;
}
.lang-icon-niv {
  background-position: -325px -210px;
}
.lang-icon-nio {
  background-position: -350px -210px;
}
.lang-icon-niu {
  background-position: -375px -210px;
}
.lang-icon-nog {
  background-position: -400px -210px;
}
.lang-icon-cr {
  background-position: -425px -210px;
}
.lang-icon-ja {
  background-position: -450px -210px;
}
.lang-icon-ja-clas {
  background-position: -475px -210px;
}
.lang-icon-nd {
  background-position: -500px -210px;
}
.lang-icon-nl {
  background-position: -525px -210px;
}
.lang-icon-nl-nbr {
  background-position: -550px -210px;
  width: 24px;
}
.lang-icon-nl-be {
  background-position: -575px -210px;
}
.lang-icon-nl-mid {
  background-position: -0px -225px;
}
.lang-icon-ne {
  background-position: -25px -225px;
  width: 13px;
}
.lang-icon-pih {
  background-position: -50px -225px;
}
.lang-icon-no {
  background-position: -75px -225px;
}
.lang-icon-no-nn {
  background-position: -100px -225px;
}
.lang-icon-no-nb {
  background-position: -125px -225px;
}
.lang-icon-no-val {
  background-position: -150px -225px;
}
.lang-icon-non {
  background-position: -175px -225px;
}
.lang-icon-ntj {
  background-position: -200px -225px;
}
.lang-icon-nr {
  background-position: -225px -225px;
}
.lang-icon-oka {
  background-position: -250px -225px;
}
.lang-icon-ny {
  background-position: -275px -225px;
}
.lang-icon-kpy {
  background-position: -300px -225px;
}
.lang-icon-oc {
  background-position: -325px -225px;
}
.lang-icon-ryu {
  background-position: -350px -225px;
}
.lang-icon-olo {
  background-position: -375px -225px;
}
.lang-icon-one {
  background-position: -400px -225px;
}
.lang-icon-ood {
  background-position: -425px -225px;
}
.lang-icon-or {
  background-position: -450px -225px;
}
.lang-icon-om {
  background-position: -475px -225px;
}
.lang-icon-hz {
  background-position: -500px -225px;
}
.lang-icon-os {
  background-position: -525px -225px;
}
.lang-icon-os-digor {
  background-position: -550px -225px;
}
.lang-icon-pi {
  background-position: -575px -225px;
}
.lang-icon-pa-guru {
  background-position: -0px -240px;
}
.lang-icon-pa-deva {
  background-position: -25px -240px;
}
.lang-icon-pa-arab {
  background-position: -50px -240px;
}
.lang-icon-pau {
  background-position: -75px -240px;
}
.lang-icon-pap {
  background-position: -100px -240px;
}
.lang-icon-prk {
  background-position: -125px -240px;
}
.lang-icon-pam {
  background-position: -150px -240px;
}
.lang-icon-paa {
  background-position: -175px -240px;
}
.lang-icon-nds {
  background-position: -200px -240px;
}
.lang-icon-pdt {
  background-position: -225px -240px;
}
.lang-icon-pl {
  background-position: -250px -240px;
}
.lang-icon-pt {
  background-position: -275px -240px;
}
.lang-icon-pt-pt {
  background-position: -300px -240px;
}
.lang-icon-pt-br {
  background-position: -325px -240px;
}
.lang-icon-prg {
  background-position: -350px -240px;
}
.lang-icon-ps {
  background-position: -375px -240px;
}
.lang-icon-phn {
  background-position: -400px -240px;
}
.lang-icon-pms {
  background-position: -425px -240px;
  width: 23px;
}
.lang-icon-piu {
  background-position: -450px -240px;
}
.lang-icon-pjt {
  background-position: -475px -240px;
}
.lang-icon-kaa {
  background-position: -500px -240px;
}
.lang-icon-crh {
  background-position: -525px -240px;
}
.lang-icon-qu {
  background-position: -550px -240px;
  width: 15px;
}
.lang-icon-rap {
  background-position: -575px -240px;
}
.lang-icon-ro {
  background-position: -0px -255px;
}
.lang-icon-rgn {
  background-position: -25px -255px;
}
.lang-icon-rom-latn {
  background-position: -50px -255px;
}
.lang-icon-rom-cyrl {
  background-position: -75px -255px;
}
.lang-icon-roo {
  background-position: -100px -255px;
}
.lang-icon-rtm {
  background-position: -125px -255px;
}
.lang-icon-rm {
  background-position: -150px -255px;
  width: 15px;
}
.lang-icon-rue {
  background-position: -175px -255px;
}
.lang-icon-rue-rs {
  background-position: -200px -255px;
}
.lang-icon-ru {
  background-position: -225px -255px;
}
.lang-icon-sg {
  background-position: -250px -255px;
}
.lang-icon-ari {
  background-position: -275px -255px;
}
.lang-icon-sa {
  background-position: -300px -255px;
}
.lang-icon-sc {
  background-position: -325px -255px;
}
.lang-icon-sah {
  background-position: -350px -255px;
}
.lang-icon-sco {
  background-position: -375px -255px;
}
.lang-icon-shs {
  background-position: -400px -255px;
}
.lang-icon-fla {
  background-position: -425px -255px;
}
.lang-icon-shn {
  background-position: -450px -255px;
}
.lang-icon-scn {
  background-position: -475px -255px;
}
.lang-icon-scn-tara {
  background-position: -500px -255px;
}
.lang-icon-sml {
  background-position: -525px -255px;
}
.lang-icon-sog {
  background-position: -550px -255px;
}
.lang-icon-ckb {
  background-position: -575px -255px;
}
.lang-icon-shh {
  background-position: -0px -270px;
}
.lang-icon-sr-cyrl {
  background-position: -25px -270px;
}
.lang-icon-sr-latn {
  background-position: -50px -270px;
}
.lang-icon-sh {
  background-position: -75px -270px;
}
.lang-icon-srn {
  background-position: -100px -270px;
}
.lang-icon-sq {
  background-position: -125px -270px;
}
.lang-icon-ii {
  background-position: -150px -270px;
}
.lang-icon-za {
  background-position: -175px -270px;
}
.lang-icon-smi {
  background-position: -200px -270px;
  width: 20px;
}
.lang-icon-smi-smn {
  background-position: -225px -270px;
  width: 20px;
}
.lang-icon-smi-smk {
  background-position: -250px -270px;
  width: 20px;
}
.lang-icon-smi-smj {
  background-position: -275px -270px;
  width: 20px;
}
.lang-icon-smi-sme {
  background-position: -300px -270px;
  width: 20px;
}
.lang-icon-smi-smp {
  background-position: -325px -270px;
  width: 20px;
}
.lang-icon-smi-sms {
  background-position: -350px -270px;
  width: 20px;
}
.lang-icon-smi-sma {
  background-position: -375px -270px;
  width: 20px;
}
.lang-icon-smi-smt {
  background-position: -400px -270px;
  width: 20px;
}
.lang-icon-smi-smu {
  background-position: -425px -270px;
  width: 20px;
}
.lang-icon-sd-deva {
  background-position: -450px -270px;
}
.lang-icon-sd-arab {
  background-position: -475px -270px;
}
.lang-icon-crs {
  background-position: -500px -270px;
}
.lang-icon-si {
  background-position: -525px -270px;
}
.lang-icon-den {
  background-position: -550px -270px;
}
.lang-icon-sk {
  background-position: -575px -270px;
}
.lang-icon-sl {
  background-position: -0px -285px;
}
.lang-icon-szl {
  background-position: -25px -285px;
}
.lang-icon-crd {
  background-position: -50px -285px;
}
.lang-icon-so {
  background-position: -75px -285px;
}
.lang-icon-st {
  background-position: -100px -285px;
}
.lang-icon-stq {
  background-position: -125px -285px;
}
.lang-icon-ss {
  background-position: -150px -285px;
}
.lang-icon-su {
  background-position: -175px -285px;
}
.lang-icon-fi {
  background-position: -200px -285px;
}
.lang-icon-sgn {
  background-position: -225px -285px;
}
.lang-icon-sgn-au {
  background-position: -250px -285px;
}
.lang-icon-sgn-us {
  background-position: -275px -285px;
}
.lang-icon-sgn-gb {
  background-position: -300px -285px;
}
.lang-icon-sgn-ee {
  background-position: -325px -285px;
}
.lang-icon-sgn-cy {
  background-position: -350px -285px;
}
.lang-icon-sgn-gr {
  background-position: -375px -285px;
}
.lang-icon-sgn-ie {
  background-position: -400px -285px;
}
.lang-icon-sgn-cn {
  background-position: -425px -285px;
}
.lang-icon-sgn-de {
  background-position: -450px -285px;
}
.lang-icon-sgn-fr {
  background-position: -475px -285px;
}
.lang-icon-sgn-ca-qc {
  background-position: -500px -285px;
}
.lang-icon-sgn-es {
  background-position: -525px -285px;
}
.lang-icon-sgn-br {
  background-position: -550px -285px;
}
.lang-icon-sgn-it {
  background-position: -575px -285px;
}
.lang-icon-sgn-nl {
  background-position: -0px -300px;
}
.lang-icon-sgn-jp {
  background-position: -25px -300px;
}
.lang-icon-sgn-no {
  background-position: -50px -300px;
}
.lang-icon-sgn-ru {
  background-position: -75px -300px;
}
.lang-icon-sgn-rs {
  background-position: -100px -300px;
}
.lang-icon-sgn-il {
  background-position: -125px -300px;
}
.lang-icon-sgn-se {
  background-position: -150px -300px;
}
.lang-icon-sv {
  background-position: -175px -300px;
}
.lang-icon-sv-fi {
  background-position: -200px -300px;
}
.lang-icon-syc {
  background-position: -225px -300px;
}
.lang-icon-ta {
  background-position: -250px -300px;
}
.lang-icon-ta-lk {
  background-position: -275px -300px;
}
.lang-icon-tl {
  background-position: -300px -300px;
}
.lang-icon-ttt {
  background-position: -325px -300px;
}
.lang-icon-tet {
  background-position: -350px -300px;
}
.lang-icon-to {
  background-position: -375px -300px;
}
.lang-icon-th {
  background-position: -400px -300px;
}
.lang-icon-tfn {
  background-position: -425px -300px;
}
.lang-icon-tpi {
  background-position: -450px -300px;
}
.lang-icon-tab {
  background-position: -475px -300px;
}
.lang-icon-taq {
  background-position: -500px -300px;
}
.lang-icon-ber {
  background-position: -525px -300px;
}
.lang-icon-tzm {
  background-position: -550px -300px;
}
.lang-icon-tly {
  background-position: -575px -300px;
}
.lang-icon-tt {
  background-position: -0px -315px;
}
.lang-icon-tsg {
  background-position: -25px -315px;
}
.lang-icon-tkr {
  background-position: -50px -315px;
  width: 22px;
}
.lang-icon-tiw {
  background-position: -75px -315px;
}
.lang-icon-dgr {
  background-position: -100px -315px;
}
.lang-icon-chy {
  background-position: -125px -315px;
}
.lang-icon-tn {
  background-position: -150px -315px;
}
.lang-icon-mi {
  background-position: -175px -315px;
}
.lang-icon-rar {
  background-position: -200px -315px;
}
.lang-icon-ty {
  background-position: -225px -315px;
}
.lang-icon-te {
  background-position: -250px -315px;
}
.lang-icon-din {
  background-position: -275px -315px;
}
.lang-icon-vi {
  background-position: -300px -315px;
}
.lang-icon-tin {
  background-position: -325px -315px;
  width: 22px;
}
.lang-icon-tlh {
  background-position: -350px -315px;
}
.lang-icon-tli {
  background-position: -375px -315px;
}
.lang-icon-ti {
  background-position: -400px -315px;
}
.lang-icon-ddo {
  background-position: -425px -315px;
}
.lang-icon-ve {
  background-position: -450px -315px;
  width: 23px;
}
.lang-icon-tr {
  background-position: -475px -315px;
}
.lang-icon-ota {
  background-position: -500px -315px;
}
.lang-icon-tk {
  background-position: -525px -315px;
}
.lang-icon-tyv {
  background-position: -550px -315px;
}
.lang-icon-uby {
  background-position: -575px -315px;
}
.lang-icon-twi {
  background-position: -0px -330px;
}
.lang-icon-cim {
  background-position: -25px -330px;
}
.lang-icon-udm {
  background-position: -50px -330px;
}
.lang-icon-uga {
  background-position: -75px -330px;
}
.lang-icon-ale {
  background-position: -100px -330px;
}
.lang-icon-udi {
  background-position: -125px -330px;
}
.lang-icon-udi-old {
  background-position: -150px -330px;
}
.lang-icon-uk {
  background-position: -175px -330px;
}
.lang-icon-ur {
  background-position: -200px -330px;
}
.lang-icon-ug {
  background-position: -225px -330px;
}
.lang-icon-uz {
  background-position: -250px -330px;
}
.lang-icon-vai {
  background-position: -275px -330px;
}
.lang-icon-vot {
  background-position: -300px -330px;
  width: 24px;
}
.lang-icon-vec {
  background-position: -325px -330px;
  width: 23px;
}
.lang-icon-vro {
  background-position: -350px -330px;
}
.lang-icon-vep {
  background-position: -375px -330px;
}
.lang-icon-wa {
  background-position: -400px -330px;
  width: 23px;
}
.lang-icon-wbp {
  background-position: -425px -330px;
}
.lang-icon-wrm {
  background-position: -450px -330px;
}
.lang-icon-wac {
  background-position: -475px -330px;
}
.lang-icon-was {
  background-position: -500px -330px;
}
.lang-icon-pnb {
  background-position: -525px -330px;
}
.lang-icon-war {
  background-position: -550px -330px;
}
.lang-icon-wo {
  background-position: -575px -330px;
}
.lang-icon-wuu-hans {
  background-position: -0px -345px;
}
.lang-icon-wuu-hant {
  background-position: -25px -345px;
}
.lang-icon-myp {
  background-position: -50px -345px;
}
.lang-icon-ts {
  background-position: -75px -345px;
}
.lang-icon-hai {
  background-position: -100px -345px;
}
.lang-icon-xh {
  background-position: -125px -345px;
}
.lang-icon-xum {
  background-position: -150px -345px;
}
.lang-icon-ket {
  background-position: -175px -345px;
}
.lang-icon-yi {
  background-position: -200px -345px;
}
.lang-icon-yol {
  background-position: -225px -345px;
}
.lang-icon-yo {
  background-position: -250px -345px;
}
.lang-icon-esu {
  background-position: -275px -345px;
}
.lang-icon-zza {
  background-position: -300px -345px;
}
.lang-icon-zea {
  background-position: -325px -345px;
}
.lang-icon-sgs {
  background-position: -350px -345px;
}
.lang-icon-zu {
  background-position: -375px -345px;
}
.lang-icon-zun {
  background-position: -400px -345px;
}
.lang-icon-art {
  background-position: -425px -345px;
}
.lang-icon-art-ido {
  background-position: -450px -345px;
}
.lang-icon-art-ina {
  background-position: -475px -345px;
}
.lang-icon-art-jbo {
  background-position: -500px -345px;
}
.lang-icon-art-lfn {
  background-position: -525px -345px;
}
.lang-icon-art-nov {
  background-position: -550px -345px;
}
.lang-icon-art-sjn {
  background-position: -575px -345px;
}
.lang-icon-art-tlh {
  background-position: -0px -360px;
}
.lang-icon-art-tkp {
  background-position: -25px -360px;
}
.lang-icon-art-qya {
  background-position: -50px -360px;
}
.lang-icon-art-srs {
  background-position: -75px -360px;
}
.lang-icon-art-vo {
  background-position: -100px -360px;
}