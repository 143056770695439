


    body {
      @apply bg-gray-50;
      height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    
    }
    .svg-icon svg {
      height: 1.15rem;
      width: 1.15rem;
    }

    .svg-icon.svg-icon-fluid svg {
      height: 100% !important;
      width: 100% !important;
    }

    .learmo-dropdown-menu:not(.show) {
      display: none;
    }
    
  

/* Import custom styles */
@import "./styles.custom";

/* Import Tailwind's base, components, and utilities layers */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import additional styles */
@import "./system";
@import "./system.icons";
@import "./system.plugins";
@import "./system.langs.flags";
@import "./styles.ng-select";
@import "./styles.shepherd";

/* Define custom utilities */
@layer utilities {
  @import "./tailwind-bootstrap.compatibility.css";
}




