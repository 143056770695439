/* ======================
     SVG & Icon Styles
     ====================== */
.svg-icon,
svg-icon {
  line-height: 1;
  margin-right: 0 !important;
  margin-left: 0 !important;
  @apply inline-block !text-base;
}

.svg-muted {
  @apply text-zinc-400;
}
.svg-icon.svg-icon-fluid svg,
svg-icon.svg-icon-fluid svg {
  height: 100% !important;
  width: 100% !important;
}

/* .btn-sm svg {
    height: 3em !important;
    width: 3em !important;
  } */
.svg-icon.svg-icon-1 svg {
  height: 1.75em !important;
  width: 1.75em !important;
}
.svg-icon.svg-icon-2 svg {
  height: 1.5em !important;
  width: 1.5em !important;
}
.svg-icon.svg-icon-3 svg {
  height: 1.35em !important;
  width: 1.35em !important;
}
.svg-icon.svg-icon-4 svg {
  height: 1.25em !important;
  width: 1.25em !important;
}
.svg-icon.svg-icon-5 svg {
  height: 1.15em !important;
  width: 1.15em !important;
}
.svg-icon.svg-icon-6 svg {
  height: 0.85em !important;
  width: 0.85em !important;
}
.svg-icon.svg-icon-7 svg {
  height: 0.75em !important;
  width: 0.75em !important;
}
.svg-icon.svg-icon-8 svg {
  height: 0.85em !important;
  width: 0.85em !important;
}
.svg-icon.svg-icon-9 svg {
  height: 0.75em !important;
  width: 0.75em !important;
}
.svg-icon.svg-icon-10 svg {
  height: 0.5em !important;
  width: 0.5em !important;
}
.svg-icon.svg-icon-base svg {
  height: 0.825em !important;
  width: 0.825em !important;
}

/* ======================
     Layout Utilities
     ====================== */
.d-flex {
  @apply flex;
}
.flex-column {
  @apply flex-col;
}
.flex-md-row {
  @apply md:flex-row;
}
.flex-grow-1 {
  @apply grow;
}

.position-absolute {
  @apply absolute;
}
.position-relative {
  @apply relative;
}
.position-sticky {
  @apply sticky;
}

.w-100 {
  @apply w-full;
}

/* Alignment Utilities */
.align-items-center {
  @apply items-center;
}
.align-items-end {
  @apply items-end;
}
.justify-content-center {
  @apply justify-center;
}
.justify-content-between {
  @apply justify-between;
}
.flex-center {
  @apply items-center justify-center;
}

/* ======================
     Grid System
     ====================== */
.row {
  @apply flex flex-wrap w-full;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.row > * {
  @apply px-4 flex-grow;
}

.row > *:first-child,
.row > *:only-child,
.row > .col-12,
.row > :not(.col) {
  @apply ps-0 pt-0;
}

.row > *:last-child,
.row > *:only-child,
.row > .col-12,
.row > :not(.col) {
  @apply pe-0 pb-0;
}

/* Column Widths */
.col-1 {
  @apply w-1/12;
}
.col-2 {
  @apply w-2/12;
}
.col-3 {
  @apply w-3/12;
}
.col-4 {
  @apply w-4/12;
}
.col-5 {
  @apply w-5/12;
}
.col-6 {
  @apply w-6/12;
}
.col-7 {
  @apply w-7/12;
}
.col-8 {
  @apply w-8/12;
}
.col-9 {
  @apply w-9/12;
}
.col-10 {
  @apply w-10/12;
}
.col-11 {
  @apply w-11/12;
}
.col-12 {
  @apply w-full;
}
.col {
  @apply flex-grow;
}

/* ======================
     Components
     ====================== */
.card {
  @apply bg-white rounded-3xl shadow-md;
}

.card-body {
  padding: 2rem 2.25rem;
}

/* Dropdown Styles */
.dropdown-menu {
  @apply hidden min-w-[12rem] p-2 m-0 text-sm text-gray-800 text-left list-none;
}

.dropdown-menu.show {
  @apply block;
}

.dropdown-item {
  @apply block w-full px-2 py-1.5 clear-both font-normal text-gray-800 text-left whitespace-nowrap bg-transparent border-0 rounded-none;
}

.dropdown-item:hover,
.dropdown-item:focus {
  @apply text-gray-900 bg-gray-100;
}

/* ======================
     Backgrounds & Borders
     ====================== */
.bg-light {
  @apply bg-zinc-50;
}
.bg-body {
  @apply bg-white;
}
.border {
  @apply border-zinc-100;
}

/* ======================
     Container Styles
     ====================== */
.learmo-app-container {
  @apply container mx-auto;
  padding-left: 6em !important;
  padding-right: 6em !important;
}

/* ======================
   Base Button Styles
   ====================== */
.btn {
  font-size: 0.8rem !important;
  @apply inline-flex items-center gap-x-2 text-sm font-medium rounded transition-all border border-transparent disabled:opacity-50 disabled:pointer-events-none;
  /* @apply block text-center font-medium rounded transition-all py-3 px-4 rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none; */
}

.btn {
  /* height: 40px; */
  @apply !py-2 !px-3 h-[40px];
}

.btn.btn-icon {
  /* width: 40px; */
  @apply !py-0 !px-0 !size-[40px];
}

.btn.custom-manage-button {
  @apply !py-2 !px-2 h-[40px];
}

.btn-sm {
  /* height: 36px; */
  @apply !py-1 !px-3 h-[36px];
}

.btn-sm.btn-icon {
  /* width: 36px !important; */
  @apply !py-0 !px-0 !size-[36px];
}

.btn-sm.custom-manage-button {
  @apply !py-1 !px-1 h-[36px];
}

.btn-lg {
  /* height: 45px; */
  @apply !py-3 !px-4 text-base h-[45px];
}

.btn-lg.btn-icon {
  /* width: 45px; */
  @apply !py-0 !px-0 !size-[45px];
}

.btn-lg.custom-manage-button {
  @apply !py-3 !px-3 h-[45px];
}

/* Button Variants */
.btn-primary {
  @apply bg-primary text-white hover:bg-primary-400;
}

.btn-primary-light {
  @apply bg-primary-50 text-primary hover:bg-primary-100 hover:text-primary focus:outline-none focus:bg-primary-100 dark:text-primary-400 dark:hover:bg-primary-900 dark:focus:bg-primary-900;
}

/* Button Elements */
.btn > svg-icon,
.btn > .svg-icon,
.btn > i {
  padding-right: 0 !important;
  padding-left: 0 !important;
  @apply inline-block !text-[1em];
}

.btn > .indicator-label {
  @apply flex items-center justify-center;
}

/* ======================
  Badge Buttons Styling
====================== */
.btn-badge {
  @apply inline-flex items-center gap-x-1.5 !py-0 !px-3 h-[30px] !rounded-full !text-xs !font-medium;
}

/* ======================
   Custom Manage Button Styles
   ====================== */

.custom-manage-button {
  @apply p-0 transition-all ease-in-out min-w-[40px] flex flex-center;
  /* min-width: calc(1.5em + 1.55rem + 2px); */
  /* aspect-ratio: 1; */
}

.custom-manage-button .svg-icon {
  @apply m-0;
}

.custom-manage-button.btn-lg {
  @apply min-w-[45px];
  /* Customize sizing here if needed */
}

.custom-manage-button.btn-sm {
  @apply min-w-[36px];
  /* Customize sizing here if needed */
}

.custom-manage-button .svg-icon,
.custom-manage-button i {
  @apply m-0 p-0;
}

.hover-rotate-svg:hover .svg-icon,
.hover-rotate-svg:hover i {
  animation: rotate-360 0.6s alternate forwards;
}

.custom-manage-button .button-label {
  @apply whitespace-nowrap overflow-hidden transform transition-all  ease-in-out;
  margin-right: -0.5em;
  max-width: 0;
  transform: translateX(5px);
  opacity: 0;
}

@media (min-width: 992px) {
  .custom-manage-button:hover .button-label,
  .custom-manage-button.active .button-label,
  .custom-manage-button[aria-expanded="true"] .button-label,
  .custom-manage-button.show-content .button-label {
    @apply transition-all  ease-in-out;
    transform: translateX(0);
    margin-right: 0 !important;
    /* margin-left: 0.5em; */
    opacity: 1;
    max-width: 200px;
  }

  .custom-manage-button:hover {
    aspect-ratio: unset;
  }

  .custom-manage-button:hover,
  .custom-manage-button.active,
  .custom-manage-button[aria-expanded="true"],
  .custom-manage-button.show-content {
    @apply !px-2;
    width: auto;
  }
}

@media (max-width: 992px) {
  .custom-manage-button .button-label {
    @apply transition-all  ease-in-out;
    transform: translateX(0);
    margin-left: 0.5em;
    opacity: 1;
    max-width: 120px;
  }

  .custom-manage-button {
    @apply p-[calc(0.55rem+1px)] py-[calc(1.25rem+1px)];
    height: calc(1em + 1.65rem + 2px);
    width: auto;
  }
}

.form-control-lg {
  @apply !text-base;
}

.card .card-header .card-title .card-label {
  @apply flex items-center;
}

.modal-header,
.card-header {
  @apply py-6;
}

.modal-header {
  @apply text-lg;
}

.modal-footer,
.card-footer {
  @apply !py-4;
}

.floating-label .form-control {
  @apply !p-4 block w-full border-gray-200 !rounded-2xl text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:!pt-6 focus:!pb-2 [&:not(:placeholder-shown)]:!pt-6 [&:not(:placeholder-shown)]:!pb-2 autofill:!pt-6 autofill:!pb-2;
}

[type="checkbox"],
[type="radio"] {
  @apply focus:!ring-transparent focus:!ring-0 focus:!ring-offset-0;
}

[type="checkbox"].rounded,
[type="checkbox"] {
  @apply !rounded-sm;
}

.form-switch > [type="checkbox"] {
  @apply relative !bg-none !w-11 !h-6 p-[2px] !bg-gray-100 border-transparent text-transparent !rounded-full cursor-pointer transition-colors ease-in-out duration-200 disabled:opacity-50 disabled:pointer-events-none checked:bg-white checked:border-primary-200 checked:text-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-5 before:bg-white checked:before:bg-primary before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200;
}

.form-control {
  @apply !outline-none  !block w-full !border-gray-200 !rounded-2xl text-sm focus:!border-primary-500 hover:!border-primary-200 focus:!ring-0 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600;
}

.form-control {
  @apply !py-3 !px-4 !text-sm;
}

.form-control.form-control-sm {
  @apply !py-2 !px-3 !text-sm;
}

.form-control.form-control-lg {
  @apply !p-4 sm:!p-4 !text-base;
}

.form-select {
  @apply !outline-none p-4 pe-9 block w-full border border-gray-200 !rounded-2xl text-sm focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2;
}

ng-select {
  @apply !bg-transparent !bg-none !border-0 focus:outline-none focus:ring-0 focus:ring-offset-0 !p-0;
}

.bg-secondary {
  @apply bg-gray-100 dark:bg-gray-800;
}
.bg-success {
  @apply bg-green-100 dark:bg-green-800;
}
.bg-info {
  @apply bg-blue-100 dark:bg-blue-800;
}
.bg-warning {
  @apply bg-yellow-100 dark:bg-yellow-800;
}
.bg-danger {
  @apply bg-red-100 dark:bg-red-800;
}

.bg-light-secondary {
  @apply bg-gray-200 dark:bg-gray-700;
}
.bg-light-success {
  @apply bg-green-200 dark:bg-green-700;
}
.bg-light-info {
  @apply bg-blue-200 dark:bg-blue-700;
}
.bg-light-warning {
  @apply bg-yellow-200 dark:bg-yellow-700;
}
.bg-light-danger {
  @apply bg-red-200 dark:bg-red-700;
}

.text-secondary {
  @apply text-gray-600 dark:text-gray-400;
}

.text-success {
  @apply text-green-600 dark:text-green-400;
}

.text-info {
  @apply text-blue-600 dark:text-blue-400;
}

.text-warning {
  @apply !text-yellow-600 dark:!text-yellow-400;
}

.text-danger {
  @apply text-red-600 dark:text-red-400;
}
